import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

import { LocaleConstants } from '../../../constants';
import { NumberMaskConfig } from '../../../interfaces';
import { LocalStorageService } from '../../../services';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'number-form-control',
  templateUrl: './number-form-control.component.html',
  styleUrls: ['./number-form-control.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class NumberFormControlComponent {

  @Input()
  public label: string;

  @Input()
  public value: number;

  @Output()
  public valueChange = new EventEmitter<number>();

  @Input()
  public minValue = -1000000;

  @Input()
  public maxValue = 1000000;

  @Input()
  public isRequired = false;

  @Input()
  public prependIcon: IconDefinition;

  @Input()
  public appendIcon: IconDefinition;

  @Input()
  public placeholder;

  @Input()
  public isDisabled = false;

  @Input()
  public name: string;

  @Input()
  public hideLabel = false;

  @Input()
  public numberMaskConfig: NumberMaskConfig;

  @Input()
  public errorMessages: any;

  @Input()
  public hideErrors = false;

  constructor(
    private _localStorageService: LocalStorageService
  ) {
    const userCulture = this._localStorageService.userProfile.culture;
    this.numberMaskConfig = LocaleConstants.numberMaskConfig.find(c => c.culture === userCulture);
    this.placeholder = `0${this.numberMaskConfig.decimal}00`;
  }

  public onValueChange(data: number): void {
    this.value = data;
    this.valueChange.emit(this.value);
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { GridSearchParameter, KeyValuePair, ResponseDataObject, RightTemplate, Role, SearchApiService } from '../../../shared/interfaces';
import { AppSettings } from '../../../shared/models';

@Injectable({
  providedIn: 'root'
})
export class RoleApiService implements SearchApiService<Role> {

  constructor(
    private _http: HttpClient
  ) { }

  public search(gridSearchParameter: GridSearchParameter): Observable<ResponseDataObject<Role[]>> {
    return this._http.post<ResponseDataObject<Role[]>>(AppSettings.endpoints.multitenancy.role + '/search', gridSearchParameter);
  }

  public searchSum(parameter: GridSearchParameter): Observable<ResponseDataObject<any>> {
    return this._http.post<ResponseDataObject<any>>(AppSettings.endpoints.multitenancy.role + '/search/sum', parameter);
  }

  public getRoleById(id: number): Observable<ResponseDataObject<Role>> {
    return this._http.get<ResponseDataObject<Role>>(AppSettings.endpoints.multitenancy.role + '/' + id);
  }

  public getRolesForTenant(tenantId?: number): Observable<ResponseDataObject<Role[]>> {
    if (isNaN(tenantId)) {
      return this._http.get<ResponseDataObject<Role[]>>(AppSettings.endpoints.multitenancy.role + '/forTenant/');
    }

    return this._http.get<ResponseDataObject<Role[]>>(AppSettings.endpoints.multitenancy.role + '/forTenant/' + tenantId);
  }

  public getIsInUse(id: number): Observable<ResponseDataObject<boolean>> {
    return this._http.get<ResponseDataObject<boolean>>(AppSettings.endpoints.multitenancy.role + '/isUsed/' + id);
  }

  public addRole(role: Role): Observable<ResponseDataObject<Role>> {
    return this._http.post<ResponseDataObject<Role>>(AppSettings.endpoints.multitenancy.role, role);
  }

  public updateRole(role: Role): Observable<ResponseDataObject<Role>> {
    return this._http.put<ResponseDataObject<Role>>(AppSettings.endpoints.multitenancy.role, role);
  }

  public deleteRole(id: number): Observable<ResponseDataObject<boolean>> {
    return this._http.delete<ResponseDataObject<boolean>>(AppSettings.endpoints.multitenancy.role + '/' + id);
  }

  public getRoleTemplates(): Observable<ResponseDataObject<KeyValuePair<string, RightTemplate[]>[]>> {
    return this._http.get<ResponseDataObject<KeyValuePair<string, RightTemplate[]>[]>>(AppSettings.endpoints.multitenancy.rightTemplate);
  }

  public getAllPermissions(): Observable<ResponseDataObject<KeyValuePair<string, string[]>[]>> {
    return this._http.get<ResponseDataObject<KeyValuePair<string, string[]>[]>>(AppSettings.endpoints.multitenancy.rightTemplate + '/allpermissions');
  }
}

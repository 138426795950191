import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Guid } from 'guid-typescript';
import * as moment from 'moment';
import { Subscription } from 'rxjs';

import { ContextMenuService, TranslationService } from '../../../core/services';
import { DayRange } from '../../enums';
import { icons } from '../../helper';
import { CalendarDay, CalendarMonthConfiguration, ContextMenuConfiguration, ContextMenuItem } from '../../models';
@Component({
  selector: 'app-calendar-month-card',
  templateUrl: './calendar-month-card.component.html',
  styleUrls: ['./calendar-month-card.component.scss']
})
export class CalendarMonthCardComponent implements OnInit, OnDestroy {
  private _subscriptions: Subscription = new Subscription();
  private _contextMenuConfiguration: ContextMenuConfiguration;
  private _calendarDayClass = 'calendar-day-';

  private _contextMenuItemCreate: ContextMenuItem;
  private _contextMenuItemEdit: ContextMenuItem;
  private _contextMenuItemDelete: ContextMenuItem;

  private _seletedDayId: number = null;
  private _seletedDay: CalendarDay;

  public daysTitle: CalendarDay[];
  public days: CalendarDay[];
  public isEditable = false;

  @Input()
  public configuration: CalendarMonthConfiguration;

  @Output()
  public createEntry: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  public editEntry: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  public deleteEntry: EventEmitter<number> = new EventEmitter<number>();

  constructor(
    private _contextMenuService: ContextMenuService,
    private _translationService: TranslationService
  ) { }


  @HostListener('contextmenu', ['$event'])
  public onRightClick(rightClick: MouseEvent): void {
    if (!this.isEditable || !this.hasCalendarDayClass(rightClick)) {
      return;
    }

    rightClick.preventDefault();

    this.openContextMenu(rightClick);
  }

  public ngOnInit(): void {
    if (this.configuration.reloadEntries) {
      this._subscriptions.add(this.configuration.reloadEntries.subscribe(_ => this.calculteEntries()));
    }

    if (this.configuration.resetPermissions) {
      this._subscriptions.add(this.configuration.resetPermissions.subscribe(_ => this.calculatePermissions()));
    }

    this.initContextMenu();

    this.daysTitle = [{
      displayNumber: this._translationService.translate('common', 'mondayShort'),
      tooltipLeftHalf: '',
      tooltipRightHalf: ''
    }, {
      displayNumber: this._translationService.translate('common', 'tuesdayShort'),
      tooltipLeftHalf: '',
      tooltipRightHalf: ''
    }, {
      displayNumber: this._translationService.translate('common', 'wednesdayShort'),
      tooltipLeftHalf: '',
      tooltipRightHalf: ''
    }, {
      displayNumber: this._translationService.translate('common', 'thursdayShort'),
      tooltipLeftHalf: '',
      tooltipRightHalf: ''
    }, {
      displayNumber: this._translationService.translate('common', 'fridayShort'),
      tooltipLeftHalf: '',
      tooltipRightHalf: ''
    }, {
      displayNumber: this._translationService.translate('common', 'saturdayShort'),
      tooltipLeftHalf: '',
      tooltipRightHalf: ''
    }, {
      displayNumber: this._translationService.translate('common', 'sundayShort'),
      tooltipLeftHalf: '',
      tooltipRightHalf: ''
    }];

    this.calculteEntries();
  }

  public ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  public onClickDay(day: CalendarDay, leftHalf: boolean): void {
    if (!this.isEditable || !day.isWorkingDay) {
      return;
    }

    this._seletedDayId = leftHalf ? day.idLeftHalf : day.idRightHalf;
    this._seletedDay = day;

    if (this._seletedDayId && this.configuration.canEdit) {
      this.editEntry.next(this._seletedDayId);
    } else if (!this._seletedDayId && this.configuration.canCreate) {
      this.createEntry.next(this._seletedDay.dateUtc);
    }
  }

  private initContextMenu(): void {
    this._contextMenuItemCreate = {
      title: this._translationService.translate('common', 'create'),
      icon: icons.add,
      isMainAction: true,
      isHidden: false,
      isDisabled: !this.configuration.canCreate,
      clicked: new EventEmitter<any>()
    };

    this._contextMenuItemEdit = {
      title: this._translationService.translate('common', 'edit'),
      icon: icons.edit,
      isMainAction: true,
      isHidden: false,
      isDisabled: !this.configuration.canEdit,
      clicked: new EventEmitter<any>()
    };

    this._contextMenuItemDelete = {
      title: this._translationService.translate('common', 'delete'),
      icon: icons.delete,
      isMainAction: false,
      isHidden: false,
      isDisabled: !this.configuration.canDelete,
      clicked: new EventEmitter<any>()
    };

    this._contextMenuItemCreate.clicked.subscribe(_ => {
      if (this.configuration.canCreate) {
        this.createEntry.next(this._seletedDay.dateUtc);
      }
    });

    this._contextMenuItemEdit.clicked.subscribe(_ => {
      if (this.configuration.canEdit) {
        this.editEntry.next(this._seletedDayId);
      }
    });
    this._contextMenuItemDelete.clicked.subscribe(_ => {
      if (this.configuration.canDelete) {
        this.deleteEntry.next(this._seletedDayId);
      }
    });

    this._contextMenuConfiguration = {
      uniqueName: Guid.create().toString(),
      items: [
        this._contextMenuItemCreate,
        this._contextMenuItemEdit,
        this._contextMenuItemDelete
      ]
    };

    this._contextMenuService.registerContextMenu(this._contextMenuConfiguration);
  }

  private openContextMenu(rightClick: MouseEvent): void {
    const indexAndDayHalf = this.getCalenderDayIndex(rightClick);
    if (!indexAndDayHalf) {
      return;
    }

    this._seletedDay = this.days[indexAndDayHalf.index];
    if (this._seletedDay.isDisabled || !this._seletedDay.isWorkingDay) {
      return;
    }

    if (!this._seletedDay.idLeftHalf && !this._seletedDay.idRightHalf) {
      this._contextMenuItemCreate.isMainAction = true;
      this._contextMenuItemCreate.isHidden = false;
      this._contextMenuItemEdit.isHidden = true;
      this._contextMenuItemDelete.isHidden = true;
      this._seletedDayId = null;

      this._contextMenuService.openContextMenu({
        uniqueName: this._contextMenuConfiguration.uniqueName,
        xPosition: rightClick.clientX,
        yPosition: rightClick.clientY
      });

      return;
    }

    this._contextMenuItemCreate.isHidden = !!this._seletedDay.idLeftHalf && !!this._seletedDay.idRightHalf;
    this._contextMenuItemEdit.isHidden = false;
    this._contextMenuItemDelete.isHidden = false;

    this._contextMenuItemCreate.isMainAction = false;
    this._contextMenuItemEdit.isMainAction = !!this._seletedDay.idLeftHalf || !!this._seletedDay.idRightHalf;

    this._seletedDayId = indexAndDayHalf.isLeftHalf ? this._seletedDay.idLeftHalf : this._seletedDay.idRightHalf;

    this._contextMenuService.openContextMenu({
      uniqueName: this._contextMenuConfiguration.uniqueName,
      xPosition: rightClick.clientX,
      yPosition: rightClick.clientY
    });
  }

  private getCalenderDayIndex(event: MouseEvent): { index: number; isLeftHalf: boolean } {
    const paths = event.composedPath();
    let calendarDayClass = '';
    let isLeftHalf = true;

    for (let indexPath = 0; indexPath < paths.length; indexPath++) {
      const element = paths[indexPath] as Element;
      if (!element.classList) {
        continue;
      }

      for (let indexClassList = 0; indexClassList < element.classList.length; indexClassList++) {
        if (element.classList[indexClassList].startsWith(this._calendarDayClass)) {
          calendarDayClass = element.classList[indexClassList];

          const subElement = paths[indexPath - 1] as Element;
          isLeftHalf = !!subElement.classList && subElement.classList.contains('left-half');

          break;
        }
      }

      if (calendarDayClass) {
        break;
      }
    }

    const dayIndex = parseInt(calendarDayClass.substring(this._calendarDayClass.length), 10);

    return { index: dayIndex, isLeftHalf };
  }


  private hasCalendarDayClass(event: MouseEvent): boolean {
    const paths = event.composedPath();
    let calendarDayClass = '';
    for (const path of paths) {
      const element = path as Element;
      if (!element.classList) {
        continue;
      }

      for (let index = 0; index < element.classList.length; index++) {
        if (element.classList[index].startsWith(this._calendarDayClass)) {
          calendarDayClass = element.classList[index];

          break;
        }
      }

      if (calendarDayClass) {
        break;
      }
    }

    return !!calendarDayClass;
  }

  private calculatePermissions(): void {
    this.isEditable = this.configuration.canCreate || this.configuration.canEdit || this.configuration.canDelete;
    if (this._contextMenuConfiguration) {
      this._contextMenuItemCreate.isDisabled = !this.configuration.canCreate;
      this._contextMenuItemEdit.isDisabled = !this.configuration.canEdit;
      this._contextMenuItemDelete.isDisabled = !this.configuration.canDelete;
    }
  }

  private calculteEntries(): void {
    this.calculatePermissions();

    const days: CalendarDay[] = [];
    const workingDays: moment.Moment[] = this.configuration.workingDays.map(d => moment(d.dateUtc));
    const startOfMonth = moment({ year: this.configuration.year, month: this.configuration.month - 1, day: 1 });
    const endOfMonth = moment(startOfMonth).endOf('month');
    let endRange = moment(endOfMonth);
    let dayWalker = moment(startOfMonth);

    while (dayWalker.isoWeekday() !== 1) {
      dayWalker = dayWalker.add(-1, 'day');
    }

    while (endRange.isoWeekday() !== 7) {
      endRange = endRange.add(1, 'day');
    }

    const holidays = this.configuration.holidays;
    const allUsedDays = this.configuration.usedDays;

    do {
      const holiday = holidays.find(h => moment(h.dateUtc).isSame(dayWalker));
      const usedDays = allUsedDays.filter(d => moment(d.dateUtc).isSame(dayWalker)).sort((a, b) => a.dayRange > b.dayRange ? 1 : (a.dayRange < b.dayRange ? -1 : 0));

      let isUsedDayLeftHalf = false;
      let isUsedDayRightHalf = false;
      let idLeftHalf: number = null;
      let idRightHalf: number = null;
      let tooltipLeftHalf = '';
      let tooltipRightHalf = '';
      let colorLeftHalf = '';
      let colorRightHalf = '';

      if (usedDays.length === 1) {
        switch (usedDays[0].dayRange) {
          case DayRange.firstHalf:
            isUsedDayLeftHalf = true;
            idLeftHalf = usedDays[0].id;
            tooltipLeftHalf = usedDays[0].title;
            colorLeftHalf = usedDays[0].color;
            break;
          case DayRange.secondHalf:
            isUsedDayRightHalf = true;
            idRightHalf = usedDays[0].id;
            tooltipRightHalf = usedDays[0].title;
            colorRightHalf = usedDays[0].color;
            break;
          case DayRange.wholeDay:
            isUsedDayLeftHalf = true;
            isUsedDayRightHalf = true;
            idLeftHalf = usedDays[0].id;
            idRightHalf = usedDays[0].id;
            tooltipLeftHalf = usedDays[0].title;
            tooltipRightHalf = usedDays[0].title;
            colorLeftHalf = usedDays[0].color;
            colorRightHalf = usedDays[0].color;
            break;
        }

      } else if (usedDays.length === 2) {
        isUsedDayLeftHalf = true;
        isUsedDayRightHalf = true;
        idLeftHalf = usedDays[0].id;
        idRightHalf = usedDays[1].id;
        tooltipLeftHalf = usedDays[0].title;
        tooltipRightHalf = usedDays[1].title;
        colorLeftHalf = usedDays[0].color;
        colorRightHalf = usedDays[1].color;
      }

      const day: CalendarDay = {
        dateUtc: moment(dayWalker).toISOString(),
        displayNumber: dayWalker.date().toString(10),
        isDisabled: dayWalker.isBefore(startOfMonth) || dayWalker.isAfter(endOfMonth),
        isHolidayLeftHalf: !!holiday && !holiday.isHalfDay,
        isHolidayRightHalf: !!holiday,
        isUsedDayLeftHalf: isUsedDayLeftHalf,
        isUsedDayRightHalf: isUsedDayRightHalf,
        tooltipLeftHalf: (holiday ? holiday.name : '') + tooltipLeftHalf,
        tooltipRightHalf: (holiday ? (!holiday.isHalfDay ? holiday.name : '') : '') + tooltipRightHalf,
        idLeftHalf: idLeftHalf,
        idRightHalf: idRightHalf,
        isWorkingDay: workingDays.length === 0 || !!workingDays.find(wd => dayWalker.isSame(wd)),
        colorLeftHalf: colorLeftHalf,
        colorRightHalf: colorRightHalf,
      };

      days.push(day);

      dayWalker = dayWalker.add(1, 'day');
    } while (dayWalker.isBefore(endRange));

    this.days = days;
  }
}

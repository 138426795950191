<div class="content-wrapper"
     *ngIf="translationsLoaded">
  <div class="login-wrapper"
       *ngIf="currentAuthenticationState === authenticationState.NotAuthenticated ||currentAuthenticationState === authenticationState.ApplicationAccessDenied">
    <div class="logo">
      <img src="../../../../assets/timeswift-with-background.svg">
    </div>
    <form class="form-signin"
          (ngSubmit)="login()"
          #loginForm="ngForm">
      <h1 class="h3 mb-3 font-weight-normal">{{ 'signIn' | translation: 'signIn' }}</h1>
      <input type="text"
             class="form-control input-top"
             name="username"
             [placeholder]="'username' | translation: 'signIn'"
             [(ngModel)]="credentials.username"
             required
             autofocus>
      <input type="password"
             class="form-control input-bottom"
             name="password"
             [placeholder]="'password' | translation: 'signIn'"
             [(ngModel)]="credentials.password"
             required>
      <button type="submit"
              class="btn btn-outline-secondary btn-block form-button"
              [disabled]="!loginForm.form.valid || isLoading">
        <span *ngIf="!isLoading">
          <fa-icon [icon]="icons.signIn"
                   [fixedWidth]="true"></fa-icon> {{ 'signIn' | translation: 'signIn' }}
        </span>
        <fa-icon *ngIf="isLoading"
                 [icon]="icons.refresh"
                 [spin]="true"></fa-icon>
      </button>
      <div class="text-danger login-error"
           *ngIf="loginFailed"> {{ 'signInError' | translation:'signIn' }} </div>
    </form>
    <div *ngIf="currentAuthenticationState === authenticationState.ApplicationAccessDenied"
         class="alert alert-danger form-signin mt-4">{{ 'applicationAccessDenied'|translation:'signIn'}}</div>
  </div>
  <div class="login-wrapper"
       *ngIf="currentAuthenticationState === authenticationState.RenewPasswordRequired">
    <form class="form-signin"
          (ngSubmit)="updatePassword()"
          #updatePasswordForm="ngForm">
      <h1 class="h3 mb-3 font-weight-normal">{{ 'passwordRenew' | translation: 'signIn' }}</h1>
      <input type="password"
             class="form-control input-top"
             name="oldPassword"
             [placeholder]="'oldPassword' | translation: 'signIn'"
             [(ngModel)]="passwordRenew.oldPassword"
             required
             autofocus>
      <input type="password"
             class="form-control input-middle"
             name="newPassword"
             [placeholder]="'newPassword' | translation: 'signIn'"
             [(ngModel)]="passwordRenew.newPassword"
             required>
      <input type="password"
             class="form-control input-bottom"
             name="newPassword"
             [placeholder]="'retypeNewPassword' | translation: 'signIn'"
             [(ngModel)]="passwordRenew.retypeNewPassword"
             required>
      <button type="submit"
              class="btn btn-outline-secondary btn-block form-button"
              [disabled]="!updatePasswordForm.form.valid || isLoading">
        <span *ngIf="!isLoading">
          <fa-icon [icon]="icons.signIn"
                   [fixedWidth]="true"></fa-icon> {{ 'change' | translation: 'signIn' }}
        </span>
        <fa-icon *ngIf="isLoading"
                 [icon]="icons.refresh"
                 [spin]="true"></fa-icon>
      </button>
      <div class="text-danger login-error"
           *ngIf="loginFailed"> {{ 'changePasswordError' | translation:'signIn' }}<br>
        <span *ngIf="passwordRenew.error">{{passwordRenew.error}}</span>
      </div>
    </form>
  </div>
  <div class="login-wrapper"
       *ngIf="currentAuthenticationState === authenticationState.NoTenantSelected">
    <div class="container-fluid">
      <div class="row pt-3">
        <div class="col-12 mb-3"
             *ngFor="let item of tenants">
          <div class="card bg-light pointer"
               (click)="selectTenant(item)">
            <div class="card-body">
              <h5 class="card-title">{{ 'tenantSelection' | translation: 'signIn' }} </h5>
              <p class="card-text">{{ item.text }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { AuthService, LayoutService, TranslationService, UserApiService } from '../../../core/services';
import { AuthenticationState } from '../../enums';
import { icons } from '../../helper';
import { DropDownItem, UserProfile } from '../../interfaces';
import { AppSettings } from '../../models';
import { LocalStorageService } from '../../services';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  private _isInitialized = false;
  private _subscriptions = new Subscription();
  private _translationSubscription: Subscription;
  private _siginInTranslationSubscription: Subscription;
  private _currentAuthenticationState = AuthenticationState.NotAuthenticated;
  private _userProfile: UserProfile;
  private _selectedTenant: DropDownItem;

  public icons = icons;
  public translationsLoaded = false;

  public credentials = {
    username: '',
    password: ''
  };

  public passwordRenew = {
    oldPassword: '',
    newPassword: '',
    retypeNewPassword: '',
    error: ''
  };

  public isLoading = false;
  public loginFailed = false;
  public tenants: DropDownItem[] = [];
  public currentAuthenticationState = AuthenticationState.NotAuthenticated;
  public authenticationState = AuthenticationState;

  constructor(
    private _localStorageService: LocalStorageService,
    private _authService: AuthService,
    private _routerService: Router,
    private _layoutService: LayoutService,
    private _translationService: TranslationService,
    private _titleService: Title,
    private _userApiService: UserApiService
  ) {
    this._subscriptions.add(
      this._authService.authenticated$.subscribe(
        val => {
          this.currentAuthenticationState = val.authenticationState;
          if (!this._isInitialized) {
            this.initComponent();
          }
        }
      ));
  }

  public ngOnInit(): void {
    this._layoutService.showSideBar(false);
    if (!this._authService.loadStoredUser()) {
      this.initComponent();
    }
  }

  public ngOnDestroy(): void {
    if (this._subscriptions) {
      this._subscriptions.unsubscribe();
      this._subscriptions = null;
    }
  }

  public login(): void {
    if (this.isLoading) {
      return;
    }
    this.loginFailed = false;
    this.isLoading = true;
    this._authService
      .authenticate(this.credentials.username, this.credentials.password)
      .subscribe(authenticationResult => {

        this._currentAuthenticationState = authenticationResult.authenticationState;
        this.isLoading = false;
        if (this._currentAuthenticationState === AuthenticationState.NotAuthenticated || this._currentAuthenticationState === AuthenticationState.ApplicationAccessDenied) {
          this.loginFailed = true;
        }

        this.loadUserProfile();
        if (this._currentAuthenticationState === AuthenticationState.Authenticated) {
          this.completeSignIn();

          return;
        }

        this.tenants = (authenticationResult.tenants || []).filter(t => t.text !== 'AdministrationArea');
        this.isLoading = false;
      });
  }

  public selectTenant(tenant: DropDownItem): void {
    this._selectedTenant = tenant;
    this._authService
      .authenticateWithParent(this.credentials.username, this.credentials.password, tenant)
      .subscribe(authenticationResult => {
        if (authenticationResult.authenticationState === AuthenticationState.Authenticated) {
          this._currentAuthenticationState = authenticationResult.authenticationState;
          this.completeSignIn();
        }
      });
  }

  public updatePassword(): void {
    this.loginFailed = false;
    this.isLoading = true;
    this._userApiService.changePassword({
      userId: this._authService.getUserId(),
      newPassword: this.passwordRenew.newPassword,
      oldPassword: this.passwordRenew.oldPassword
    }).subscribe(
      response => {
        this.isLoading = false;
        if (response.isFaulty) {
          this.passwordRenew.error = this._translationService.translate('signIn', response.message);
          this.loginFailed = true;
        } else {
          this.credentials.username = this._authService.getUsername();
          this.credentials.password = this.passwordRenew.newPassword;
          this.passwordRenew.oldPassword = '';
          this.passwordRenew.newPassword = '';

          if (this._selectedTenant) {
            this.selectTenant(this._selectedTenant);
          } else {
            this.login();
          }
        }
      },
      _ => {
        this.isLoading = false;
        this.loginFailed = true;
      });
  }

  private initComponent(): void {
    this._isInitialized = true;
    this.currentAuthenticationState = this._authService.getAuthenticationState();

    if (this.currentAuthenticationState === AuthenticationState.Authenticated) {
      this.loadGlobalTranslationAndRouteAway(this._authService.hasPermission(['globaladministrator.read']));

      return;
    }

    this.loadSignInTranslations();
  }

  private routeAway(): void {
    const route = this._authService.redirectUrl || '/';
    if (route) {
      this._authService.redirectUrl = '';
      this._routerService.navigate([route]);
    }
  }

  private loadGlobalTranslationAndRouteAway(routeToAdministration: boolean): void {
    if (!this._userProfile) {
      this.loadUserProfile();
    }

    this._translationSubscription = this._translationService.translationsLoaded.subscribe(_ => {
      this._layoutService.showSideBar(true);
      this._translationSubscription.unsubscribe();
      this._translationSubscription = null;

      if (routeToAdministration) {
        this._routerService.navigate(['/administration/tenants']);
      } else {
        this.routeAway();
      }
    });

    this._translationService.loadTranslations(this._userProfile.culture);
  }

  private loadSignInTranslations(): void {
    this._siginInTranslationSubscription = this._translationService.translationsLoaded.subscribe(_ => {
      this.translationsLoaded = true;
      this._siginInTranslationSubscription.unsubscribe();
      this._siginInTranslationSubscription = null;

      this._titleService.setTitle(`${this._translationService.translate('signIn', 'signIn')} - ${AppSettings.appName}`);

      // check if valid user
      if (!this._authService.isAuthenticated() && this._authService.isStoredUserValid()) {
        this._authService.loadStoredUser();
        this._layoutService.showSideBar(true);
        this.loadGlobalTranslationAndRouteAway(false);
      }
    });

    this._translationService.loadTranslationsSignIn('de-DE');
  }

  private loadUserProfile(): void {
    this._userProfile = { id: this._authService.getUserId(), culture: 'de-DE' };
    this._localStorageService.setItem(AppSettings.settings.userProfile, this._userProfile);
  }

  private completeSignIn(): void {
    this.tenants = [];
    this.isLoading = false;
    this.credentials.username = '';
    this.credentials.password = '';
    this.loadGlobalTranslationAndRouteAway(this._authService.hasPermission(['globaladministrator.read']));
  }
}

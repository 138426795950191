import { NG_VALIDATORS, Validator, AbstractControl, ValidationErrors } from '@angular/forms';
import { Directive, Input } from '@angular/core';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[maxValue]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: MaxValueValidatorDirective, multi: true }
  ]
})
export class MaxValueValidatorDirective implements Validator {

  @Input()
  public maxValue: number;

  public validate(c: AbstractControl): ValidationErrors | null {
    if ((!c.value && c.value !== 0) || (!this.maxValue && this.maxValue !== 0)) {
      return null;
    }

    let value = c.value as number;
    const stringValue = c.value.toString();
    if (stringValue && stringValue.indexOf(',') > 0) {
      value = +stringValue.split(',').join('.');
    }

    if ((!this.maxValue && this.maxValue !== 0) || (!value && value !== 0)) {
      return null;
    }

    if (value <= this.maxValue) {
      return null;
    } else {
      return { maxValue: true };
    }
  }
}

<div class="assistant-container w-100 h-100">
  <div class="left">
    <ul class="list-group list-group-flush" *ngIf="!configuration">
      <li class="list-group-item">
        <div class="rounded-circle text-center m-3">
          <fa-icon [icon]="icons.spinner" size="2x" [pulse]="true"></fa-icon>
        </div>
      </li>
    </ul>
    <ul class="list-group list-group-flush" *ngIf="configuration && configuration.dataRecordInformation.show">
      <li class="list-group-item" *ngIf="configuration.dataRecordInformation.firstLineText">
        {{ configuration.dataRecordInformation.firstLineText}}
      </li>
      <li class="list-group-item" *ngIf="configuration.dataRecordInformation.secondLineText ">
        {{ configuration.dataRecordInformation.secondLineText }}
      </li>
      <li class="list-group-item" *ngIf="configuration.dataRecordInformation.showAvatar">
        <div class="rounded-circle avatar m-auto"></div>
      </li>
    </ul>
    <ul class="list-group list-group-flush text-center mt-2" *ngIf="configuration">
      <li class="list-group-item step" [ngClass]="{ 'step-active': item.isActive, 'clickable': item.isClickable }"
        (click)="navigate(item)" *ngFor="let item of configuration.menuItems">{{
        item.translationKey | translation: configuration.translationGroup }}</li>
    </ul>
  </div>
  <div class="right">
    <div class="top-bar header">
      <span class="ps-1">{{ labelProgress }}</span>
    </div>
    <div class="content-container">
      <router-outlet></router-outlet>
    </div>
    <div class="bottom-bar footer d-flex justify-content-end">
      <button *ngIf="configuration && configuration.showReloadBasicDataButton" type="button" class="btn btn-outline-secondary" [disabled]="isLoadingBasicData || isSaving"
        (click)="reloadBasicData()">
        <fa-icon [icon]="icons.refresh" [fixedWidth]="true"></fa-icon>
        {{ 'reloadBasicData' | translation: 'common' }}
      </button>
      <button type="button" class="btn btn-outline-secondary ms-2" [disabled]="!enableNavigationButtons || isFirstStepActive() || isSaving"
        (click)="navigatePrevious()">
        <fa-icon [icon]="icons.previous" [fixedWidth]="true"></fa-icon>
        {{ 'previous' | translation: 'common' }}
      </button>
      <button type="button" class="btn btn-outline-primary ms-2" [disabled]="!enableNavigationButtons || isLastStepActive() || isSaving"
        (click)="navigateNext()">
        <fa-icon [icon]="icons.next" [fixedWidth]="true"></fa-icon>
        {{ 'next' | translation: 'common' }}
      </button>
      <button type="button" class="btn btn-outline-primary ms-2" [disabled]="!enableSaveButton || isSaving" (click)="save()"
        *ngIf="configuration && !configuration.enableSaveOnLastStep">
        <fa-icon [icon]="icons.save" [fixedWidth]="true"></fa-icon>
        {{ 'save' | translation: 'common' }}
      </button>
      <button type="button" class="btn btn-outline-primary ms-2" [disabled]="!enableSaveButton || isSaving" (click)="save()"
        *ngIf="configuration && configuration.enableSaveOnLastStep">
        <fa-icon [icon]="icons.save" [fixedWidth]="true"></fa-icon>
        {{ 'finish' | translation: 'common' }}
      </button>
      <button type="button" class="btn btn-outline-secondary ms-2" [disabled]="isSaving" (click)="cancelAssistant()">
        <fa-icon [icon]="icons.cancel" [fixedWidth]="true"></fa-icon>
        {{ 'cancel' | translation: 'common' }}
      </button>
    </div>
  </div>
</div>

<div class="chart-container {{ kpiChartConfig ? kpiChartConfig.additionalClass : 'initial' }}">
  <div class="header-label mt-2 ms-2"> {{kpiChartConfig?.headerLabel}} </div>
  <div class="value-label mt-2 ms-4"
       *ngIf="kpiChartConfig"
       [ngClass]="kpiChartConfig.valuePosition === 'left' ? 'value-label-left' : 'value-label-right'">{{!isLoading ? kpiChartConfig.valueLabel : ''}}</div>
  <div class="area-chart"
       #chart></div>
  <div class="progess-chart"
       *ngIf="kpiChartConfig && !kpiChartConfig.hideProgress">
    <div class="progress-bar mx-auto">
      <div class="progress-bar-indicator {{kpiChartConfig?.progressColorClass }}"
           [style.width]="kpiChartConfig && kpiChartConfig.progressPercentage >= 0 ? kpiChartConfig.progressPercentage + '%' : ''"></div>
    </div>
  </div>
  <div class="min-label">{{kpiChartConfig?.minLabel}}</div>
  <div class="max-label">{{kpiChartConfig?.maxLabel}}</div>
</div>

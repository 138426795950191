import {
  faAngleDoubleDown,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleDoubleUp,
  faBomb,
  faBuilding,
  faCalendarAlt,
  faChartPie,
  faCheck,
  faClipboard,
  faClipboardCheck,
  faClipboardList,
  faClock,
  faCog,
  faCogs,
  faCopy,
  faDatabase,
  faDollarSign,
  faDownload,
  faEraser,
  faExclamationTriangle,
  faFile,
  faFilter,
  faFlag,
  faGasPump,
  faGlobe,
  faIdCardAlt,
  faInfo,
  faListAlt,
  faListOl,
  faMedkit,
  faMobile,
  faMobileAlt,
  faMoneyCheckAlt,
  faPlay,
  faPlug,
  faPlus,
  faRoad,
  faSave,
  faServer,
  faSignInAlt,
  faSignOutAlt,
  faSort,
  faSortDown,
  faSortUp,
  faSpinner,
  faStar,
  faSyncAlt,
  faTimes,
  faTools,
  faTrash,
  faTrashAlt,
  faTruck,
  faTruckMoving,
  faTshirt,
  faUndo,
  faUser,
  faUserAstronaut,
  faUserCircle,
  faUserGraduate,
  faUserLock,
  faUsers,
  faUserShield,
  faWarehouse,
  faUserCheck,
  faStamp,
  faFileInvoice,
  faDumpster,
  faSearchPlus,
  faQuestionCircle,
  faPen,
  faChevronLeft,
  faRotate
} from '@fortawesome/free-solid-svg-icons';

export const icons = {
  activate: faPlay,
  user: faUser,
  onboarding: faClipboard,
  vehicles: faTruckMoving,
  customers: faClipboardList,
  critical: faBomb,
  calendar: faCalendarAlt,
  applications: faWarehouse,
  copy: faCopy,
  reports: faChartPie,
  users: faUsers,
  settings: faCog,
  adminSettings: faCogs,
  angleDoubleLeft: faAngleDoubleLeft,
  angleDoubleRight: faAngleDoubleRight,
  userCircle: faUserCircle,
  signOut: faSignOutAlt,
  signIn: faSignInAlt,
  save: faSave,
  trash: faTrash,
  delete: faTrashAlt,
  add: faPlus,
  check: faCheck,
  road: faRoad,
  truck: faTruck,
  idCardAlt: faIdCardAlt,
  userLock: faUserLock,
  userRoles: faUserAstronaut,
  tenants: faUserGraduate,
  refresh: faSyncAlt,
  reset: faUndo,
  close: faTimes,
  clear: faEraser,
  filter: faFilter,
  cancel: faTimes,
  info: faInfo,
  exclamation: faExclamationTriangle,
  sortNone: faSort,
  sortAsc: faSortUp,
  sortDesc: faSortDown,
  roles: faUserShield,
  spinner: faSpinner,
  company: faBuilding,
  fuel: faGasPump,
  time: faClock,
  timeInterval: faClock,
  healthInsurance: faMedkit,
  document: faFile,
  next: faAngleDoubleRight,
  previous: faAngleDoubleLeft,
  down: faAngleDoubleDown,
  up: faAngleDoubleUp,
  country: faGlobe,
  mobileAppVersion: faMobileAlt,
  mobilePhoneContract: faMobile,
  mobilePhoneProvider: faMobile,
  mobilePhoneTariff: faMobileAlt,
  nationality: faFlag,
  qualification: faClipboardCheck,
  clothing: faTshirt,
  toolsAndMaterials: faTools,
  inventoryNumbers: faListOl,
  costType: faDollarSign,
  costCenter: faDollarSign,
  database: faDatabase,
  financialInstitution: faBuilding,
  download: faDownload,
  plugins: faPlug,
  salary: faMoneyCheckAlt,
  logs: faServer,
  workOrders: faListAlt,
  ratingStar: faStar,
  qualityCheck: faUserCheck,
  accounting: faStamp,
  invoice: faFileInvoice,
  notBillable: faDumpster,
  magnifier: faSearchPlus,
  support: faQuestionCircle,
  edit: faPen,
  toLeft: faChevronLeft,
  reload: faRotate
};

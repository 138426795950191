import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CommonSettingItem, CommonSettings, ResponseDataObject } from '../../../shared/interfaces';
import { AppSettings } from '../../../shared/models';

@Injectable({
  providedIn: 'root'
})
export class CommonSettingsApiService {

  private _baseUrl = AppSettings.endpoints.application.commonSettings;

  constructor(
    private _http: HttpClient
  ) { }

  public getLogo(): Observable<ResponseDataObject<string>> {
    return this._http.get<ResponseDataObject<string>>(this._baseUrl + '/logo');
  }

  public getSettings(): Observable<ResponseDataObject<CommonSettings>> {
    return this._http.get<ResponseDataObject<CommonSettings>>(this._baseUrl);
  }

  public getSettingsByIds(ids: number[]): Observable<ResponseDataObject<CommonSettingItem[]>> {
    return this._http.post<ResponseDataObject<CommonSettingItem[]>>(this._baseUrl, ids);
  }

  public updateSettings(settings: CommonSettings): Observable<ResponseDataObject<CommonSettings>> {
    return this._http.put<ResponseDataObject<CommonSettings>>(this._baseUrl, settings);
  }
}

import { DecimalPipe } from '@angular/common';
import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import * as d3 from 'd3';
import * as d3Scale from 'd3-scale';
import * as d3Shape from 'd3-shape';
import { TranslationService } from '../../../../core/services';
import { DonutChartConfig, DonutChartData } from '../../../models/charts';

@Component({
  selector: 'app-donut-chart',
  templateUrl: './donut-chart.component.html',
  styleUrls: ['./donut-chart.component.scss']
})
export class DonutChartComponent {

  private _svg: any;
  private _radius: number;
  private _arc: any;
  private _arcExploded: any;
  private _pie: any;
  private _color: any;
  private _donutChartConfig: DonutChartConfig;
  private _height: any;
  private _width: any;
  private _legendText: any;
  private _offsetLeftArc = 0;
  private _isLoading = false;

  @Input()
  public set isLoading(value: boolean) {
    setTimeout(() => {
      if (this._svg) {
        this.drawChart(this._isLoading);
      }
    });

    this._isLoading = value;
  }
  public get isLoading(): boolean {
    return this._isLoading;
  }

  @Input()
  public set donutChartConfig(donutChartInfo: DonutChartConfig) {
    this._donutChartConfig = donutChartInfo;
    if (this._donutChartConfig) {
      setTimeout(() => {
        if (this.initSvg()) {
          this.drawChart(this._isLoading);
        }
      });
    }
  }
  public get donutChartConfig(): DonutChartConfig {
    return this._donutChartConfig;
  }

  @ViewChild('chart', { static: true })
  public chart: ElementRef;

  constructor(
    private _translationService: TranslationService,
    private _decimalPipe: DecimalPipe
  ) { }

  private initSvg(): boolean {

    const margin = {
      top: 5,
      right: 5,
      bottom: 5,
      left: 5
    };

    this._offsetLeftArc = this._donutChartConfig.hideLegend ? 0 : 60;

    const donutChartDiv = this.chart.nativeElement as HTMLDivElement;
    donutChartDiv.id = this._donutChartConfig.identifier + '_c';
    this._width = donutChartDiv.clientWidth;
    this._height = donutChartDiv.clientHeight;

    while (donutChartDiv.lastChild) {
      donutChartDiv.removeChild(donutChartDiv.lastChild);
    }

    this._radius = Math.min(this._width, this._height) / 2.3;
    let chartColors = this._donutChartConfig.chartColors;

    if (this._donutChartConfig.chartData.filter(f => f.value > 0).length === 0) {
      chartColors = ['#C0C0C0', ...chartColors];
    }

    this._color = d3Scale.scaleOrdinal()
      .range(chartColors);

    this._arc = d3Shape.arc()
      .innerRadius(this._radius - this._donutChartConfig.donutWidth)
      .outerRadius(this._radius)
      .cornerRadius(0);

    this._pie = d3Shape.pie()
      .sort(null)
      .value((d: any) => d.value);

    this._svg = d3.select(this.chart.nativeElement)
      .append('svg')
      .attr('width', this._width - margin.left - margin.right)
      .attr('height', this._height - margin.top - margin.bottom)
      .attr('style', 'border: transparent')
      .append('g')
      .attr('transform', `translate(${this._width / 2 + margin.left}, ${this._height / 2 - 7})`);

    if (!this._donutChartConfig.chartData) {
      this._svg.append('text')
        .attr('x', 0)
        .attr('y', 0)
        .style('text-anchor', 'middle')
        .text(this._translationService.translate('common', 'noData'));

      return false;
    }

    return true;
  }

  private drawChart(isLoading: boolean): void {

    if (isLoading || !this._donutChartConfig) {
      return;
    }

    const data = this._donutChartConfig.chartData;

    const nodata: DonutChartData[] = [];
    if (data.filter(f => f.value > 0).length === 0) {
      nodata.push({ key: 'NODATA', value: 100 });
    }

    const g = this._svg.selectAll('.arc')
      .data(this._pie(nodata.length > 0 ? nodata : data))
      .enter()
      .append('g')
      .attr('id', (d: any) => this.getItemIdentifier(d.data))
      .attr('class', 'arc')
      .attr('transform', `translate(${this._offsetLeftArc}, 8)`);

    this._pie(data);
    const angleInterpolation = d3.interpolate(this._pie.startAngle()(), this._pie.endAngle()());

    g.append('path')
      .style('cursor', (d: any) => d.data.key !== 'NODATA' ? 'pointer' : 'default')
      .style('fill', (d: any) => this._color(d.data.key))
      .on('click', (_: any, d: any) => {
        if (d.data.key !== 'NODATA') {
          this.clickEvent(data, d);
        }
      })
      .transition()
      .duration(1000)
      .attrTween('d', d => {
        const originalEnd = d.endAngle;

        return t => {
          const currentAngle = angleInterpolation(t);
          if (currentAngle < d.startAngle) {

            return '';
          }

          d.endAngle = Math.min(currentAngle, originalEnd);

          return this._arc(d);
        };
      });

    if (!this._donutChartConfig.hideLegend) {
      const legend = this._svg.append('g')
        .attr('transform', `translate(${-(this._width / 2) + 18}, ${-(this._height / 2) - 4})`);

      this._legendText = legend.selectAll('.legend')
        .data(this._pie(data))
        .enter().append('g')
        .style('cursor', d => d.data.value > 0 ? 'pointer' : 'default')
        .attr('id', d => 'L' + this.getItemIdentifier(d.data))
        .attr('transform', (d: any, i: any) => 'translate(0,' + (i * 40 + 30) + ')')
        .attr('class', 'legend')
        .style('opacity', d => d.data.value > 0 ? 1 : 0.1);

      this._legendText.append('rect')
        .attr('width', 30)
        .attr('rx', 2)
        .attr('ry', 2)
        .attr('height', 20)
        .style('cursor', d => d.data.value > 0 ? 'pointer' : 'default')
        .style('fill', d => this._color(d.data.key));

      this.legendText();

      this._legendText.on('click', (_: any, d: any) => {
        this.clickEvent(data, d);
      });
    }

    setTimeout(() => {
      if (data) {
        const maxValue = data.length > 0
          ? data.map(d => d.value).reduce((a, b) => Math.max(a, b))
          : 0;

        if (maxValue > 0) {
          this._arcExploded = d3Shape.arc()
            .innerRadius(this._radius - this._donutChartConfig.donutWidth - 3)
            .outerRadius(this._radius + 3)
            .cornerRadius(0);

          const selectedData = data.find(d => d.value === maxValue);
          const selected = this._svg.select('#' + this.getItemIdentifier(selectedData));
          selected.select('path').attr('d', this._arcExploded);

          this.centerText(selectedData);
        } else {
          this.centerNoDataText();
        }
      }
    }, 1100);
  }

  private clickEvent(data: any, d: any): void {
    if (d.value === 0) {
      return;
    }

    const dataKey = this.getItemIdentifier(d.data);
    data.forEach(item => {
      const itemKey = this.getItemIdentifier(item);
      if (itemKey === dataKey) {
        const selected = this._svg.select('#' + dataKey);
        selected.select('path').attr('d', this._arcExploded);
      } else {
        const selected = this._svg.select('#' + itemKey);
        selected.select('path').attr('d', this._arc);
      }
    });

    this._svg.selectAll('text').remove();

    this.centerText(d.data);
    if (!this._donutChartConfig.hideLegend) {
      this.legendText();
    }
  }

  private centerText(data: any): void {
    this._svg.append('text')
      .attr('class', 'centerText')
      .attr('dy', '19')
      .attr('dx', `${this._offsetLeftArc}`)
      .attr('text-anchor', 'middle')
      .attr('fill', () => this._color(data.key))
      .style('font-size', 'var(--font-size-50)')
      .style('font-weight', 'bold')
      .text(this._decimalPipe.transform(data.value));

    this._svg.append('text')
      .attr('class', 'centerText')
      .attr('dy', '40')
      .attr('dx', `${this._offsetLeftArc}`)
      .attr('text-anchor', 'middle')
      .attr('fill', () => this._color(data.key))
      .style('font-size', 'var(--font-size-16)')
      .style('font-weight', 'bold')
      .text(data.key);
  }

  private centerNoDataText(): void {
    this._svg.append('text')
      .attr('class', 'centerText')
      .attr('dy', '19')
      .attr('dx', `${this._offsetLeftArc}`)
      .attr('text-anchor', 'middle')
      .attr('fill', () => 'var(--chart-color-no-data)')
      .style('font-size', '3.125rem')
      .style('font-weight', 'bold')
      .text(0);
  }

  private legendText(): void {
    this._legendText.append('text')
      .text((d: { data: { key: any } }) => d.data.key)
      .style('font-size', 'var(--font-size-14)')
      .attr('y', 16)
      .attr('x', 40);
  }

  private getItemIdentifier(data: { key: string; identifier?: string }): string {
    const key = data.identifier || data.key;

    return this._donutChartConfig.identifier + key.replace(' ', '');
  }
}

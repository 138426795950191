import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';

import { TranslationService } from '../../../core/services';
import { icons } from '../../helper';
import { TreeViewConfiguration, TreeViewEntry, TreeViewEntryMove } from '../../models';

@Component({
  selector: 'app-tree-view',
  templateUrl: './tree-view.component.html',
  styleUrls: ['./tree-view.component.scss']
})
export class TreeViewComponent implements OnInit, OnDestroy {
  private _subscriptions: Subscription = new Subscription();

  @Input()
  public configuration: TreeViewConfiguration;

  @Output()
  public entryAdded: EventEmitter<TreeViewEntry> = new EventEmitter<TreeViewEntry>();

  @Output()
  public entrySelected: EventEmitter<TreeViewEntry> = new EventEmitter<TreeViewEntry>();

  @Output()
  public entryMoved: EventEmitter<TreeViewEntryMove> = new EventEmitter<TreeViewEntryMove>();

  public icons = icons;

  constructor(
    private _translationService: TranslationService
  ) { }

  public ngOnInit(): void {
    if (this.configuration.selectEntry$) {
      this._subscriptions.add(
        this.configuration.selectEntry$.subscribe(e => {
          this.selectEntry(e);
        })
      );
    }

    if (this.configuration.deleteEntry$) {
      this._subscriptions.add(
        this.configuration.deleteEntry$.subscribe(e => {
          this.deleteEntry(e);
        })
      );
    }

    if (this.configuration.selectEntryData$) {
      this._subscriptions.add(
        this.configuration.selectEntryData$.subscribe(data => {
          this.selectEntryData(data);
        })
      );
    }
    if (this.configuration.deleteEntryData$) {
      this._subscriptions.add(
        this.configuration.deleteEntryData$.subscribe(data => {
          this.deleteEntryData(data);
        })
      );
    }
  }

  public ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  public getLabelAddEntry(level: number): string {
    return this.getLabelEntry(level, 'addTreeViewEntry');
  }

  public getLabelNewEntry(level: number): string {
    return this.getLabelEntry(level, 'newTreeViewEntry');
  }

  public addEntry(parentEntry: TreeViewEntry): void {
    this.entryAdded.next(parentEntry);
  }

  public selectEntry(entry: TreeViewEntry): void {
    if (entry.isDisabled) {
      return;
    }

    this.unselectAllEntries(this.configuration.entries);
    entry.isSelected = true;

    this.entrySelected.next(entry);
  }

  public drop(event: CdkDragDrop<TreeViewEntry[]>, source: TreeViewEntry[]): void {
    if (event.previousContainer === event.container) {
      const data = source[event.previousIndex];
      moveItemInArray(source, event.previousIndex, event.currentIndex);
      this.entryMoved.next({
        oldIndex: event.previousIndex,
        newIndex: event.currentIndex,
        entry: data
      });
    }
  }

  private deleteEntry(entry: TreeViewEntry): void {
    this.deleteEntryFromTree(entry, this.configuration.entries);
  }

  private deleteEntryFromTree(entry: TreeViewEntry, entries: TreeViewEntry[]): void {
    if (!entries || entries.length === 0) {
      return;
    }

    for (let index = 0; index < entries.length; index++) {
      if (entry === entries[index]) {
        entries.splice(index, 1);

        return;
      }

      this.deleteEntryFromTree(entry, entries[index].entries);
    }
  }

  private selectEntryData(data: any): void {
    const entry = this.getEntryByData(this.configuration.entries, data, false);
    if (entry) {
      this.selectEntry(entry);
    }
  }

  private getEntryByData(entries: TreeViewEntry[], data: any, remove: boolean): TreeViewEntry {
    if (!entries || entries.length === 0) {
      return null;
    }

    for (let index = 0; index < entries.length; index++) {
      if (entries[index].data === data) {
        return this.checkEntry(entries[index], entries, index, remove);
      }

      const entry = this.getEntryByData(entries[index].entries, data, remove);
      if (entry) {
        return entry;
      }
    }

    return null;
  }

  private checkEntry(entry: TreeViewEntry, entries: TreeViewEntry[], index: number, remove: boolean): TreeViewEntry {
    if (!entry) {
      return;
    }

    if (remove) {
      entries.splice(index, 1);
    }

    return entry;
  }

  private deleteEntryData(data: any): void {
    this.getEntryByData(this.configuration.entries, data, true);
  }

  private unselectAllEntries(entries: TreeViewEntry[]): void {
    if (!entries || entries.length === 0) {
      return;
    }

    entries.forEach(e => {
      e.isSelected = false;
      this.unselectAllEntries(e.entries);
    });
  }

  private getLabelEntry(level: number, baseKey: string): string {
    if (!this.configuration.translationGroup) {
      return this._translationService.translate('common', baseKey);
    }

    const label = this._translationService.translate(this.configuration.translationGroup || 'common', baseKey + level);
    if (label.indexOf('missing') === -1) {
      return label;
    }

    return this._translationService.translate(this.configuration.translationGroup || 'common', baseKey);
  }
}

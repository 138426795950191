import { animate, query, style, transition, trigger } from '@angular/animations';
import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { NotificationService } from '../../../core/services';
import { NotificationButtonKind } from '../../enums';
import { icons } from '../../helper';
import {
  NotificationDialogButton,
  NotificationDialogData,
  NotificationSafeDialogData,
  NotificationSafeToastData,
  NotificationToastData,
} from '../../interfaces';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('fadeInOut', [
      transition('* => *', [ // each time the binding value changes
        query(':leave', [
          animate('0.5s', style({ opacity: 0, height: 0 }))
        ], { optional: true }),
        query(':enter', [
          style({ opacity: 0 }),
          animate('0.5s', style({ opacity: 1 }))
        ], { optional: true })
      ])
    ])
  ]
})
export class NotificationComponent {
  private _modalRef: NgbModalRef;

  public toasts: NotificationSafeToastData[] = [];
  public dialogs: NotificationDialogData[] = [];
  public dialog: NotificationSafeDialogData;

  @ViewChild('dialogTemplate')
  public dialogTemplate: any;

  constructor(
    private _dialogService: NotificationService,
    private _modalService: NgbModal,
    private _sanitizer: DomSanitizer
  ) {
    this._dialogService.dialog$.subscribe(
      dialogData => this.addDialog(dialogData)
    );
    this._dialogService.toast$.subscribe(
      toastData => this.addToast(toastData)
    );
  }

  public buttonClick(button: NotificationDialogButton): void {
    if (button.action) {
      button.action();
    }
    this.closeDialog();
  }

  public closeDialog(): void {
    this._modalRef.close();
    this._modalRef = null;
    this.showDialog();
  }

  public closeToast(toast: NotificationSafeToastData): void {
    const index = this.toasts.indexOf(toast);
    if (index >= 0) {
      this.toasts.splice(index, 1);
    }
  }

  private addDialog(dialogData: NotificationDialogData): void {
    if (!dialogData.buttons || dialogData.buttons.length === 0) {
      dialogData.buttons = [{
        caption: 'Close',
        kind: NotificationButtonKind.Primary,
        icon: icons.close
      }];
    }

    this.dialogs.push(dialogData);
    this.showDialog();
  }

  private addToast(toastData: NotificationToastData): void {
    const safeToast: NotificationSafeToastData = {
      icon: toastData.icon,
      kind: toastData.kind,
      message: toastData.message,
      title: toastData.title,
      safeHtml: this._sanitizer.bypassSecurityTrustHtml(toastData.message),
      timeout: toastData.timeout
    };
    this.toasts.push(safeToast);
    setTimeout(() => {
      this.closeToast(safeToast);
    }, safeToast.timeout || 5000);
  }

  private showDialog(): void {
    if (this._modalRef) {
      return;
    }
    const dialogData = this.dialogs.shift();
    if (dialogData) {
      this.dialog = {
        buttons: dialogData.buttons,
        kind: dialogData.kind,
        message: dialogData.message,
        title: dialogData.title,
        safeHtml: this._sanitizer.bypassSecurityTrustHtml(dialogData.message)
      };
      this._modalRef = this._modalService.open(
        this.dialogTemplate,
        { size: 'lg', centered: true, backdrop: 'static', windowClass: dialogData.kind, keyboard: false }
      );
    }
  }
}

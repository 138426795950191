import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';

import { LocaleConstants } from '../../constants';
import { DropDownItem } from '../../interfaces';
import { LocalStorageService } from '../../services';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[numberValidator][ngModel],[numberValidator][formControl]',
  providers: [{ provide: NG_VALIDATORS, useExisting: NumberValidatorDirective, multi: true }]
})
export class NumberValidatorDirective implements Validator {

  // tslint:disable-next-line:no-input-rename
  @Input('numberValidator')
  public type: 'Integer' | 'DecimalOne' | 'DecimalTwo' | 'DecimalThree' | 'Currency' = 'Integer';

  constructor(
    private _localStorageService: LocalStorageService
  ) { }

  public validate(control: AbstractControl): { [key: string]: any } | null {

    if (!control.value) {
      return null;
    }

    const culture = this._localStorageService.userProfile.culture;

    switch (this.type) {
      case 'Currency':
        return this.validateWithRegEx(LocaleConstants.CurrencyFormat, culture, control.value);
      case 'DecimalOne':
        return this.validateWithRegEx(LocaleConstants.DecimalOneFormat, culture, control.value);
      case 'DecimalTwo':
        return this.validateWithRegEx(LocaleConstants.DecimalTwoFormat, culture, control.value);
      case 'DecimalThree':
        return this.validateWithRegEx(LocaleConstants.DecimalThreeFormat, culture, control.value);
      case 'Integer':
      default:
        return this.validateWithRegEx(LocaleConstants.Integerformat, culture, control.value);
    }
  }

  private validateWithRegEx(formats: DropDownItem[], culture: string, value: string): { [key: string]: any } | null {
    const format = formats.find(df => df.value === culture);

    if (!format) {
      return { 'number': { value: 'unknownCulture' } };
    }
    const regEx = new RegExp(format.text);
    const result = regEx.test(value);
    if (result) {
      return null;
    }

    return this.createErrorResult(value);
  }

  private createErrorResult(value: string): { [key: string]: any } {
    return { 'number': { value: value } };
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { EmployeeSalary, GridSearchParameter, ResponseDataObject, SearchApiService } from '../../../shared/interfaces';
import { AppSettings } from '../../../shared/models';

@Injectable({
  providedIn: 'root'
})
export class EmployeeSalaryApiService implements SearchApiService<EmployeeSalary> {

  private _baseUrl = AppSettings.endpoints.application.employeeSalary;

  constructor(
    private http: HttpClient
  ) { }
  public search(parameter: GridSearchParameter): Observable<ResponseDataObject<EmployeeSalary[]>> {
    return this.http.post<any>(this._baseUrl + '/search', parameter);
  }
  public searchSum(parameter: GridSearchParameter): Observable<ResponseDataObject<EmployeeSalary>> {
    return this.http.post<any>(this._baseUrl + '/search/sum', parameter);
  }
}

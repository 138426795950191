<div class="picture-overlay"
     [class.open]="isOpen">
  <img class="picture"
       [class.full-size]="fullSize"
       *ngIf="imageData"
       [src]="imageData"
       (click)="fullSize=!fullSize">
</div>
<fa-icon class="close"
         [class.open]="isOpen"
         [icon]="icons.cancel"
         [fixedWidth]="true"
         (click)="closeOverlay()"></fa-icon>

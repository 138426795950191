import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';

import { CustomValidatorOptions } from '../../../interfaces';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'text-form-control',
  templateUrl: './text-form-control.component.html',
  styleUrls: ['./text-form-control.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class TextFormControlComponent {

  @Input()
  public label: string;

  @Input()
  public value: string;

  @Output()
  public valueChange = new EventEmitter<string>();

  @Input()
  public name: string;

  @Input()
  public isRequired = false;

  @Input()
  public prependIcon: IconDefinition;

  @Input()
  public appendIcon: IconDefinition;

  @Input()
  public appendIconText = '';

  @Input()
  public placeholder = '';

  @Input()
  public maxlength: number = null;

  @Input()
  public isDisabled = false;

  @Input()
  public isReadonly = false;

  @Input()
  public hideLabel = false;

  @Input()
  public isPassword = false;

  @Input()
  public letterCase: 'UpperCase' | 'LowerCase' | 'MixedCase' | 'None' = 'None';

  @Input()
  public customValidator: CustomValidatorOptions;

  @Input()
  public hideErrors = false;

  constructor() { }

  public onValueChange(data: string): void {
    this.value = data;
    this.valueChange.emit(this.value);
  }

}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'iban-form-control',
  templateUrl: './iban-form-control.component.html',
  styleUrls: ['./iban-form-control.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class IbanFormControlComponent {

  @Input()
  public label: string;

  @Input()
  public value: string;

  @Output()
  public valueChange = new EventEmitter<string>();

  @Input()
  public name: string;

  @Input()
  public isRequired = false;

  @Input()
  public prependIcon: IconDefinition;

  @Input()
  public appendIcon: IconDefinition;

  @Input()
  public appendIconText = '';

  @Input()
  public placeholder = '';

  @Input()
  public isDisabled = false;

  @Input()
  public isReadonly = false;

  @Input()
  public hideLabel = false;

  @Input()
  public hideErrors = false;

  constructor() { }

  public onValueChange(data: string): void {
    this.value = data;
    this.valueChange.emit(this.value);
  }
}

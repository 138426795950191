import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

import { AuthService } from '../../core/services';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[hasRight]'
})
export class HasRightDirective {

  private _rights: string[] = [];
  private _isHidden = true;


  constructor(
    private _templateRef: TemplateRef<any>,
    private _viewContainer: ViewContainerRef,
    private _authService: AuthService
  ) { }

  @Input()
  public set hasRight(rights: string[]) {
    this._rights = rights || [];
    this.updateView();
  }

  private updateView(): void {
    if (this._authService.hasPermission(this._rights)) {
      if (this._isHidden) {
        this._viewContainer.createEmbeddedView(this._templateRef);
        this._isHidden = false;
      }
    } else {
      this._isHidden = true;
      this._viewContainer.clear();
    }
  }

}

import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

import { DateTimeLogicLayer } from '../../core/business-logic/dateTime.logicLayer';
import { FieldDataType } from '../enums';
import { LocalStorageService } from '../services';

@Pipe({
  name: 'localeDate'
})
export class LocaleDatePipe implements PipeTransform {

  constructor(
    private _localStorageService: LocalStorageService,
    private _dateTimeLogicLayer: DateTimeLogicLayer
  ) { }

  public transform(value: string, args?: any): string {
    if (!value) {
      return value;
    }

    const date = moment(value);
    const userCulture = this._localStorageService.userProfile.culture;

    return date.format(this._dateTimeLogicLayer.getDateTimeFormat(FieldDataType.date, userCulture));
  }

}

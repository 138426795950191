import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { EditorConfiguration } from 'src/app/shared/interfaces';

@Injectable({
  providedIn: 'root'
})
export class EditorControlBarService {

  private _subjectConfigurationChanged: Subject<EditorConfiguration> = new Subject<EditorConfiguration>();
  private _subjectAddButtonStatusChanged: Subject<boolean> = new Subject<boolean>();
  private _subjectSaveButtonStatusChanged: Subject<boolean> = new Subject<boolean>();
  private _subjectDeleteButtonStatusChanged: Subject<boolean> = new Subject<boolean>();
  private _subjectAddButtonClicked: Subject<any> = new Subject<any>();
  private _subjectSaveButtonClicked: Subject<any> = new Subject<any>();
  private _subjectDeleteButtonClicked: Subject<any> = new Subject<any>();
  private _subjectCancelButtonClicked: Subject<any> = new Subject<any>();
  private _subjectControlBarStatusChanged: Subject<boolean> = new Subject<boolean>();
  private _subjectVisibilityChanged: Subject<boolean> = new Subject<boolean>();

  public configurationChanged = this._subjectConfigurationChanged.asObservable();
  public addButtonStatusChanged = this._subjectAddButtonStatusChanged.asObservable();
  public saveButtonStatusChanged = this._subjectSaveButtonStatusChanged.asObservable();
  public deleteButtonStatusChanged = this._subjectDeleteButtonStatusChanged.asObservable();
  public addButtonClicked = this._subjectAddButtonClicked.asObservable();
  public saveButtonClicked = this._subjectSaveButtonClicked.asObservable();
  public deleteButtonClicked = this._subjectDeleteButtonClicked.asObservable();
  public cancelButtonClicked = this._subjectCancelButtonClicked.asObservable();
  public controlBarStatusChanged = this._subjectControlBarStatusChanged.asObservable();
  public visibilityChanged = this._subjectVisibilityChanged.asObservable();

  constructor() { }

  public configureControlBar(configuration: EditorConfiguration): void {
    this._subjectConfigurationChanged.next(configuration);
  }

  public destroyControlBar(): void {
    this._subjectConfigurationChanged.next(null);
  }

  public enableAddButton(enabled: boolean): void {
    this._subjectAddButtonStatusChanged.next(enabled);
  }

  public enableSaveButton(enabled: boolean): void {
    this._subjectSaveButtonStatusChanged.next(enabled);
  }

  public enableDeleteButton(enabled: boolean): void {
    this._subjectDeleteButtonStatusChanged.next(enabled);
  }

  public add(): void {
    this._subjectAddButtonClicked.next(null);
  }

  public save(): void {
    this._subjectSaveButtonClicked.next(null);
  }

  public delete(): void {
    this._subjectDeleteButtonClicked.next(null);
  }

  public cancel(): void {
    this._subjectCancelButtonClicked.next(null);
  }

  public changeControlBarStatus(enabled: boolean): void {
    this._subjectControlBarStatusChanged.next(enabled);
  }

  public changeVisibility(visible: boolean): void {
    this._subjectVisibilityChanged.next(visible);
  }
}

export enum LoginResultType {
  AccountCredentialsInvalid = 0,
  AccountInactive = 1,
  AccountLocked = 2,
  NoTenantAssigned = 3,
  LoginSuccessfull = 4,
  UserDataManipulated = 5,
  RenewPasswordRequired = 6,
  InvalidApplication = 7,
  ApplicationAccessDenied = 8,
  UnknownError = 9
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';

import { icons } from '../../../helper';
import { CustomValidatorOptions } from '../../../interfaces';

@Component({
  selector: 'app-time-picker-form-control',
  templateUrl: './time-picker-form-control.component.html',
  styleUrls: ['./time-picker-form-control.component.scss']
})
export class TimePickerFormControlComponent implements OnInit {

  private _transitionSupported = true;
  private _innerRadius = 54;
  private _outerRadius = 80;

  private _duration = this._transitionSupported ? 350 : 1;
  private _formattedData: string;
  private _timeValue = {
    hour: 0,
    minute: 0
  };

  public dialRadius = 100;
  public tickRadius = 13;
  public diameter = this.dialRadius * 2;
  public clockPickerHandCx = 0;
  public clockPickerHandCy = 0;

  public icons = icons;

  public timeValidationOptions: CustomValidatorOptions = {
    regExp: /^([0-1][0-9]|[2][0-3]):{0,1}([0-5][0-9])$/,
    minLength: 4,
    errorKey: 'invalidTime'
  };

  public showHours = true;
  public showMinutes = false;

  public hours: TimeNumberSelector[] = [];
  public minutes: TimeNumberSelector[] = [];

  @Input()
  public label: string;

  @Input()
  public set value(value: string) {
    if (value && this.timeValidationOptions.regExp.test(value)) {

      if (value.indexOf(':') === -1) {
        value = value.substring(0, 2) + ':' + value.substring(2, 4);
      }
      this._formattedData = value;

      this._timeValue.hour = parseInt(this._formattedData.split(':')[0], 10);
      this._timeValue.minute = parseInt(this._formattedData.split(':')[1], 10);
    } else {
      this._formattedData = null;
      this._timeValue.hour = 0;
      this._timeValue.minute = 0;
    }

    this.setClockHand();
  }

  public get value(): string {
    return this._formattedData;
  }

  @Output()
  public valueChange = new EventEmitter<string>();

  @Input()
  public isRequired = false;

  @Input()
  public isDisabled = false;
  @Input()
  public isReadonly = false;

  @Input()
  public minTime: string;

  @Input()
  public maxTime: string;

  @Input()
  public name: string;

  @Input()
  public hideLabel = false;

  @Input()
  public errorMessages: any;

  constructor() { }

  public ngOnInit(): void {
    for (let hour = 1; hour <= 24; hour++) {
      const radian = hour / 6 * Math.PI;
      const inner = hour > 0 && hour < 13;
      const radius = inner ? this._innerRadius : this._outerRadius;

      this.hours.push({
        value: this.formatNumber(hour === 24 ? 0 : hour),
        inner: inner,
        left: `${this.dialRadius + Math.sin(radian) * radius - this.tickRadius}px`,
        top: `${this.dialRadius - Math.cos(radian) * radius - this.tickRadius}px`
      });
    }

    for (let minute = 0; minute < 60; minute += 5) {

      const radian = minute / 30 * Math.PI;
      this.minutes.push({
        value: this.formatNumber(minute),
        inner: false,
        left: `${this.dialRadius + Math.sin(radian) * this._outerRadius - this.tickRadius}px`,
        top: `${this.dialRadius - Math.cos(radian) * this._outerRadius - this.tickRadius}px`
      });
    }
  }

  public onValueChange(data: string): void {
    if (this.timeValidationOptions.regExp.test(data)) {

      if (data.indexOf(':') === -1) {
        data = data.substring(0, 2) + ':' + data.substring(2, 4);
      }

      this.value = data;
      this._formattedData = data;
      this.valueChange.emit(this.value);
    } else {
      this.value = null;
      this._formattedData = null;
      this._timeValue.hour = 0;
      this._timeValue.minute = 0;
      this.valueChange.emit(null);
    }
  }

  public openTimePicker(popover: NgbPopover): void {
    if (this.isDisabled || this.isReadonly) {
      return;
    }

    if (popover.isOpen()) {
      popover.close();
    } else {
      this.switchToHours();
      popover.open();
    }
  }

  public getHours(): string {
    return this.formatNumber(this._timeValue.hour);
  }

  public getMinutes(): string {
    return this.formatNumber(this._timeValue.minute);
  }

  public switchToHours(): void {
    this.showHours = true;
    this.showMinutes = false;
    this.setClockHand();
  }

  public switchToMinutes(): void {
    this.showHours = false;
    this.showMinutes = true;
    this.setClockHand();
  }

  public onSelectedTime(time: string): void {
    if (this.showHours) {
      this._timeValue.hour = parseInt(time, 10);
      this.switchToMinutes();
    } else {
      this._timeValue.minute = parseInt(time, 10);
    }
    this._formattedData = `${this.formatNumber(this._timeValue.hour)}:${this.formatNumber(this._timeValue.minute)}`;
    this.setClockHand();
    this.onClosedPopover();
  }

  public onClosedPopover(): void {
    this.valueChange.emit(this.value);
  }

  private formatNumber(time: number): string {
    if (time < 10) {
      return `0${time}`;
    }

    return time.toString(10);
  }

  private setClockHand(): void {
    const isHours = this.showHours;
    const value = isHours ? this._timeValue.hour : this._timeValue.minute;
    const unit = Math.PI / (isHours ? 6 : 30);
    const radian = value * unit;
    const radius = isHours && value > 0 && value < 13 ? this._innerRadius : this._outerRadius;

    const x = Math.sin(radian) * radius;
    const y = - Math.cos(radian) * radius;

    this.setHand(x, y, false);
  }

  private setHand(x: number, y: number, roundBy5: boolean): void {
    let radian = Math.atan2(x, - y);
    const isHours = this.showHours;
    const unit = Math.PI / (isHours || roundBy5 ? 6 : 30);
    const z = Math.sqrt(x * x + y * y);
    const inner = isHours && z < (this._outerRadius + this._innerRadius) / 2;
    const radius = inner ? this._innerRadius : this._outerRadius;

    if (radian < 0) {
      radian = Math.PI * 2 + radian;
    }

    radian = Math.round(radian / unit) * unit;

    this.clockPickerHandCx = Math.sin(radian) * radius;
    this.clockPickerHandCy = - Math.cos(radian) * radius;
  }
}

export interface TimeNumberSelector {
  value: string;
  inner: boolean;
  left: string;
  top: string;
}

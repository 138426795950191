import { EventEmitter } from '@angular/core';

import { icons } from '../helper/icon-helper';
import { GridConfiguration } from '../interfaces';


export abstract class ListBase {

  protected editItemEmitter: EventEmitter<any> = new EventEmitter<any>();
  protected selectItemEmitter: EventEmitter<any> = new EventEmitter<any>();
  public icons = icons;

  constructor(
    public gridConfiguration: GridConfiguration
  ) {
    gridConfiguration.doubleClickAction = this.editItemEmitter;
    gridConfiguration.itemSelected = this.selectItemEmitter;
  }
}

import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { errorMessageConstants } from '../../shared/constants';

import { ResponseDataObject } from '../../shared/interfaces';
import { NotificationService } from './notification.service';
import { TranslationService } from './translation.service';

@Injectable({
  providedIn: 'root'
})
export class CommonHelperService {

  constructor(
    private _notificationService: NotificationService,
    private _translationService: TranslationService,
  ) {

  }

  public executeApiRequest<T>(action: Observable<ResponseDataObject<T>>, translationGroup: string, title: string, successMessageKey = null): Observable<T> {
    return action
      .pipe(
        map(
          response => {
            if (response.isFaulty) {
              this._notificationService.showErrorToast(
                this._translationService.translate('errors', response.message),
                title ? this._translationService.translate(translationGroup, title) : undefined,
              );

              return undefined;
            }

            if (successMessageKey) {
              this._notificationService.showSuccessToast(
                this._translationService.translate(translationGroup, successMessageKey),
                title ? this._translationService.translate(translationGroup, title) : undefined,
              );
            }

            return response.data;
          }),
        catchError(error => {
          console.log(error);
          this._notificationService.showErrorToast(
            this._translationService.translate('errors', errorMessageConstants.unexpectedErrorOccurred),
            title ? this._translationService.translate(translationGroup, title) : undefined,
          );

          return of(undefined);
        })
      );
  }
}

import { Component, Input, Output, EventEmitter } from '@angular/core';

import { icons } from '../../../helper/icon-helper';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'checkbox-form-control',
  templateUrl: './checkbox-form-control.component.html',
  styleUrls: ['./checkbox-form-control.component.scss']
})
export class CheckboxFormControlComponent {

  public icons = icons;

  @Input()
  public value = false;

  @Output()
  public valueChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input()
  public label = '';

  @Input()
  public hideLabel = false;

  @Input()
  public isDisabled = false;

  @Input()
  public description = '';

  constructor() { }

  public switchState(): void {
    if (this.isDisabled) {
      return;
    }
    setTimeout(() => {
      this.value = !this.value;
      this.valueChange.emit(this.value);
    }, 10);
  }

}

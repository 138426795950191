import { Injectable } from '@angular/core';
import { Routes } from '@angular/router';
import { Subject } from 'rxjs';
import { MenuItem, NavigationLink } from '../../shared/interfaces';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  private _links: NavigationLink[] = [];
  private _linksChanged = new Subject<NavigationLink[]>();
  private _linkClicked = new Subject<NavigationLink>();

  private _subLinks: NavigationLink[] = [];
  private _subLinksChanged = new Subject<NavigationLink[]>();
  private _subLinkClicked = new Subject<NavigationLink>();

  public linksChanged$ = this._linksChanged.asObservable();
  public linkClicked$ = this._linkClicked.asObservable();

  public subLinksChanged$ = this._subLinksChanged.asObservable();
  public subLinkClicked$ = this._subLinkClicked.asObservable();

  constructor(
    private _authService: AuthService
  ) { }

  public set links(links: NavigationLink[]) {
    this._links = links;
    this._linksChanged.next(links);
  }

  public get links(): NavigationLink[] {
    return this._links;
  }

  public set subLinks(subLinks: NavigationLink[]) {
    this._subLinks = subLinks;
    this._subLinksChanged.next(subLinks);
  }

  public get subLinks(): NavigationLink[] {
    return this._subLinks;
  }

  public click(link: NavigationLink): void {
    this._linkClicked.next(link);
  }

  public setupMainNavigation(routes: Routes, baseUrl: string): void {
    const newLinks = this.setupSubNavigation(routes, baseUrl);
    this.links = newLinks;
  }

  public setupSubNavigation(childRoutes: Routes, baseUrl: string): NavigationLink[] {
    if (!childRoutes) {
      return [];
    }

    const newLinks = [];
    childRoutes.filter(route => !!route.data && !route.data.isHidden && this._authService.hasPermission(route.data.only || [])).forEach(route => {
      let childBaseRoute = baseUrl + route.path;
      if (childBaseRoute[childBaseRoute.length - 1] !== '/') {
        childBaseRoute += '/';
      }

      newLinks.push({
        path: baseUrl + route.path,
        data: {
          title: route.data ? route.data.title : '',
          description: route.data ? route.data.description : '',
          only: route.data ? route.data.only : [],
          icon: route.data ? route.data.icon : null,
          activeIcon: route.data ? route.data.activeIcon : null
        },
        children: this.setupSubNavigation(route.children, childBaseRoute)
      });
    });
    this.subLinks = newLinks;

    return newLinks;
  }

  public filterRoutes(routes: NavigationLink[], menuItemCreator: (route: NavigationLink) => MenuItem): MenuItem[] {
    if (!routes) {
      return [];
    }
    const pairs = routes.map(r => ({ menutItem: menuItemCreator(r), route: r }));
    const groups: Record<string, any[]> = {};

    pairs.forEach(p => {
      const group = p.route.data.group || '';
      (groups[group] = groups[group] || []).push(p);
    });

    const baseList = groups[''].sort(this.sortByTitle);

    for (const key in groups) {
      if (!!key && Object.prototype.hasOwnProperty.call(groups, key)) {
        const elements = groups[key];
        elements.sort(this.sortByTitle);
        const first = elements[0];
        baseList.push(first);
      }
    }

    baseList.sort(this.sortByTitle);
    for (const key in groups) {
      if (!!key && Object.prototype.hasOwnProperty.call(groups, key)) {
        const elements = groups[key];
        const first = elements[0];
        const index = baseList.indexOf(first);
        baseList.splice(index, 1, elements);
      }
    }

    return baseList.map(r => r.menutItem);
  }

  private sortByTitle(a: any, b: any): number {
    return a.menutItem.title > b.menutItem.title ? 1 : -1;
  }
}

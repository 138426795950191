<ng-template #permissionsTemplate>
  <form #roleForm="ngForm">
    <div class="modal-header"> Available Permissions </div>
    <div class="modal-body">
      <div class="container-fluid">
        <div class="row">
          <div class="col-2">
            <button type="button"
                    class="btn btn-primary btn-block"
                    (click)="setNone()">None</button>
          </div>
          <div class="col-2">
            <button type="button"
                    class="btn btn-primary btn-block"
                    (click)="setAll()">All</button>
          </div>
          <div class="col-8">
            <input type="text"
                   name="search"
                   placeholder="Search"
                   [(ngModel)]="search"
                   (ngModelChange)="onSearchChanged($event)"
                   class="form-control">
          </div>
        </div>
        <div class="row">
          <div class="col">
            <hr>
          </div>
        </div>
        <ng-container *ngFor="let item of filteredPermissions">
          <div class="row mt-2">
            <div class="col-12">
              <div class="font-size-larger font-weight-bold my-2">{{ item.text }} <span>({{ item.value.length }} / {{ item.groupId }})</span>
                <checkbox-form-control class="d-inline-block ms-2"
                                       [value]="isActiveGroup(item)"
                                       (valueChange)="onPermissionCategoryChanged($event, item)">
                </checkbox-form-control>
              </div>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-3 my-2"
                 *ngFor="let permission of item.value">
              <checkbox-form-control [label]="permission.text"
                                     [value]="permission.groupId"
                                     (valueChange)="onPermissionChanged($event, permission)">
              </checkbox-form-control>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="modal-footer">
      <button class="btn btn-outline-primary btn-sm"
              (click)="closeModal()">{{'close' | translation:'common'}} </button>
    </div>
  </form>
</ng-template>
<div class="app-wrapper">
  <div class="app-header">
    <div *ngIf="viewData.isSideBarVisible"
         class="app-navbar-toggle pointer"
         [ngClass]="{collapsed: viewData.collapsed}"
         (click)="switchCollapse()">
      <div class="rotateable"
           [class.rotate]="viewData.collapsed">
        <fa-icon [icon]="icons.angleDoubleLeft"
                 [fixedWidth]="true"></fa-icon>
      </div>
    </div>
    <div *ngIf="isGlobalAdministrator"
         class="app-navbar-brand pointer"
         [routerLink]="['/administration']">
      <img *ngIf="!logo"
           src="../assets/timeswift.svg"
           title="{{viewData.tentantName}}" />
    </div>
    <div *ngIf="!isGlobalAdministrator && menuItems && menuItems.length > 0"
         class="app-navbar-brand pointer"
         [routerLink]="['/',menuItems[0].path]">
      <img *ngIf="!logo"
           src="../assets/timeswift.svg"
           title="{{viewData.tentantName}}" />
      <img *ngIf="logo"
           [src]="logo"
           title="{{viewData.tentantName}}" />
    </div>
    <div class="ms-4">&nbsp;</div>
    <ng-container *ngFor="let link of links">
      <a class="app-navbar-link"
         [routerLink]="link.path"
         routerLinkActive="active"
         title="{{ link.data.title | translation: 'navigation' }}"
         *hasRight="link.data.only">
        <span>{{ link.data.title | translation: 'navigation' }}</span>
      </a>
    </ng-container>
    <div *ngIf="viewData.authenticationState > authenticationState.NotAuthenticated"
         class="app-navbar-user ms-auto">
      <div ngbDropdown
           placement="bottom-right">
        <fa-icon class="pointer account"
                 ngbDropdownToggle
                 [icon]="icons.userCircle"
                 size="lg"
                 [fixedWidth]="true"></fa-icon>
        <div ngbDropdownMenu>
          <h6 class="dropdown-header w-100 text-center">{{ viewData.username }}</h6>
          <div class="dropdown-divider"></div>
          <div class="dropdown-header w-100 text-center">{{appSettings.appName}} - {{appSettings.version}}</div>
          <div class="dropdown-divider"></div>
          <button class="dropdown-item"
                  disabled>
            <fa-icon [icon]="icons.settings"
                     [fixedWidth]="true"></fa-icon> {{'profile'|translation:'navigation'}}
          </button>
          <div class="dropdown-divider"
               *ngIf="appSettings.debug"></div>
          <button *ngIf="appSettings.debug && !isGlobalAdministrator"
                  class="dropdown-item pointer"
                  (click)="showPermissionDebug(permissionsTemplate)">
            <fa-icon [icon]="icons.userLock"
                     [fixedWidth]="true"></fa-icon> {{'permissionDebug'|translation:'navigation'}}
          </button>
          <button *ngIf="appSettings.debug"
                  class="dropdown-item pointer"
                  (click)="reAuthenticate()">
            <fa-icon [icon]="icons.refresh"
                     [fixedWidth]="true"></fa-icon> {{'reAuthenticate'|translation:'navigation'}}
          </button>
          <div class="dropdown-divider"></div>
          <button class="dropdown-item pointer"
                  (click)="unauthenticate()">
            <fa-icon [icon]="icons.signOut"
                     [fixedWidth]="true"></fa-icon> {{'signOut'|translation:'navigation'}}
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="app-content">
    <div *ngIf="viewData.isSideBarVisible"
         class="app-sidebar"
         [ngClass]="{collapsed: viewData.collapsed}">
      <div class="list-group list-group-flush">
        <ng-container *ngIf="viewData.authenticationState === authenticationState.Authenticated">
          <ng-container *ngFor="let menuItem of menuItems">
            <a class="list-group-item list-group-item-action"
               [routerLink]="['/',menuItem.path]"
               routerLinkActive="active"
               title="{{ menuItem.title | translation: 'navigation' }}"
               *hasRight="menuItem.permission">
              <fa-icon [icon]="menuItem.icon"
                       [fixedWidth]="true"></fa-icon>
              <span *ngIf="!viewData.collapsed">&nbsp;{{ menuItem.title | translation: 'navigation' }}</span>
            </a>
          </ng-container>
        </ng-container>
        <ng-container *hasRight="['localadministrator.read']">
          <a *ngIf="viewData.authenticationState === authenticationState.Authenticated"
             class="list-group-item list-group-item-action"
             [routerLink]="['/','administration']"
             routerLinkActive="active"
             title="{{ 'administration' | translation: 'navigation' }}">
            <fa-icon [icon]="icons.adminSettings"
                     [fixedWidth]="true"></fa-icon>
            <span *ngIf="!viewData.collapsed">&nbsp;{{ 'administration' | translation: 'navigation' }}</span>
          </a>
        </ng-container>
        <ng-container *hasRight="['globaladministrator.read']">
          <a class="list-group-item list-group-item-action"
             [routerLink]="['/','administration']"
             routerLinkActive="active"
             title="{{ 'administration' | translation: 'navigation' }}">
            <fa-icon [icon]="icons.adminSettings"
                     [fixedWidth]="true"></fa-icon>
            <span *ngIf="!viewData.collapsed">&nbsp;{{ 'administration' | translation: 'navigation' }}</span>
          </a>
        </ng-container>
      </div>
    </div>
    <div class="main w-100 content-wrapper">
      <router-outlet></router-outlet>
      <app-banner></app-banner>
    </div>
  </div>
</div>
<app-notification></app-notification>
<app-picture-viewer-overlay></app-picture-viewer-overlay>
<app-context-menu></app-context-menu>

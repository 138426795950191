import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { AuthService, EditorControlBarService, GridControlBarService } from '../../../core/services';
import { icons } from '../../helper';
import { EditorConfiguration, GridControlBarButton } from '../../interfaces';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'basic-data-button-bar',
  templateUrl: './basic-data-button-bar.component.html',
  styleUrls: ['./basic-data-button-bar.component.scss']
})
export class BasicDataButtonBarComponent implements OnDestroy {

  private _subscriptions: Subscription = new Subscription();
  public icons = icons;
  public showGridButtons = true;
  public showEditorButtons = false;
  public gridButtons: GridControlBarButton[] = [];
  public editorButtons: GridControlBarButton[] = [];
  public editorConfiguration: EditorConfiguration;
  public translationGroup = '';
  public enableEditorButtons = true;
  public enableSaveButton = true;
  public enableDeleteButton = true;
  public firstVisibleGridButtonIndex = 0;

  constructor(
    private _editorControlBarService: EditorControlBarService,
    private _gridControlbarService: GridControlBarService,
    private _authService: AuthService
  ) {
    this.registerEvents();
  }

  public ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
    this._subscriptions = null;
  }

  public deleteItem(): void {
    if (this.enableEditorButtons && this.enableDeleteButton && this.editorConfiguration.showDeleteButton) {
      this._editorControlBarService.delete();
    }
  }

  public saveItem(): void {
    if (this.enableEditorButtons && this.enableSaveButton && this.editorConfiguration.showSaveButton) {
      this._editorControlBarService.save();
    }
  }

  public cancelItem(): void {
    if (this.enableEditorButtons && this.editorConfiguration.showCancelButton) {
      this._editorControlBarService.cancel();
    }
  }

  private registerEvents(): void {
    this._subscriptions.add(this._gridControlbarService.configurationChanged.subscribe(configuration => {
      if (configuration && !configuration.isOfflineMode) {
        setTimeout(() => {
          this.gridButtons = configuration.controlBarButtons;
          this.translationGroup = configuration.translationGroup;
          this.showGridButtons = true;
          this.showEditorButtons = false;
          this.calculateButtonVisibility();
        }, 10);
      }
    }));

    this._subscriptions.add(this._editorControlBarService.configurationChanged.subscribe(configuration => {
      if (configuration) {
        setTimeout(() => {
          this.editorButtons = configuration.controlBarButtons || [];
          this.editorConfiguration = configuration;
          this.translationGroup = configuration.translationGroup;
          this.showEditorButtons = configuration.isVisible;
          this.showGridButtons = !configuration.isVisible;
        }, 10);
      }
    }));

    this._subscriptions.add(this._gridControlbarService.visibilityChanged.subscribe(visible => {
      this.showGridButtons = visible;
    }));

    this._subscriptions.add(this._editorControlBarService.visibilityChanged.subscribe(visible => {
      this.showEditorButtons = visible;
    }));

    this._subscriptions.add(this._editorControlBarService.controlBarStatusChanged.subscribe(enabled => {
      this.enableEditorButtons = enabled;
    }));

    this._subscriptions.add(this._editorControlBarService.saveButtonStatusChanged.subscribe(enabled => {
      this.enableSaveButton = this.editorConfiguration && enabled && this._authService.hasPermission([`${this.editorConfiguration.rightName}.create`, `${this.editorConfiguration.rightName}.edit`]);
    }));

    this._subscriptions.add(this._editorControlBarService.deleteButtonStatusChanged.subscribe(enabled => {
      this.enableDeleteButton = this.editorConfiguration && enabled && this._authService.hasPermission(`${this.editorConfiguration.rightName}.delete`);
    }));

    this._subscriptions.add(this._gridControlbarService.buttonVisibilityChanged.subscribe(_ => {
      this.calculateButtonVisibility();
    }));

  }

  private calculateButtonVisibility(): void {
    this.firstVisibleGridButtonIndex = (this.gridButtons || []).findIndex(b => b.visible);
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { AssistantService, NotificationService, TranslationService } from '../../../core/services';
import { errorMessageConstants } from '../../constants';
import { NotificationButtonKind, NotificationDialogKind } from '../../enums';
import { icons } from '../../helper';
import { AssistantConfiguration, AssistantMenuItem } from '../../interfaces';


@Component({
  selector: 'app-assistant',
  templateUrl: './assistant.component.html',
  styleUrls: ['./assistant.component.scss']
})
export class AssistantComponent implements OnInit, OnDestroy {

  private _initialDataRecordString: string;
  private _initialDataRecord: any;
  private _metaDataRecord: any;
  private _createMode: boolean;
  private _subscriptions: Subscription = new Subscription();
  private _routerEventAlreadyProcessed = false;

  private _returnToStep: AssistantMenuItem;

  public icons = icons;
  public configuration: AssistantConfiguration;
  public enableNavigationButtons: boolean;
  public enableSaveButton: boolean;
  public isLoadingBasicData: boolean;
  public isSaving: boolean;
  public labelProgress: string;

  constructor(
    private _assistantService: AssistantService,
    private _translationService: TranslationService,
    private _routerService: Router,
    private _activatedRoute: ActivatedRoute,
    private _notificationService: NotificationService
  ) {

    this._createMode = true;
    this.enableNavigationButtons = false;
    this.calculateProgress();
  }

  public ngOnInit(): void {
    this._routerService.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.setActiveMenuItem(event.url);
    });

    this._subscriptions.add(this._assistantService.navigateToFirstStepRequested.subscribe(backToSource => {
      if (backToSource) {
        this._returnToStep = this.getCurrentNavigationStep();
      }
      this.processNavigationRequest(this.configuration.menuItems[0]);
    }));

    this._subscriptions.add(this._assistantService.validationStateChange.subscribe(isValid => {
      this.enableNavigationButtons = isValid;
      this.enableSaveButton = isValid && this.configuration && (!this.configuration.enableSaveOnLastStep || this.isLastStepActive());
    }));

    this._subscriptions.add(this._assistantService.configurationChanged.subscribe(configration => {
      if (!this.configuration) {
        setTimeout(() => {
          this.configuration = configration;
          this.setActiveMenuItem(this._routerService.url);
          if (this._createMode && !this.configuration.menuItems[0].isActive) {
            this.processNavigationRequest(this.configuration.menuItems[0]);
          }
        }, 10);
      }
    }));

    this._subscriptions.add(this._assistantService.dataRecordInformationChanged.subscribe(dataRecordInformation => {
      setTimeout(() => {
        this.configuration.dataRecordInformation = dataRecordInformation;
      }, 10);
    }));

    this._subscriptions.add(this._assistantService.saveCompleted.subscribe(result => {
      this.isSaving = false;
      if (this.configuration.enableSaveOnLastStep && result) {
        this._routerEventAlreadyProcessed = true;
        this.redirectToCancelRoute();
      }
    }));

    this._subscriptions.add(this._assistantService.reloadBasicDataCompleted.subscribe(_ => {
      this.isLoadingBasicData = false;
      this._notificationService.showInfoToast(this._translationService.translate('Common', 'reloadBasicDataCompleted'));
    }));

    this._subscriptions.add(this._assistantService.metaDataRecordToAssistantSent.subscribe(metaDataRecord => {
      this._metaDataRecord = metaDataRecord;
      if (this._returnToStep) {
        const tmp = this._returnToStep;
        this._returnToStep = null;
        this.processNavigationRequest(tmp);
      }
    }));

    this._subscriptions.add(this._assistantService.metaDataRecordRequested.subscribe(_ => {
      this._assistantService.sendMetaDataRecordToAssistantStep(this._metaDataRecord);
    }));

    this._subscriptions.add(this._assistantService.initialDataRecordSet.subscribe(dataRecord => {
      this._initialDataRecordString = JSON.stringify(dataRecord);
      this._initialDataRecord = dataRecord;
    }));

    this._subscriptions.add(this._assistantService.changedDataCheckTriggered.subscribe(_ => {
      this._assistantService.hasChangedData(this.hasChanges());
    }));
  }

  public ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
    this._metaDataRecord = null;
  }

  public navigateNext(): void {
    const currentItem = this.getCurrentNavigationStep();
    const currentItemIndex = this.configuration.menuItems.indexOf(currentItem);

    if (currentItemIndex < (this.configuration.menuItems.length - 1) && this.enableNavigationButtons && !this.isSaving) {
      this.processNavigationRequest(this.configuration.menuItems[currentItemIndex + 1]);
    }
  }

  public navigatePrevious(): void {
    const currentItem = this.getCurrentNavigationStep();
    const currentItemIndex = this.configuration.menuItems.indexOf(currentItem);

    if (currentItemIndex > 0 && this.enableNavigationButtons && !this.isSaving) {
      this.processNavigationRequest(this.configuration.menuItems[currentItemIndex - 1]);
    }
  }

  public navigate(item: AssistantMenuItem): void {
    if (item.isClickable && this.enableNavigationButtons && !this.isSaving) {
      this.processNavigationRequest(item);
    }
  }

  public save(): void {
    if (this.isSaving || !this.enableSaveButton) {
      return;
    }

    if (this.hasChanges()) {
      this.isSaving = true;
      this._assistantService.requestSave();
    } else {
      this._notificationService.showSuccessToast(
        this._translationService.translate('errors', errorMessageConstants.noChangesToSave)
      );
    }
  }

  public isFirstStepActive(): boolean {
    return !this.configuration || this.configuration.menuItems[0].isActive;
  }

  public isLastStepActive(): boolean {
    return !this.configuration || this.configuration.menuItems[this.configuration.menuItems.length - 1].isActive;
  }

  public cancelAssistant(): void {
    if (!this.isSaving) {
      this.redirectToCancelRoute();
    }
  }

  public shouldAskCancelAssistant(): boolean {
    return !this._routerEventAlreadyProcessed;
  }

  public askCancelAssistent(): Observable<boolean> {
    if (this.isSaving) {
      return of(false);
    }

    this._assistantService.overvoteCancelRequestDecision(true);

    if (!this.hasChanges()) {
      return of(true);
    }

    const subject$: Subject<boolean> = new Subject<boolean>();
    this._notificationService.showDialog({
      title: this._translationService.translate(this.configuration.translationGroup, this._createMode ? 'cancelAssistantCreateTitle' : 'cancelAssistantEditTitle'),
      message: this._translationService.translate(this.configuration.translationGroup, 'cancelAssistant').replace('\n', '<br>'),
      kind: NotificationDialogKind.Default,
      buttons: [{
        caption: this._translationService.translate('common', 'yes'),
        kind: NotificationButtonKind.Primary,
        icon: icons.check,
        action: () => {
          this._routerEventAlreadyProcessed = true;
          subject$.next(true);
        }
      }, {
        caption: this._translationService.translate('common', 'no'),
        kind: NotificationButtonKind.Secondary,
        icon: icons.cancel,
        action: () => {
          this._assistantService.overvoteCancelRequestDecision(false);
          subject$.next(false);
        }
      }]
    });

    return subject$.asObservable();
  }

  public reloadBasicData(): void {
    this.isLoadingBasicData = true;
    this._assistantService.requestReloadBasicData();
    this._notificationService.showInfoToast(this._translationService.translate('Common', 'reloadBasicDataRequested'));
  }

  private getCurrentNavigationStep(): AssistantMenuItem {
    return this.configuration.menuItems.find(i => i.isActive);
  }

  private redirectToCancelRoute(): void {
    this._routerService.navigate([this.configuration.cancelActionRoute], { relativeTo: this._activatedRoute });
  }

  private calculateProgress(): void {
    const maxStepCount: number = this.configuration ? this.configuration.menuItems.length : 1;
    const currentStepNumber: number = (this.configuration ? this.configuration.menuItems.indexOf(this.configuration.menuItems.find(item => item.isActive)) : 0) + 1;
    const progress = Math.round(currentStepNumber / maxStepCount * 100);

    this.labelProgress = `${this._translationService.translate('assistant', 'progress')}: ${progress}% ${this._translationService.translate('assistant', 'completed')}`;
  }

  private processNavigationRequest(item: AssistantMenuItem): void {
    if (item.navigationRoute && !this.isSaving) {
      if (this.configuration.dataRecordId) {
        this._routerService.navigate(['edit/' + item.navigationRoute, this.configuration.dataRecordId], { relativeTo: this._activatedRoute });
      } else {
        this._routerService.navigate(['create/' + item.navigationRoute], { relativeTo: this._activatedRoute });
      }
    }
  }

  private setActiveMenuItem(url: string): void {
    if (!this.configuration) {
      return;
    }

    const parts = url.split('/');
    let navigationPart = '';
    if (url.indexOf('create') === -1) {
      this._createMode = false;
      navigationPart = parts[parts.length - 2];
    } else {
      this._createMode = true;
      navigationPart = parts[parts.length - 1];
    }

    this.configuration.menuItems.forEach(item => {
      item.isActive = item.navigationRoute === navigationPart;
    });

    this.calculateProgress();
  }

  private hasChanges(): boolean {
    return this._initialDataRecordString !== JSON.stringify(this._initialDataRecord);
  }
}

export enum FieldDataType {
  string = 'string',
  dateTime = 'dateTime',
  date = 'date',
  time = 'time',
  multiline = 'multiline',
  number = 'number',
  select = 'select',
  boolean = 'boolean',
  tag = 'tag'
}

import { Injectable } from '@angular/core';
import { NgbDateAdapter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class NgbDateStringAdapter extends NgbDateAdapter<string> {

  public fromModel(date: string): NgbDateStruct {
    if (date) {
      const tmpDate = new Date(date);

      return { year: tmpDate.getFullYear(), month: tmpDate.getMonth() + 1, day: tmpDate.getDate() };
    }

    return null;
  }

  public toModel(date: NgbDateStruct): string {
    return date ? new Date(date.year, date.month - 1, date.day).toISOString() : null;
  }
}

import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[characterValidator][ngModel],[characterValidator][formControl]',
  providers: [{ provide: NG_VALIDATORS, useExisting: CharacterValidatorDirective, multi: true }]
})
export class CharacterValidatorDirective implements Validator {

  private readonly _lowerCaseRegEx = /^[a-z]+$/;
  private readonly _upperCaseRegEx = /^[A-Z]+$/;
  private readonly _mixedCaseRegEx = /^[a-zA-Z]+$/;

  // tslint:disable-next-line:no-input-rename
  @Input('characterValidator')
  public letterCase: 'UpperCase' | 'LowerCase' | 'MixedCase' | 'None' = 'MixedCase';

  constructor() { }

  public validate(control: AbstractControl): { [key: string]: any } | null {
    let regExp: RegExp;
    let errorResult: any = null;

    switch (this.letterCase) {
      case 'None':
        return null;
      case 'LowerCase':
        regExp = this._lowerCaseRegEx;
        errorResult = { 'characterLowerCase': { value: control.value } };
        break;
      case 'UpperCase':
        regExp = this._upperCaseRegEx;
        errorResult = { 'characterUpperCase': { value: control.value } };
        break;
      case 'MixedCase':
        regExp = this._mixedCaseRegEx;
        errorResult = { 'characterMixedCase': { value: control.value } };
    }

    if (regExp && regExp.test(control.value)) {
      return null;
    }

    return errorResult;
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ColorEvent } from 'ngx-color';

@Component({
  selector: 'color-picker-form-control',
  templateUrl: './color-picker-form-control.component.html',
  styleUrls: ['./color-picker-form-control.component.scss']
})
export class ColorPickerFormControlComponent {

  @Input()
  public label: string;

  @Input()
  public value: string;

  @Output()
  public valueChange = new EventEmitter<string>();

  @Input()
  public isDisabled = false;

  @Input()
  public name: string;

  @Input()
  public hideLabel = false;

  constructor() {
  }

  public onValueChange(colorEvent: ColorEvent): void {
    console.log(colorEvent);
    this.value = colorEvent.color.hex;
    this.valueChange.emit(this.value);
  }
}

<label *ngIf="!hideLabel">{{ label }} <span *ngIf="imageData.data"
        (click)="removeImage()"
        class="trash"
        title="{{''|translation:'common'}}">
    <fa-icon [icon]="icons.trash"
             [fixedWidth]="true"></fa-icon>
  </span>
</label>
<div class="custom-dropzone {{ sizeClass }} {{ backgroundClass }}"
     ngx-dropzone
     (change)="onSelect($event)"
     [multiple]="false"
     [accept]="getAccept()"
     [maxFileSize]="configuration.maxFileSizeInBytes"
     [disabled]="isDisabled"
     [ngStyle]="getBackground()">
  <ngx-dropzone-label class="static-label"
                      *ngIf="showUploadText">
    <div class="static-text"> {{ 'UploadDocument'|translation:'common' }} </div>
  </ngx-dropzone-label>
  <ngx-dropzone-label class="no-image-label"
                      *ngIf="!isImage() && file">
    <div class="no-image-text"> {{ 'UploadDocumentChosen'|translation:'common':file.name }} </div>
  </ngx-dropzone-label>
  <!-- <ngx-dropzone-label class="hover-label"
                      *ngIf="label">
    <div class="info-text"> {{ label }} </div>
  </ngx-dropzone-label> -->
</div>

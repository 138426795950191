<div class="tree-view">
  <ng-container *ngTemplateOutlet="branch; context: { entries: configuration.entries, parent: null, noChilds: !configuration.allowAddRootEntry, level: 1, isCollapsed: false }"></ng-container>
</div>
<ng-template #branch
             let-entries="entries"
             let-parent="parent"
             let-noChilds="noChilds"
             let-level="level"
             let-isCollapsed="isCollapsed">
  <ng-container *ngIf="entries">
    <ul class="tree"
        [ngClass]="{'root': !parent && noChilds, 'collapsed': isCollapsed }"
        cdkDropList
        (cdkDropListDropped)="drop($event, entries)">
      <li *ngFor="let entry of entries"
          [cdkDragDisabled]="entry.isDragDropDisabled || (configuration.allowEdit && !parent)"
          [class.root]="!parent && noChilds"
          cdkDrag>
        <div *cdkDragPlaceholder
             class="mb-2 p-2 drag-placeholder"> {{ entry.name || getLabelNewEntry(level)}} </div>
        <div *cdkDragPreview
             class="drag-preview">{{ entry.name || getLabelNewEntry(level)}}</div>
        <div class="tree-branch mb-2 p-2"
             [ngClass]="{'active': entry.isSelected, 'error': !entry.isValid, 'disabled': entry.isDisabled }"
             (click)="selectEntry(entry)">
          <div class="collapse-wrapper rotateable"
               [ngClass]="{'rotated': entry.isCollapsed}">
            <fa-icon *ngIf="!entry.isCollapseDisabled && (parent && !entry.noChilds)"
                     [icon]="icons.up"
                     (click)="entry.isCollapsed = !entry.isCollapsed">
            </fa-icon>
          </div>
          <div *ngIf="!entry.isDragDropDisabled && configuration.allowEdit && parent"
               class="drag-handle"
               cdkDragHandle>
          </div> {{ entry.name || getLabelNewEntry(level)}}
        </div>
        <ng-container *ngTemplateOutlet="branch; context: { entries: entry.entries, parent: entry, noChilds: entry.noChilds, level: (level + 1), isCollapsed: entry.isCollapsed }"></ng-container>
      </li>
      <li *ngIf="!noChilds">
        <button class="btn btn-outline-secondary btn-sm mb-2 pe-2"
                type="button"
                (click)="addEntry(parent)"> {{ getLabelAddEntry(level) }} </button>
      </li>
    </ul>
  </ng-container>
</ng-template>

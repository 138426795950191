<ng-container *ngIf="!configuration.scrollGridBody">
  <div #gridContainer
       class="grid-container"
       infiniteScroll
       [infiniteScrollDistance]="2"
       [infiniteScrollThrottle]="50"
       [infiniteScrollContainer]="configuration.scrollableContainer || '.content-wrapper'"
       [fromRoot]="true"
       (scrolled)="loadFurtherData()">
    <table class="grid table table-striped table-bordered table-hover responsive-table-custom">
      <thead>
        <ng-container *ngTemplateOutlet="gridHeaderTemplate;"></ng-container>
      </thead>
      <tbody #gridBody>
        <ng-container *ngTemplateOutlet="gridBodyTemplate;"></ng-container>
      </tbody>
      <tfoot *ngIf="configuration.showFooterRow && footerRow">
        <ng-container *ngTemplateOutlet="gridFooterTemplate;"></ng-container>
      </tfoot>
    </table>
  </div>
</ng-container>
<ng-container *ngIf="configuration.scrollGridBody">
  <div #gridContainer
       class="grid-container grid-scroll-body">
    <table class="grid table table-striped table-bordered table-hover responsive-table-custom">
      <thead>
        <ng-container *ngTemplateOutlet="gridHeaderTemplate;"></ng-container>
      </thead>
      <tbody class="grid-body"
             infiniteScroll
             [infiniteScrollDistance]="2"
             [infiniteScrollThrottle]="50"
             [infiniteScrollContainer]="configuration.scrollableContainer || '.grid-body'"
             [fromRoot]="true"
             (scrolled)="loadFurtherData()">
        <ng-container *ngTemplateOutlet="gridBodyTemplate;"></ng-container>
      </tbody>
      <tfoot *ngIf="configuration.showFooterRow && footerRow">
        <ng-container *ngTemplateOutlet="gridFooterTemplate;"></ng-container>
      </tfoot>
    </table>
  </div>
</ng-container>
<ng-template #gridHeaderTemplate>
  <tr class="header">
    <ng-container *ngFor="let column of configuration.columns;index as i">
      <th class="column-header grid-col-{{i}}"
          *ngIf="column.visible"
          (click)="changeSorting(column)"> {{column.displayName}} <ng-container *ngIf="column.sortable">
          <fa-icon class="sort"
                   *ngIf="column.sort === sortDirection.none"
                   [icon]="icons.sortNone"></fa-icon>
          <fa-icon class="sort"
                   *ngIf="column.sort === sortDirection.asc"
                   [icon]="icons.sortAsc"></fa-icon>
          <fa-icon class="sort"
                   *ngIf="column.sort === sortDirection.desc"
                   [icon]="icons.sortDesc"></fa-icon>
        </ng-container>
      </th>
    </ng-container>
    <th class="col-scroll-bar"
        *ngIf="configuration.scrollGridBody"></th>
  </tr>
  <tr class="search"
      *ngIf="configuration.isVisibleColumnFilter">
    <ng-container *ngFor="let column of configuration.columns">
      <th class="column-filter"
          *ngIf="column.visible"
          [style.max-width]="column.colWidth !== undefined ? column.colWidth + 'px' : 'inherit'">
        <div *ngIf="searchable(column,[columnType.text,columnType.tag,columnType.list])">
          <div class="responsive-column-name">{{column.displayName}}</div>
          <ng-container *ngIf="column.searchAsDropDown">
            <select [(ngModel)]="column.search.searchTerm"
                    (change)="filterDropDown()">
              <option *ngFor="let dataItem of column.list.data"
                      value="{{dataItem[column.list.key]}}">{{dataItem[column.list.value]}}</option>
            </select>
          </ng-container>
          <ng-container *ngIf="!column.searchAsDropDown">
            <input [placeholder]="labelSearch"
                   [(ngModel)]="column.search.searchTerm"
                   (keydown)="filterInput($event)">
          </ng-container>
        </div>
        <div *ngIf="searchable(column,[columnType.action])">
          <div class="responsive-column-name">{{column.displayName}}</div>
          <input [placeholder]="labelSearch"
                 [(ngModel)]="column.search.searchTerm"
                 (keydown)="filterInput($event)">
        </div>
        <div *ngIf="searchable(column,[columnType.boolean])">
          <div class="responsive-column-name">{{column.displayName}}</div>
          <select [(ngModel)]="column.search.searchTerm"
                  (change)="filterDropDown()">
            <option value="">{{labelAll}}</option>
            <option value="1">{{formatBooleanValue(column, true)}}</option>
            <option value="0">{{formatBooleanValue(column, false)}}</option>
          </select>
        </div>
        <div class="no-search"
             *ngIf="searchable(column,[columnType.date])"></div>
        <div class="no-search"
             *ngIf="searchable(column,[columnType.datetime])"></div>
        <div class="no-search"
             *ngIf="searchable(column,[columnType.time])"></div>
        <div class="no-search"
             *ngIf="searchable(column,[columnType.number])"></div>
        <div class="no-search"
             *ngIf="!column.searchable"></div>
      </th>
    </ng-container>
    <th class="col-scroll-bar"
        *ngIf="configuration.scrollGridBody"></th>
  </tr>
</ng-template>
<ng-template #gridBodyTemplate>
  <tr *ngIf="dataStatus === gridDataStatus.hasNoItems">
    <td [attr.colspan]="getVisibleColumnCount()"
        class="text-center p-3">
      <span>{{labelNoItems}}</span>
    </td>
  </tr>
  <tr *ngIf="dataStatus === gridDataStatus.errorOccurred">
    <td [attr.colspan]="getVisibleColumnCount()"
        class="text-center p-3">
      <span class="text-danger">{{labelErrorOccurred}}</span>
    </td>
  </tr>
  <tr *ngFor="let item of data; index as i"
      [class.selected]="selectedItem === item"
      (click)="selectItem(item)"
      (dblclick)="openItem()"
      class="grid-row-{{i}}-index">
    <ng-container *ngFor="let column of configuration.columns">
      <td *ngIf="column.visible"
          [ngClass]="{ 'text-right': column.type === columnType.number }"
          [style.max-width]="column.colWidth !== undefined ? column.colWidth + 'px' : 'inherit'">
        <div class="responsive-column-name"><strong>{{column.displayName}}</strong></div>
        <div *ngIf="column.type !== columnType.action">
          <span [innerHTML]='item[column.gridName]'></span>
        </div>
        <div *ngIf="column.type === columnType.action">
          <button type="button"
                  class="btn btn-link btn-grid-link"
                  (click)="executeAction($event, item, column)">{{item[column.gridName]}}</button>
        </div>
      </td>
    </ng-container>
  </tr>
  <tr *ngIf="dataStatus === gridDataStatus.isLoading">
    <td [attr.colspan]="getVisibleColumnCount()"
        class="text-center p-3">
      <fa-icon [icon]="icons.spinner"
               size="2x"
               animation="spin"
               [pulse]="true"></fa-icon>&nbsp;&nbsp; <span style="vertical-align: super;">{{labelLoading}}</span>
    </td>
  </tr>
</ng-template>
<ng-template #gridFooterTemplate>
  <tr class="footer">
    <ng-container *ngFor="let column of configuration.columns">
      <th class="column-footer"
          [class.responsive-hidden]="!column.footerRowAction || column.footerRowAction === footerRowAction.None"
          *ngIf="column.visible"
          [ngClass]="{ 'text-right': column.type === columnType.number || column.footerRowAction === footerRowAction.Count }"
          [style.max-width]="column.colWidth !== undefined ? (column.colWidth) + 'px' : 'inherit'">
        <div class="responsive-column-name"><strong>{{column.displayNameFooter}}</strong></div>
        <div *ngIf="footerRow[column.gridName]">
          <span [innerHTML]='footerRow[column.gridName]'></span>
        </div>
      </th>
    </ng-container>
    <th class="col-scroll-bar"
        *ngIf="configuration.scrollGridBody"></th>
  </tr>
</ng-template>
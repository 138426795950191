import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MobilePhoneContract } from '../../../../shared/interfaces';
import { AppSettings } from '../../../../shared/models';
import { AbstractBasicDataApiService } from '../../abstract-basic-data-api.service';

@Injectable({
  providedIn: 'root'
})
export class MobilePhoneContractApiService extends AbstractBasicDataApiService<MobilePhoneContract> {

  constructor(
    protected http: HttpClient
  ) {
    super(http, AppSettings.endpoints.basicinformation.employees.mobilephonecontract);
  }

  public list(): Observable<MobilePhoneContract[]> {
    return this.http.get<MobilePhoneContract[]>(this.baseUri);
  }
}

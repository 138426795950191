export enum RightCategory {
  Administrator = 0,
  ApplicationParts = 1,
  BasicInformationCommon = 2,
  BasicInformationCompanies = 3,
  BasicInformationEmployees = 4,
  BasicInformationVehicles = 5,
  Applications = 7,
  BasicInformationOnBoarding = 8,
  WorkOrders = 9
}

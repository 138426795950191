import { Injectable } from '@angular/core';
import { NgbDateAdapter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

@Injectable()
export class StringDateAdapter extends NgbDateAdapter<string> {

  constructor() {
    super();
  }

  public fromModel(value: string): NgbDateStruct {
    if (!value) {
      return null;
    }

    const date = moment(value);

    return {
      year: date.year(),
      month: date.month() + 1,
      day: date.date()
    };
  }

  public toModel(date: NgbDateStruct): string {
    if (!date) {
      return '';
    }

    return moment({ year: date.year, month: date.month - 1, date: date.day }).toISOString();
  }

}

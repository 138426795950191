import { NG_VALIDATORS, Validator, AbstractControl, ValidationErrors } from '@angular/forms';
import { Directive, Input } from '@angular/core';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[minValue]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: MinValueValidatorDirective, multi: true }
  ]
})
export class MinValueValidatorDirective implements Validator {

  @Input()
  public minValue: number;

  public validate(c: AbstractControl): ValidationErrors | null {
    if ((!c.value && c.value !== 0) || (!this.minValue && this.minValue !== 0)) {
      return null;
    }

    let value = c.value as number;
    const stringValue = c.value.toString();
    if (stringValue && stringValue.indexOf(',') > 0) {
      value = +stringValue.split(',').join('.');
    }

    if ((!this.minValue && this.minValue !== 0) || (!value && value !== 0)) {
      return null;
    }

    if (value >= this.minValue) {
      return null;
    } else {
      return { minValue: true };
    }
  }
}

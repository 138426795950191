import { Component, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { GridControlBarService } from '../../../core/services';
import { icons } from '../../helper';
import { GridConfiguration, GridSearchParameter } from '../../interfaces';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'grid-control-bar',
  templateUrl: './grid-control-bar.component.html',
  styleUrls: ['./grid-control-bar.component.scss']
})
export class GridControlBarComponent implements OnDestroy {

  private _subscriptions: Subscription = new Subscription();
  private _gridConfiguration: GridConfiguration;

  public icons = icons;
  public isRefreshing = false;
  public isResetting = false;
  public showColumnFilterbar = false;
  public filter = '';
  public enableControlBar = true;

  @Input()
  public showSearch = true;

  constructor(
    private _gridControlbarService: GridControlBarService
  ) {
    this.registerEvents();
  }

  public ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
    this._subscriptions = null;
  }

  public toggleFilter(): void {
    this.showColumnFilterbar = !this.showColumnFilterbar;
    this._gridControlbarService.changeFilterBarVisibility(this.showColumnFilterbar);

    if (!this.showColumnFilterbar) {
      this.clearColumnFilter();
      this.updateGridData();
    }
  }

  public refresh(): void {
    this.isRefreshing = true;
    const parameter: GridSearchParameter = {
      resetGridStatus: true,
      isEnabledPagination: this._gridConfiguration.isEnabledPagination,
      take: this._gridConfiguration.paginationItemCount,
      searchTerm: this.filter
    };

    this._gridControlbarService.requestDataUpdate(parameter);
  }

  public reset(): void {
    this.isResetting = true;
    this.changeSearchTerm(true);
  }

  public search(event: any = null): void {
    if (this.enableControlBar && (!event || event.key === 'Enter')) {
      this.isRefreshing = true;
      this.changeSearchTerm(false);
    }
  }

  public clearSearch(): void {
    this.filter = '';
    this.updateGridData();
  }

  private updateGridData(): void {
    const parameter: GridSearchParameter = {
      resetGridStatus: true,
      isEnabledPagination: this._gridConfiguration.isEnabledPagination,
      take: this._gridConfiguration.paginationItemCount,
      searchTerm: this.filter
    };

    this._gridControlbarService.requestDataUpdate(parameter);
  }

  private changeSearchTerm(resetGridStatus: boolean): void {
    if (resetGridStatus) {
      this.filter = '';
      this.clearColumnFilter();
    }

    this.updateGridData();
  }

  private clearColumnFilter(): void {
    this._gridConfiguration.columns.filter(c => c.search).forEach(column => {
      column.search.searchTerm = '';
      column.search.searchTermAdditional = '';
    });
  }

  private registerEvents(): void {
    this._subscriptions.add(this._gridControlbarService.configurationChanged.subscribe(configuration => {
      this._gridConfiguration = configuration;

      if (configuration && configuration.loadDataImmediately) {
        this.updateGridData();
      }
    }));

    this._subscriptions.add(this._gridControlbarService.dataUpdateFinished.subscribe(_ => {
      this.isRefreshing = false;
      this.isResetting = false;
    }));

    this._subscriptions.add(this._gridControlbarService.visibilityChanged.subscribe(visible => {
      this.enableControlBar = visible;
    }));
  }
}

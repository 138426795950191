import { Injectable } from '@angular/core';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

import { DateTimeLogicLayer } from '../../core/business-logic';
import { FieldDataType } from '../enums';
import { LocalStorageService } from '../services';

@Injectable()
export class LocaleFormatter extends NgbDateParserFormatter {

  constructor(
    private _localStorageService: LocalStorageService,
    private _dateTimeLogicLayer: DateTimeLogicLayer
  ) {
    super();
  }

  public parse(value: string): NgbDateStruct {
    if (value) {
      const date = moment(value);

      return { year: date.year(), month: date.month() + 1, day: date.date() };
    }

    return null;
  }

  public format(date: NgbDateStruct): string {
    if (date) {
      const userCulture = this._localStorageService.userProfile.culture;

      return moment({ year: date.year, month: date.month - 1, day: date.day }).format(this._dateTimeLogicLayer.getDateTimeFormat(FieldDataType.date, userCulture));
    }

    return '';
  }
}

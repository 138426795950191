import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ControlContainer, NgForm, NgModel } from '@angular/forms';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

import { icons } from '../../../helper/icon-helper';
import { Subject, Subscription, timer } from 'rxjs';
import { debounce, distinctUntilChanged } from 'rxjs/operators';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'date-form-control',
  templateUrl: './date-form-control.component.html',
  styleUrls: ['./date-form-control.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class DateFormControlComponent implements OnInit, OnDestroy {

  private _valueSubject = new Subject<string>();
  private _value$ = this._valueSubject.asObservable();
  private _valueSubscription = new Subscription();

  private _formattedData: string;
  private _minDate: string;
  private _maxDate: string;

  public icons = icons;

  @Input()
  public label: string;

  @Input()
  public set value(value: string) {
    if (value) {
      this._formattedData = moment(value).format('YYYY-MM-DD');
    } else {
      this._formattedData = null;
    }
  }

  public get value(): string {
    return this._formattedData;
  }

  @Output()
  public valueChange = new EventEmitter<string>();

  @Input()
  public isRequired = false;

  @Input()
  public prependIcon: IconDefinition;

  @Input()
  public appendIcon: IconDefinition;

  @Input()
  public isDisabled = false;
  @Input()
  public isReadonly = false;

  @Input()
  public placeholder = '';

  @Input()
  public set minDate(minDate: string) {
    this._minDate = minDate;
    setTimeout(() => {
      if (this.ngModelControl) {
        this.ngModelControl.control.updateValueAndValidity();
      }
    }, 100);
  }
  public get minDate(): string {
    return this._minDate;
  }

  @Input()
  public set maxDate(maxDate: string) {
    this._maxDate = maxDate;
    setTimeout(() => {
      if (this.ngModelControl) {
        this.ngModelControl.control.updateValueAndValidity();
      }
    }, 100);
  }

  public get maxDate(): string {
    return this._maxDate;
  }

  @Input()
  public name: string;

  @Input()
  public hideLabel = false;

  @Input()
  public hideErrors = false;

  @Input()
  public markDisabled: (date: NgbDate, current: { month: number }) => boolean;

  @Input()
  public errorMessages: any;

  @Input()
  public useEndOfDay = false;

  @Input()
  public delayInMs = 500;

  @ViewChild('dateValue')
  public ngModelControl: NgModel;

  constructor() { }

  public ngOnInit(): void {
    this._valueSubscription.add(this._value$.pipe(
      distinctUntilChanged(),
      debounce(() => timer(this.delayInMs))
    ).subscribe({
      next: value => {
        if (!value) {
          this.valueChange.emit(value);
        } else if (this.useEndOfDay) {
          this.valueChange.emit(moment(value).endOf('day').add(-1, 's').toISOString());
        } else {
          this.valueChange.emit(moment(value).toISOString());
        }
      }
    }));
  }

  public ngOnDestroy(): void {
    this._valueSubscription.unsubscribe();
  }

  public onValueChange(data: string): void {
    this.value = data;

    this._valueSubject.next(data);
  }
}

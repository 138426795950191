<ng-template #dialogTemplate>
  <div class="modal-header">
    <h5 class="modal-title">{{ dialog.title }}</h5>
  </div>
  <div class="modal-body">
    <p [innerHTML]="dialog.safeHtml"></p>
  </div>
  <div class="modal-footer">
    <button type="button"
            class="btn {{ item.kind }}"
            *ngFor="let item of dialog.buttons"
            (click)="buttonClick(item)">
      <fa-icon *ngIf="item.icon"
               [icon]="item.icon"
               [fixedWidth]="true"></fa-icon>
      {{ item.caption }}
    </button>
  </div>
</ng-template>

<div class="toast-wrapper"
     [@fadeInOut]="toasts.length">
  <ng-container *ngFor="let toast of toasts.reverse()">
    <ngb-alert class="alert"
               [dismissible]="false"
               [type]="toast.kind">
      <fa-icon *ngIf="toast.icon"
               class="alert-icon"
               [icon]="toast.icon"
               [size]="'2x'"
               [fixedWidth]="true"></fa-icon>
      <div class="alert-content">
        <h4 *ngIf="toast.title">{{ toast.title }}</h4>
        <span [innerHTML]="toast.safeHtml"></span>
      </div>
    </ngb-alert>
  </ng-container>
</div>

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private _authService: AuthService,
    private _router: Router
  ) { }

  public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const url = state.url;
    const data = next.data;

    return this.checkRights(url, data);
  }

  private checkRights(url: string, data: any): boolean {
    if (!this._authService.isAuthenticated() || this._authService.isPasswordExpired()) {
      this._authService.redirectUrl = url;
      this._router.navigate(['/login']);

      return false;
    }

    const routeData = data || {};

    if (this._authService.hasPermission(routeData.only)) {
      return true;
    } else {
      this._router.navigate(['/403']);

      return false;
    }
  }
}

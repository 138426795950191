import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApplicationUser, ApplicationUserItem, ChangePasswordRequest, DropDownItem, GridSearchParameter, ResponseDataObject, SearchApiService } from '../../../shared/interfaces';
import { AppSettings } from '../../../shared/models';

@Injectable({
  providedIn: 'root'
})
export class UserApiService implements SearchApiService<ApplicationUser> {

  constructor(
    private _http: HttpClient
  ) { }

  public search(gridSearchParameter: GridSearchParameter): Observable<ResponseDataObject<ApplicationUser[]>> {
    return this._http.post<ResponseDataObject<ApplicationUser[]>>(AppSettings.endpoints.multitenancy.user + '/search', gridSearchParameter);
  }

  public searchSum(parameter: GridSearchParameter): Observable<ResponseDataObject<any>> {
    return this._http.post<ResponseDataObject<any>>(AppSettings.endpoints.multitenancy.user + '/search/sum', parameter);
  }

  public getUserDropDownList(): Observable<ResponseDataObject<DropDownItem[]>> {
    return this._http.get<ResponseDataObject<DropDownItem[]>>(AppSettings.endpoints.multitenancy.user + '/dropdown');
  }

  public getUserById(id: number): Observable<ResponseDataObject<ApplicationUser>> {
    return this._http.get<ResponseDataObject<ApplicationUser>>(AppSettings.endpoints.multitenancy.user + '/' + id);
  }

  public getIsInUse(id: number): Observable<ResponseDataObject<boolean>> {
    return this._http.get<ResponseDataObject<boolean>>(AppSettings.endpoints.multitenancy.user + '/isUsed/' + id);
  }

  public addUser(user: ApplicationUser): Observable<ResponseDataObject<ApplicationUser>> {
    return this._http.post<ResponseDataObject<ApplicationUser>>(AppSettings.endpoints.multitenancy.user, user);
  }

  public updateUser(user: ApplicationUser): Observable<ResponseDataObject<ApplicationUser>> {
    return this._http.put<ResponseDataObject<ApplicationUser>>(AppSettings.endpoints.multitenancy.user, user);
  }

  public deleteUser(id: number): Observable<ResponseDataObject<boolean>> {
    return this._http.delete<ResponseDataObject<boolean>>(AppSettings.endpoints.multitenancy.user + '/' + id);
  }

  public resetPassword(id: number): Observable<ResponseDataObject<boolean>> {
    return this._http.post<ResponseDataObject<boolean>>(AppSettings.endpoints.multitenancy.user + '/password/reset/', { userId: id });
  }

  public changePassword(changePasswordRequst: ChangePasswordRequest): Observable<ResponseDataObject<boolean>> {
    return this._http.post<ResponseDataObject<boolean>>(AppSettings.endpoints.multitenancy.user + '/password/change/', changePasswordRequst);
  }

  public getUsers(): Observable<ResponseDataObject<ApplicationUserItem[]>> {
    return this._http.get<ResponseDataObject<ApplicationUserItem[]>>(AppSettings.endpoints.multitenancy.user + '/users');
  }
}

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { TranslationGroups } from '../../shared/interfaces';
import { NotificationService } from './notification.service';
import { TranslationApiService } from './translation-api.service';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {

  private _translationCache: TranslationGroups = {};
  private _subjectTranslationsLoaded: Subject<TranslationGroups> = new Subject<TranslationGroups>();

  public translationsLoaded = this._subjectTranslationsLoaded.asObservable();

  constructor(
    private _notificationService: NotificationService,
    private _translationApiService: TranslationApiService
  ) { }

  public loadTranslationsSignIn(culture: string): void {
    this._translationApiService.getTranslationsSignIn(culture).subscribe(
      translations => {
        this._translationCache = translations;
        this._subjectTranslationsLoaded.next(translations);
      },
      _ => {
        this._notificationService.showErrorToast('Cannot load culture specific ui information');
      });
  }

  public loadTranslations(culture: string): void {
    this._translationApiService.getTranslationsCompleteApplication(culture).subscribe(
      translations => {
        this._translationCache = translations;
        this._subjectTranslationsLoaded.next(this._translationCache);
      },
      _ => {
        this._notificationService.showErrorToast('Cannot load culture specific ui information');
      });
  }

  public loadTranslationsForOnboarding(culture: string = 'de-DE'): void {
    this._translationApiService.getTranslationsForOnboarding(culture).subscribe(
      translations => {
        this._translationCache['onboarding'] = translations['onboarding'];
        this._translationCache['common'] = translations['common'];
        this._translationCache['errors'] = translations['errors'];
      },
      _ => {
        this._notificationService.showErrorToast('Cannot load culture specific onboarding information');
      }
    );
  }

  public cleanUp(): void {
    this._translationCache = null;
  }

  public translate(groupName: string, key: string, ...args: string[]): string {
    let group = this._translationCache[groupName] || {};

    if (!group[key] && groupName !== 'common') {
      group = this._translationCache['common'] || {};
    }

    if (!group[key]) {
      return this.getTranslationFallback(key, true);
    }

    if (args && args.length > 0) {
      return this.format(group[key], ...args);
    } else {
      return group[key];
    }
  }

  public translateWithoutFallback(groupName: string, key: string, ...args: string[]): string {
    let group = this._translationCache[groupName] || {};

    if (!group[key] && groupName !== 'common') {
      group = this._translationCache['common'] || {};
    }

    if (!group[key]) {
      return this.getTranslationFallback(key, false);
    }

    if (args && args.length > 0) {
      return this.format(group[key], ...args);
    } else {
      return group[key];
    }
  }

  public translateWithSecondKey(groupName: string, key: string, additionalKey: string): string {
    if (!additionalKey) {
      return '';
    }

    const firstTranslation = this.translate(groupName, key);
    let secondTranslation = this.translate('errors', additionalKey);

    if (secondTranslation.indexOf(' - missing') > -1) {
      secondTranslation = this.translate('common', additionalKey);
    }

    return `${firstTranslation}<br>${secondTranslation}`;
  }

  private getTranslationFallback(key: string, useFallback: boolean): string {
    return useFallback ? (key + ' - missing') : '';
  }

  private format(value: string, ...args: string[]): string {
    return value.replace(/(%\d+)/g, (match, number: string) => {
      const index = parseInt(number.substring(1), 10);

      return typeof args[index] != 'undefined'
        ? args[index]
        : match;
    }
    );
  }
}

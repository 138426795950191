import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { TimeSwiftDataType } from '../../shared/enums';
import { FileDownload } from '../../shared/interfaces';
import { AppSettings } from '../../shared/models';

@Injectable({
  providedIn: 'root'
})
export class DocumentApiService {

  private _url = AppSettings.endpoints.document;

  constructor(
    private _http: HttpClient) { }

  public getImageByGET(datatype: TimeSwiftDataType, parentId: number, documentId: number, width?: number): Observable<FileDownload> {
    let url = `${this._url}/image/${datatype}/${parentId}/${documentId}`;
    if (width && width > 0) {
      url += '?width=' + width;
    }

    return this._http.get(url, { observe: 'response', responseType: 'blob' }).pipe(map((res) => this.mapBlobResponse(res)));
  }

  public getThumbnailImageByGET(datatype: TimeSwiftDataType, parentId: number, documentId: number): Observable<FileDownload> {
    const url = `${this._url}/image/${datatype}/${parentId}/${documentId}/thumbnail`;

    return this._http.get(url, { observe: 'response', responseType: 'blob' }).pipe(map((res) => this.mapBlobResponse(res)));
  }

  public getFileByGET(datatype: TimeSwiftDataType, parentId: number, documentId: number): Observable<FileDownload> {
    return this._http.get(`${this._url}/download/${datatype}/${parentId}/${documentId}`, { observe: 'response', responseType: 'blob' }).pipe(map((res) => this.mapBlobResponse(res)));
  }

  public getFileByPOST(datatype: TimeSwiftDataType, parentId: number, documentId: number, requestData: any): Observable<FileDownload> {
    return this._http.post(`${this._url}/download/${datatype}/${parentId}/${documentId}`, requestData, { observe: 'response', responseType: 'blob' }).pipe(map((res) => this.mapBlobResponse(res)));
  }

  private mapBlobResponse(response: HttpResponse<Blob>): FileDownload {
    let contentDisposition = response.headers.get('Content-Disposition');
    let index = contentDisposition.indexOf('filename="');
    const withQuotes = index > -1;
    if (!withQuotes) {
      index = contentDisposition.indexOf('filename=');
    }

    if (index > -1) {
      contentDisposition = contentDisposition.substr(index + (withQuotes ? 10 : 9));
      contentDisposition = contentDisposition.substr(0, contentDisposition.indexOf(';') - (withQuotes ? 1 : 0));
    }

    const data: FileDownload = {
      file: new Blob([response.body], { type: response.headers.get('Content-Type') }),
      fileName: contentDisposition
    };

    return data;
  }
}

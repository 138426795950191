import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BasicDataRequest, BasicDataResponse } from '../../../shared/interfaces';
import { AppSettings } from '../../../shared/models';

@Injectable({
  providedIn: 'root'
})
export class BasicDataApiService {

  constructor(
    private _http: HttpClient
  ) { }

  public loadBasicData(request: BasicDataRequest): Observable<BasicDataResponse> {
    return this._http.post<BasicDataResponse>(AppSettings.endpoints.basicinformation.basicData, request);
  }
}

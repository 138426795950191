import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { EmployeeCostUnitAssignment, ResponseDataObject } from '../../../shared/interfaces';
import { AppSettings } from '../../../shared/models';
import { AbstractBasicDataApiService } from '../abstract-basic-data-api.service';

@Injectable({
  providedIn: 'root'
})
export class EmployeeCostUnitAssignmentApiService extends AbstractBasicDataApiService<EmployeeCostUnitAssignment> {

  constructor(
    protected http: HttpClient
  ) {
    super(http, AppSettings.endpoints.application.employeeCostUnitAssignment);
  }

  public upsert(userId: number, added: EmployeeCostUnitAssignment[], updated: EmployeeCostUnitAssignment[], deleted: number[]): Observable<ResponseDataObject<boolean>> {
    return this.http.patch<any>(
      `${this.baseUri}/users/${userId}`,
      {
        added,
        updated,
        deleted
      }
    );
  }

  public getByUserId(userId: number): Observable<ResponseDataObject<EmployeeCostUnitAssignment[]>> {
    return this.http.get<ResponseDataObject<EmployeeCostUnitAssignment[]>>(`${this.baseUri}/users/${userId}`);
  }
}

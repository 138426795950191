import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Nationality } from '../../../../shared/interfaces';
import { AppSettings } from '../../../../shared/models';
import { AbstractBasicDataApiService } from '../../abstract-basic-data-api.service';

@Injectable({
  providedIn: 'root'
}

) export class NationalityApiService extends AbstractBasicDataApiService<Nationality> {

  constructor(protected http: HttpClient) {
    super(http, AppSettings.endpoints.basicinformation.employees.nationality);
  }
}

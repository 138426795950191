import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { GridSearchParameter, LogEntry, ResponseDataObject, SearchApiService } from '../../../shared/interfaces';
import { AppSettings } from '../../../shared/models';

@Injectable({
  providedIn: 'root'
})
export class LogEntryApiService implements SearchApiService<LogEntry> {

  constructor(
    private _http: HttpClient
  ) { }

  public search(parameter: GridSearchParameter): Observable<ResponseDataObject<LogEntry[]>> {
    return this._http.post<ResponseDataObject<LogEntry[]>>(AppSettings.endpoints.multitenancy.logEntry + '/search', parameter);
  }

  public searchSum(parameter: GridSearchParameter): Observable<ResponseDataObject<any>> {
    return this._http.post<any>(AppSettings.endpoints.multitenancy.logEntry + '/search/sum', parameter);
  }

  public getLogEntryById(id: number): Observable<ResponseDataObject<LogEntry>> {
    return this._http.get<ResponseDataObject<LogEntry>>(AppSettings.endpoints.multitenancy.logEntry + '/' + id);
  }
}

import { EventEmitter, Injectable, Output } from '@angular/core';
import { Route } from '@angular/router';

import { MenuItem } from '../../shared/interfaces';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  private _menuItems: MenuItem[] = [];

  public get menuItems(): MenuItem[] {
    return this._menuItems;
  }

  @Output()
  public menuItemsChanged$: EventEmitter<MenuItem[]> = new EventEmitter<MenuItem[]>();

  constructor() { }

  public addNavigationItems(items: Route[]): void {
    this._menuItems = [];

    items.forEach(item => {
      if (item.data && item.path) {
        const newItem: MenuItem = this.buildMenuItem(item);
        if (item.data.menu) {
          this._menuItems.push(newItem);
        }
      }
    });

    this.menuItemsChanged$.emit(this._menuItems);
  }

  private buildMenuItem(item: Route): MenuItem {
    const newItem: MenuItem = {
      icon: item.data.icon,
      activeIcon: item.data.activeIcon || item.data.icon,
      path: item.path,
      title: item.data.title,
      permission: item.data.only,
      children: []
    };

    if (item.children) {
      item.children.forEach(c => {
        if (c.data && c.path) {
          newItem.children.push(this.buildMenuItem(c));
        }
      });
    }

    return newItem;
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthenticationState, LoginResultType } from '../../shared/enums';
import { AuthenticationResult, DropDownItem, UserTokenResponse } from '../../shared/interfaces';
import { AppSettings } from '../../shared/models';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnDestroy {

  private _currentUser: UserTokenResponse;
  private _authenticatedSubject = new Subject<AuthenticationResult>();
  private _timeoutId: any;
  public readonly authenticated$ = this._authenticatedSubject.asObservable();
  public redirectUrl: string;

  constructor(
    private _http: HttpClient,
    private _router: Router
  ) { }

  public ngOnDestroy(): void {
    if (this._timeoutId) {
      clearTimeout(this._timeoutId);
    }

    this._currentUser = null;
  }

  public isAuthenticated(): boolean {
    return (this._currentUser && moment().isBefore(this._currentUser.expires));
  }

  public isPasswordExpired(): boolean {
    return (this._currentUser && this._currentUser.authenticationState === AuthenticationState.RenewPasswordRequired);
  }

  public hasPermission(permission: string | string[]): boolean {
    /*
     * Fix for local administrator login
     * || (this._currentUser.expires !== '0001-01-01T00:00:00' && moment().isAfter(this._currentUser.expires))) {
     */

    if (!this._currentUser
      || !this._currentUser.permissions
      || this._currentUser.permissions.length === 0
      || moment().isAfter(this._currentUser.expires)) {
      return false;
    }

    if (!permission || permission.length === 0) {
      return true;
    }

    if (Array.isArray(permission)) {
      return this.hasOneOf(permission);
    }

    const result = this._currentUser.permissions.includes(permission);

    return result;
  }

  public setDebugPermissions(permissions: string[]): void {
    if (!AppSettings.debug || !this._currentUser) {
      return;
    }

    this._currentUser.permissions = permissions;
  }

  public authenticate(username: string, password: string): Observable<AuthenticationResult> {
    return this._http.post<UserTokenResponse>(AppSettings.endpoints.auth, { username: username, password: password, application: 1 })
      .pipe(
        map(user => {
          user.authenticationState = this.mapToAuthenticationState(user);
          this.setUser(user);

          const result = {
            authenticationState: user.authenticationState,
            tenants: user.tenants || (user.tenant ? [user.tenant] : [])
          };

          this.resetTimeout();
          this._authenticatedSubject.next(result);

          return result;
        })
      );
  }

  public authenticateWithParent(username: string, password: string, tenant: DropDownItem): Observable<AuthenticationResult> {
    return this._http.post<UserTokenResponse>(AppSettings.endpoints.auth, { username: username, password: password, tenantId: tenant.value, application: 1 })
      .pipe(
        map(user => {
          user.authenticationState = this.mapToAuthenticationState(user);
          this.setUser(user);

          const result = {
            authenticationState: user.authenticationState,
            tenants: user.tenants || []
          };

          this.resetTimeout();
          this._authenticatedSubject.next(result);

          return result;
        })
      );
  }

  public unauthenticate(): void {
    this.setUser(null);
    this.resetTimeout();
    this._authenticatedSubject.next({ authenticationState: AuthenticationState.NotAuthenticated });
    this._router.navigate(['/login']);
  }

  public getAuthenticationState(): AuthenticationState {
    if (this._currentUser
      && moment().isBefore(this._currentUser.expires)) {
      return this._currentUser.authenticationState;
    }

    return AuthenticationState.NotAuthenticated;
  }

  public getToken(): string {
    if (this._currentUser) {
      return this._currentUser.token;
    }

    return '';
  }

  public getUserId(): number {
    if (this._currentUser) {
      return this._currentUser.userId;
    }

    return null;
  }

  public getUsername(): string {
    if (this._currentUser) {
      return this._currentUser.username;
    }

    return '';
  }

  public getTenantName(): string {
    if (this._currentUser && this._currentUser.tenant) {
      return this._currentUser.tenant.text;
    }

    return '';
  }

  public getTenantId(): number {
    if (this._currentUser && this._currentUser.tenant) {
      return this._currentUser.tenant.value;
    }

    return null;
  }

  public loadStoredUser(): boolean {
    const storedUser = this.getStoredUser();

    if (storedUser && moment().isBefore(storedUser.expires)) {
      this._currentUser = storedUser;
      if (this._currentUser.permissionsBackup) {
        this._currentUser.permissions = this._currentUser.permissionsBackup;
      }
      this.reAuthenticate();

      return true;
    }

    return false;
  }

  public isStoredUserValid(): boolean {
    const storedUser = this.getStoredUser();

    return (storedUser && moment().isBefore(storedUser.expires));
  }

  public reAuthenticate(): void {
    this._http.get<UserTokenResponse>(AppSettings.endpoints.auth + '/check')
      .subscribe(user => {
        if (user.signInResult !== LoginResultType.LoginSuccessfull) {
          this._currentUser.authenticationState = this.mapToAuthenticationState(user);
        }
        this._currentUser.expires = user.expires;
        this._currentUser.token = user.token;
        this._currentUser.sessionId = user.sessionId;
        this._currentUser.userId = user.userId;
        this._currentUser.username = user.username;
        this._currentUser.signInResult = user.signInResult;
        this._currentUser.tenant = user.tenant;
        this.setUser(this._currentUser, false);

        const result = {
          authenticationState: this._currentUser.authenticationState,
          tenants: this._currentUser.tenants || []
        };

        this._authenticatedSubject.next(result);
        this.resetTimeout();
      });
  }

  private hasOneOf(requestedRights: string[]): boolean {
    if (!requestedRights) {
      return true;
    }

    if (!this._currentUser || !this._currentUser.permissions || this._currentUser.permissions.length === 0) {
      return false;
    }

    const result = requestedRights.some(requestedRight => this._currentUser.permissions.includes(requestedRight));

    return result;
  }

  private getStoredUser(): UserTokenResponse {
    return JSON.parse(localStorage.getItem(AppSettings.auth.user)) as UserTokenResponse;
  }

  private setUser(user: UserTokenResponse, backupPermissions: boolean = true): void {
    this._currentUser = user;
    if (!!this._currentUser && backupPermissions) {
      this._currentUser.permissionsBackup = this._currentUser.permissions;
    }
    localStorage.setItem(AppSettings.auth.user, JSON.stringify(user));
  }

  private mapToAuthenticationState(user: UserTokenResponse): AuthenticationState {
    let result = AuthenticationState.NotAuthenticated;
    switch (user.signInResult) {
      case LoginResultType.LoginSuccessfull:
        result = AuthenticationState.NoTenantSelected;
        if (user.tenant) {
          result = AuthenticationState.Authenticated;
        }
        break;
      case LoginResultType.RenewPasswordRequired:
        result = AuthenticationState.RenewPasswordRequired;
        break;
      case LoginResultType.ApplicationAccessDenied:
        result = AuthenticationState.ApplicationAccessDenied;
        break;
      default:
        result = AuthenticationState.NotAuthenticated;
        break;
    }

    return result;
  }

  private resetTimeout(): void {
    if (this._timeoutId) {
      clearTimeout(this._timeoutId);
    }

    if (this._currentUser && this._currentUser.expires) {
      const expiration = moment(this._currentUser.expires).add(-90, 'seconds');
      const timeout = expiration.diff(moment());
      if (timeout > 0) {
        this._timeoutId = setTimeout(() => {
          this.reAuthenticate();
        }, timeout);
      }
    }
  }
}

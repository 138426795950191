<div class="timeswift-context-menu"
     [class.invisible]="!items"
     [ngStyle]="{'left.px': xPosition, 'top.px': yPosition}">
  <ng-container *ngFor="let item of items">
    <div class="timeswift-context-menu-item"
         [class.main-action]="item.isMainAction"
         [class.disabled]="item.isDisabled"
         [style.color]="!item.isDisabled ? item.color : 'unset'"
         *ngIf="!item.isSeparator"
         (click)="onClickedItem(item)">
      <div class="timeswift-context-menu-item-icon">
        <fa-icon *ngIf="item.icon"
                 [icon]="item.icon"
                 [fixedWidth]="true"></fa-icon>
      </div>
      <div class="timeswift-context-menu-item-title"
           [title]="item.title">{{ item.title }}</div>
    </div>
    <div class="timeswift-context-menu-separator"
         *ngIf="item.isSeparator"></div>
  </ng-container>
</div>

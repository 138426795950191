import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { DropDownItem, GridSearchParameter, ResponseDataObject, SearchApiService, Tenant } from '../../../shared/interfaces';
import { AppSettings } from '../../../shared/models';

@Injectable({
  providedIn: 'root'
})
export class TenantApiService implements SearchApiService<Tenant> {

  constructor(
    private _http: HttpClient
  ) { }

  public search(parameter: GridSearchParameter): Observable<ResponseDataObject<Tenant[]>> {
    return this._http.post<ResponseDataObject<Tenant[]>>(AppSettings.endpoints.multitenancy.tenant + '/search', parameter);
  }

  public searchSum(parameter: GridSearchParameter): Observable<ResponseDataObject<Tenant>> {
    return this._http.post<ResponseDataObject<Tenant>>(AppSettings.endpoints.multitenancy.tenant + '/search/sum', parameter);
  }

  public getTenantDropDownList(): Observable<ResponseDataObject<DropDownItem[]>> {
    return this._http.get<ResponseDataObject<DropDownItem[]>>(AppSettings.endpoints.multitenancy.tenant + '/dropdown');
  }

  public getTenantById(id: number): Observable<ResponseDataObject<Tenant>> {
    return this._http.get<ResponseDataObject<Tenant>>(AppSettings.endpoints.multitenancy.tenant + '/' + id);
  }

  public getIsInUse(id: number): Observable<ResponseDataObject<boolean>> {
    return this._http.get<ResponseDataObject<boolean>>(AppSettings.endpoints.multitenancy.tenant + '/isUsed/' + id);
  }

  public getExists(name: string, id?: number): Observable<ResponseDataObject<boolean>> {
    let query = AppSettings.endpoints.multitenancy.role + '/exists/' + name;

    if (id) {
      query = query + '?tenantId=' + id;
    }

    return this._http.get<ResponseDataObject<boolean>>(query);
  }

  public addTenant(tenant: Tenant): Observable<ResponseDataObject<Tenant>> {
    return this._http.post<ResponseDataObject<Tenant>>(AppSettings.endpoints.multitenancy.tenant, tenant);
  }

  public updateTenant(tenant: Tenant): Observable<ResponseDataObject<Tenant>> {
    return this._http.put<ResponseDataObject<Tenant>>(AppSettings.endpoints.multitenancy.tenant, tenant);
  }

  public deleteTenant(id: number): Observable<ResponseDataObject<boolean>> {
    return this._http.delete<ResponseDataObject<boolean>>(AppSettings.endpoints.multitenancy.tenant + '/' + id);
  }
}

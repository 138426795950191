import { Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from '../../core/services/translation.service';

@Pipe({
  name: 'translation'
})
export class TranslationPipe implements PipeTransform {

  constructor(private _translationService: TranslationService) { }

  public transform(value: string, groupName: string, _?: any): string {
    return this._translationService.translate(groupName, value);
  }
}

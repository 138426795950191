<label *ngIf="!hideLabel">{{ label }}</label>
<div class="input-group">
  <span class="input-group-text"
        *ngIf="prependIcon">
    <fa-icon [icon]="prependIcon"
             [fixedWidth]="true"></fa-icon>
  </span>
  <input type="date"
         class="form-control"
         [placeholder]="placeholder"
         [ngClass]="{'is-invalid': dateValue.invalid && (dateValue.dirty || dateValue.touched)}"
         #dateValue="ngModel"
         name="{{ name }}"
         [ngModel]="value"
         (ngModelChange)="onValueChange($event)"
         [required]="isRequired"
         [disabled]="isDisabled"
         [readOnly]="isReadonly"
         [min]="minDate | date:'yyyy-MM-dd'"
         [max]="maxDate | date:'yyyy-MM-dd'"
         [minDate]="minDate | date:'yyyy-MM-dd'"
         [maxDate]="maxDate | date:'yyyy-MM-dd'">
  <span class="input-group-text"
        *ngIf="appendIcon">
    <fa-icon [icon]="appendIcon"
             [fixedWidth]="true"></fa-icon>
  </span>
</div>
<div class="invalid-feedback d-block"
     *ngIf="!hideErrors && dateValue.invalid && (dateValue.dirty || dateValue.touched || !!value)">
  <div *ngFor="let dateValueError of dateValue.errors | keyvalue">
    <ng-container *ngIf="errorMessages && errorMessages[dateValueError.key]"> {{ errorMessages[dateValueError.key]}} </ng-container>
    <ng-container *ngIf="!errorMessages || !errorMessages[dateValueError.key]"> {{ dateValueError.key | translation:'errors' }} </ng-container>
  </div>
</div>

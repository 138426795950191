import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Employer } from '../../../../shared/interfaces';
import { AppSettings } from '../../../../shared/models';
import { AbstractBasicDataApiService } from '../../abstract-basic-data-api.service';

@Injectable({
  providedIn: 'root'
})
export class EmployerApiService extends AbstractBasicDataApiService<Employer> {

  constructor(
    protected http: HttpClient
  ) {
    super(http, AppSettings.endpoints.basicinformation.employees.employer);
  }
}

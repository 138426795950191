export enum WorkflowComponentType {
  none = 0,
  picture = 1,
  startMileage = 2,
  endMileage = 3,
  startTime = 4,
  endTime = 5,
  unit = 6,
  checkbox = 7,
  text = 8,
  textMultiline = 9,
  gps = 10,
  barcodeQRCode = 11,
  ratingStars = 12
}

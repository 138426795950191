import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './core/services/auth.guard';
import { AppShellComponent } from './shared/components/app-shell/app-shell.component';
import { DashboardComponent } from './shared/components/dashboard/dashboard.component';
import { FileDownloadComponent } from './shared/components/file-download/file-download.component';
import { ForbiddenComponent } from './shared/components/forbidden/forbidden.component';
import { LoginComponent } from './shared/components/login/login.component';
import { NotFoundComponent } from './shared/components/not-found/not-found.component';
import { icons } from './shared/helper/icon-helper';


export const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    component: AppShellComponent,
    children: [{
      path: 'employees',
      loadChildren: () => import('./employees/employees.module').then(m => m.EmployeesModule),
      canActivate: [AuthGuard],
    }, {
      path: 'vehicles',
      loadChildren: () => import('./vehicles/vehicles.module').then(m => m.VehiclesModule),
      canActivate: [AuthGuard],
    }, {
      path: 'applications',
      loadChildren: () => import('./applications/applications.module').then(m => m.ApplicationsModule),
      canActivate: [AuthGuard],
    }, {
      path: 'work-orders',
      loadChildren: () => import('./work-orders/work-orders.module').then(m => m.WorkOrdersModule),
      canActivate: [AuthGuard],
    }, {
      path: 'companies',
      loadChildren: () => import('./companies/companies.module').then(m => m.CompaniesModule),
      canActivate: [AuthGuard],
    }, {
      path: 'administration',
      loadChildren: () => import('./administration/administration.module').then(m => m.AdministrationModule),
      canActivate: [AuthGuard],
    }, {
      path: 'settings',
      loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule),
      canActivate: [AuthGuard],
    }, {
      path: 'support',
      loadChildren: () => import('./support/support.module').then(m => m.SupportModule),
      canActivate: [AuthGuard],
    }, {
      path: '',
      pathMatch: 'full',
      canActivate: [AuthGuard],
      component: DashboardComponent
    }]
  }, {
    path: 'login',
    component: LoginComponent
  }, {
    path: 'onboarding-external',
    loadChildren: () => import('./onboarding-external/onboarding-external.module').then(m => m.OnboardingExternalModule)
  }, {
    path: '_blank',
    pathMatch: 'full',
    redirectTo: 'file-download'
  }, {
    path: 'file-download',
    pathMatch: 'full',
    canActivate: [AuthGuard],
    component: FileDownloadComponent
  }, {
    path: '403',
    component: ForbiddenComponent
  }, {
    path: '404',
    component: NotFoundComponent
  }, {
    path: '**',
    redirectTo: '404'
  }];

export const mainNavigationRoutes: Routes = [{
  path: 'employees',
  data: {
    only: ['employees.read'],
    menu: true,
    title: 'employees',
    icon: icons.user,
    activeIcon: icons.user
  },
}, {
  path: 'vehicles',
  data: {
    only: ['vehicles.read'],
    menu: true,
    title: 'vehicles',
    icon: icons.vehicles,
    activeIcon: icons.vehicles
  },
}, {
  path: 'applications',
  data: {
    only: ['applications.read'],
    menu: true,
    title: 'applications',
    icon: icons.applications,
    activeIcon: icons.applications
  }
}, {
  path: 'work-orders',
  data: {
    only: ['workorders.read'],
    menu: true,
    title: 'workOrders',
    icon: icons.workOrders,
    activeIcon: icons.workOrders
  }
}, {
  path: 'companies',
  data: {
    only: ['company.read'],
    menu: true,
    title: 'company',
    icon: icons.company,
    activeIcon: icons.company
  }
}, {
  path: 'administration',
  data: {
    only: ['globaladministrator.read', 'localadministrator.read']
  }
}, {
  path: 'settings',
  data: {
    only: ['basicinformationcommon.read'],
    menu: true,
    title: 'settings',
    icon: icons.settings,
    activeIcon: icons.settings
  }
}, {
  path: 'support',
  data: {
    only: ['webfrontend.edit'],
    menu: true,
    title: 'support',
    icon: icons.support,
    activeIcon: icons.support
  }
}];


@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

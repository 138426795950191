import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { CrudApiService, GridSearchParameter, Identifier, ResponseDataObject, SearchApiService } from '../../shared/interfaces';

export abstract class AbstractBasicDataApiService<T extends Identifier> implements SearchApiService<T>, CrudApiService<T> {

  constructor(
    protected http: HttpClient,
    protected baseUri: string
  ) { }

  public search(parameter: GridSearchParameter): Observable<ResponseDataObject<T[]>> {
    return this.http.post<ResponseDataObject<T[]>>(this.baseUri + '/search', parameter);
  }

  public searchSum(parameter: GridSearchParameter): Observable<ResponseDataObject<any>> {
    return this.http.post<ResponseDataObject<any>>(this.baseUri + '/search/sum', parameter);
  }

  public getById(itemId: number): Observable<ResponseDataObject<T>> {
    return this.http.get<ResponseDataObject<T>>(this.baseUri + '/' + itemId);
  }

  public getIsUsed(itemId: number): Observable<ResponseDataObject<boolean>> {
    return this.http.get<ResponseDataObject<boolean>>(this.baseUri + '/isUsed/' + itemId);
  }

  public createItem(item: T): Observable<ResponseDataObject<T>> {
    return this.http.post<ResponseDataObject<T>>(this.baseUri, item);
  }

  public updateItem(item: T): Observable<ResponseDataObject<T>> {
    return this.http.put<ResponseDataObject<T>>(this.baseUri, item);
  }

  public deleteItem(itemId: number): Observable<ResponseDataObject<boolean>> {
    return this.http.delete<ResponseDataObject<boolean>>(this.baseUri + '/' + itemId);
  }
}

import { Injectable } from '@angular/core';

import { NumberMaskConfig } from '../../shared/interfaces';


@Injectable({
  providedIn: 'root'
})
export class CurrencyLogicLayer {

  constructor() { }

  public formatCurrency(value: number | string, config: NumberMaskConfig): string {
    if (!config) {
      return value.toString();
    }

    return this.format(value.toString(), config);
  }

  private format(value: string, options: NumberMaskConfig): string {
    const { allowNegative, decimal, precision, prefix, suffix, thousands } = options;
    value = parseFloat(value).toFixed(precision);
    const onlyNumbers = value.replace(/[^0-9]/g, '');

    if (!onlyNumbers) {
      return '';
    }

    let integerPart = onlyNumbers.slice(0, onlyNumbers.length - precision).replace(/^0*/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, thousands);

    if (integerPart === '') {
      integerPart = '0';
    }

    let newRawValue = integerPart;
    let decimalPart = onlyNumbers.slice(onlyNumbers.length - precision);

    if (precision > 0) {
      decimalPart = '0'.repeat(precision - decimalPart.length) + decimalPart;
      newRawValue += decimal + decimalPart;
    }

    const isZero = parseInt(integerPart, 10) === 0 && (parseInt(decimalPart, 10) === 0 || decimalPart === '');
    const operator = (value.indexOf('-') > -1 && allowNegative && !isZero) ? '-' : '';

    return operator + prefix + newRawValue + suffix;
  }
}

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { Subscription } from 'rxjs';

import { BannerService } from '../../../core/services';
import { icons } from '../../helper';
import { Banner } from '../../interfaces';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit, OnDestroy {

  private _subscription = new Subscription();
  private _timeout = 7000;

  @Input()
  public bannerId: string;

  public banner: Banner = {
    title: 'random error',
    text1: 'random message',
    text2: 'additional erros',
  };

  public icons = icons;

  public isVisible = false;

  constructor(
    private _bannerService: BannerService
  ) {
    this._subscription.add(
      this._bannerService.banner$.subscribe(banner => {
        if (this.checkBannerId(banner.bannerId)) {
          this.banner = banner;
          this.isVisible = true;
          setTimeout(() => {
            this.hide();
          }, this._timeout);
        }
      })
    );
  }

  public ngOnInit(): void {
    this.getBannerBackgroundClass();
  }

  public ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  public getFallbackIcon(): IconDefinition {
    if (!this.banner || !this.banner.kind) {
      return icons.exclamation;
    }

    switch (this.banner.kind) {
      case 'critical':
        return icons.critical;
      case 'info':
        return icons.info;
      case 'warning':
        return icons.exclamation;
      case 'success':
        return icons.check;
    }
  }

  public getBannerBackgroundClass(): any {
    const result = {
      'banner-visible': this.isVisible
    };

    let bgColor = 'bg-warning';
    switch (this.banner.kind) {
      case 'critical':
        bgColor = 'bg-danger';
        break;
      case 'info':
        bgColor = 'bg-info';
        break;
      case 'warning':
        bgColor = 'bg-warning';
        break;
      case 'success':
        bgColor = 'bg-success';
        break;
    }

    result[bgColor] = true;

    return result;
  }

  public hide(): void {
    this.isVisible = false;
  }

  private checkBannerId(bannerId: string): boolean {
    if (!bannerId && !this.bannerId) {
      return true;
    }

    return bannerId === this.bannerId;
  }
}

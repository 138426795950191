import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-date-time-form-control',
  templateUrl: './date-time-form-control.component.html',
  styleUrls: ['./date-time-form-control.component.scss']
})
export class DateTimeFormControlComponent implements OnInit {

  private _timeValue = {
    hour: 0,
    minute: 0
  };

  @Input()
  public value: string | moment.Moment;

  @Input()
  public index: number;

  @Input()
  public labelDate: string;

  @Input()
  public labelTime: string;

  @Input()
  public nameDate: string;

  @Input()
  public nameTime: string;

  @Input()
  public isRequired: boolean;

  @Input()
  public useFlexLayout = true;

  @Input()
  public cssClassContainer: string;

  @Input()
  public cssClassDate: string;

  @Input()
  public cssClassTime: string;

  public dateValue: string;

  @Input()
  public minDate: string;

  @Output()
  public valueChange = new EventEmitter<string | moment.Moment>();

  public timeValueString = '';

  constructor() { }

  public ngOnInit(): void {
    if (this.value) {
      const dateTime = moment(this.value);

      this.dateValue = dateTime.format('YYYY-MM-DD');
      this._timeValue.hour = dateTime.hours();
      this._timeValue.minute = dateTime.minutes();

      this.timeValueString = `${this.formatNumber(this._timeValue.hour)}:${this.formatNumber(this._timeValue.minute)}`;
    }
  }

  public onChangeTime(): void {
    if (!!this.timeValueString && this.timeValueString.indexOf(':') > -1) {
      this._timeValue.hour = parseInt(this.timeValueString.split(':')[0], 10);
      this._timeValue.minute = parseInt(this.timeValueString.split(':')[1], 10);
    } else {
      this._timeValue.hour = -1;
      this._timeValue.minute = -1;
    }
    this.buildDateTime();
  }

  public onChangeDate(): void {
    if (!this.dateValue) {
      this.resetValues();

      return;
    }

    this.buildDateTime();
  }

  private buildDateTime(): void {
    if (this._timeValue.hour >= 0 && this._timeValue.minute >= 0 && !!this.dateValue) {
      let dateTime = moment(this.dateValue);
      dateTime = dateTime.add(this._timeValue.hour, 'hour');
      dateTime = dateTime.add(this._timeValue.minute, 'minute');

      this.value = dateTime as any;
    } else {
      this.value = null;
    }

    this.valueChange.next(this.value);
  }

  private resetValues(): void {
    this.dateValue = null;
    this.value = null;
    this._timeValue = {
      hour: -1,
      minute: -1
    };
    this.valueChange.next(this.value);
  }

  private formatNumber(value: number): string {
    if (value < 10) {
      return '0' + value;
    }

    return value.toString(10);
  }

}

import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'mask-form-control',
  templateUrl: './mask-form-control.component.html',
  styleUrls: ['./mask-form-control.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class MaskFormControlComponent {

  @Input()
  public label: string;

  @Input()
  public value: string;

  @Output()
  public valueChange = new EventEmitter<string>();

  @Input()
  public isRequired = false;

  @Input()
  public prependIcon: IconDefinition;

  @Input()
  public appendIcon: IconDefinition;

  @Input()
  public placeholder = '';

  @Input()
  public isDisabled = false;

  @Input()
  public name: string;

  @Input()
  public hideLabel = false;

  @Input()
  public mask: string;

  @Input()
  public hideErrors = false;

  constructor() { }

  public onValueChange(data: string): void {
    this.value = data;
    this.valueChange.emit(this.value);
  }

}

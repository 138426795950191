<label *ngIf="!hideLabel">{{ label }}</label>
<div class="tag-box">
  <ng-container *ngIf="tagPosition === 'top'">
    <ng-container *ngTemplateOutlet="tagList;"></ng-container>
  </ng-container>
  <div class=" input-group"
       *ngIf="!isReadonly && !isDisabled && (maxTagCount === 0 || maxTagCount > tags.length)">
    <input type="text"
           class="form-control"
           [ngClass]="{ 'mt-2': tagPosition === 'top', 'mb-2': tagPosition === 'bottom'}"
           #textValue="ngModel"
           name="{{ name }}"
           [placeholder]="placeholder"
           [title]="tooltip"
           [(ngModel)]="tagInput"
           [ngbTypeahead]="searchTags"
           (blur)="addTag()"
           (keydown)="checkKey($event)">
  </div>
  <ng-container *ngIf="tagPosition === 'bottom'">
    <ng-container *ngTemplateOutlet="tagList;"></ng-container>
  </ng-container>
</div>
<ng-template #tagList>
  <div [class]="'tag ' + tagCssClass"
       *ngFor="let tag of tags">
    <span>{{tag}}</span>
    <span [ngClass]="{ 'tag-remove': !isReadonly && !isDisabled }"
          (click)="removeTag(tag)"></span>
  </div>
</ng-template>

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { AppSettings } from '../../shared/models';
import { LocalStorageService } from '../../shared/services';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {

  private _sideBarSubject = new Subject<boolean>();
  private _layout = {
    showSideBar: false
  };

  public showSideBar$ = this._sideBarSubject.asObservable();

  constructor(
    private _localStorageService: LocalStorageService
  ) {
    this.loadState();
  }

  public showSideBar(show: boolean): void {
    this._layout.showSideBar = show;
    this._sideBarSubject.next(show);
    this.saveState();
  }

  public getSideBarState(): boolean {
    return this._layout.showSideBar;
  }

  private saveState(): void {
    this._localStorageService.setItem(AppSettings.settings.layout, this._layout);
  }

  private loadState(): void {
    this._layout = (this._localStorageService.getItem(AppSettings.settings.layout) as any) || this._layout;
  }
}

<div class="date-time-picker-container {{cssClassContainer}}"
     [class.flex]="useFlexLayout">
  <div class="date-picker-container {{cssClassDate}}"
       [class.no-label]="!labelDate">
    <date-form-control [name]="nameDate + (index ||'')"
                       [hideLabel]="!labelDate"
                       [label]="labelDate"
                       [isRequired]="isRequired"
                       [(value)]="dateValue"
                       [minDate]="minDate"
                       (valueChange)="onChangeDate()">
    </date-form-control>
  </div>
  <div class="time-picker-container {{cssClassTime}}"
       [class.no-label]="!labelTime">
    <app-time-picker-form-control [name]="nameTime + (index ||'')"
                                  [hideLabel]="!labelTime"
                                  [label]="labelTime"
                                  [(value)]="timeValueString"
                                  [isRequired]="isRequired"
                                  (valueChange)="onChangeTime()"></app-time-picker-form-control>
  </div>
</div>

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { AssistantConfiguration, AssistantDataRecordInformation } from '../../shared/interfaces';

@Injectable({
  providedIn: 'root'
})
export class AssistantService {
  private _subjectMetaDataRecordToAssistantSent: Subject<any> = new Subject<any>();
  private _subjectMetaDataRecordToAssistantStepSent: Subject<any> = new Subject<any>();
  private _subjectMetaDataRecordRequested: Subject<any> = new Subject<any>();
  private _subjectNavigationRequested: Subject<boolean> = new Subject<boolean>();
  private _subjectSaveRequested: Subject<any> = new Subject<any>();
  private _subjectSaveCompleted: Subject<boolean> = new Subject<boolean>();
  private _subjectValidationStateChanged: Subject<boolean> = new Subject<boolean>();
  private _subjectConfigurationChanged: Subject<AssistantConfiguration> = new Subject<AssistantConfiguration>();
  private _subjectDataRecordInformationChanged: Subject<AssistantDataRecordInformation> = new Subject<AssistantDataRecordInformation>();
  private _subjectNavigateToFirstStepRequested: Subject<boolean> = new Subject<boolean>();
  private _subjectReloadBasicDataRequested: Subject<any> = new Subject<any>();
  private _subjectReloadBasicDataCompleted: Subject<any> = new Subject<any>();
  private _subjectCancelRequestDecisionOvervoted: Subject<boolean> = new Subject<any>();
  private _subjectInitialDataRecordSet: Subject<any> = new Subject<any>();
  private _subjectChangedDataCheckTriggered: Subject<any> = new Subject<any>();
  private _subjectChangedDataExecuted: Subject<boolean> = new Subject<boolean>();

  public metaDataRecordToAssistantSent = this._subjectMetaDataRecordToAssistantSent.asObservable();
  public metaDataRecordToAssistantStepSent = this._subjectMetaDataRecordToAssistantStepSent.asObservable();
  public metaDataRecordRequested = this._subjectMetaDataRecordRequested.asObservable();
  public navigationRequested = this._subjectNavigationRequested.asObservable();
  public saveRequested = this._subjectSaveRequested.asObservable();
  public saveCompleted = this._subjectSaveCompleted.asObservable();
  public validationStateChange = this._subjectValidationStateChanged.asObservable();
  public configurationChanged = this._subjectConfigurationChanged.asObservable();
  public dataRecordInformationChanged = this._subjectDataRecordInformationChanged.asObservable();
  public navigateToFirstStepRequested = this._subjectNavigateToFirstStepRequested.asObservable();
  public reloadBasicDataRequested = this._subjectReloadBasicDataRequested.asObservable();
  public reloadBasicDataCompleted = this._subjectReloadBasicDataCompleted.asObservable();
  public cancelRequestDecisionOvervoted = this._subjectCancelRequestDecisionOvervoted.asObservable();
  public initialDataRecordSet = this._subjectInitialDataRecordSet.asObservable();
  public changedDataCheckTriggered = this._subjectChangedDataCheckTriggered.asObservable();
  public changedDataExecuted = this._subjectChangedDataExecuted.asObservable();

  constructor() { }

  public navigate(next: boolean): void {
    this._subjectNavigationRequested.next(next);
  }

  public changeValidationStatus(isValid: boolean): void {
    this._subjectValidationStateChanged.next(isValid);
  }

  public changeConfiguration(configuration: AssistantConfiguration): void {
    this._subjectConfigurationChanged.next(configuration);
  }

  public changeDataRecordInformation(dataRecordInformation: AssistantDataRecordInformation): void {
    this._subjectDataRecordInformationChanged.next(dataRecordInformation);
  }

  public sendMetaDataRecordToAssistant(metaDataRecord: any): void {
    this._subjectMetaDataRecordToAssistantSent.next(metaDataRecord);
  }

  public sendMetaDataRecordToAssistantStep(metaDataRecord: any): void {
    this._subjectMetaDataRecordToAssistantStepSent.next(metaDataRecord);
  }

  public requestMetaDataRecord(): void {
    this._subjectMetaDataRecordRequested.next(null);
  }

  public requestSave(): void {
    this._subjectSaveRequested.next(null);
  }

  public completeSave(result: boolean): void {
    this._subjectSaveCompleted.next(result);
  }

  public navigateToFirstStep(redirectToCurrentStep: boolean = false): void {
    this._subjectNavigateToFirstStepRequested.next(redirectToCurrentStep);
  }

  public requestReloadBasicData(): void {
    this._subjectReloadBasicDataRequested.next(null);
  }

  public completeReloadBasicData(): void {
    this._subjectReloadBasicDataCompleted.next(null);
  }

  public overvoteCancelRequestDecision(overvoted: boolean): void {
    this._subjectCancelRequestDecisionOvervoted.next(overvoted);
  }

  public setInitialDataRecord(dataRecord: any): void {
    this._subjectInitialDataRecordSet.next(dataRecord);
  }

  public triggerChangedDataCheck(): void {
    this._subjectChangedDataCheckTriggered.next(null);
  }

  public hasChangedData(changedData: boolean): void {
    this._subjectChangedDataExecuted.next(changedData);
  }
}

import { Injectable } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup, NgForm } from '@angular/forms';
import { Subject } from 'rxjs';

import { errorMessageConstants } from '../../shared/constants';
import { Banner, BannerData } from '../../shared/interfaces';
import { TranslationService } from './translation.service';

@Injectable({ providedIn: 'root' })
export class BannerService {

  private _bannerSubject = new Subject<Banner>();

  public banner$ = this._bannerSubject.asObservable();

  constructor(
    private _translationService: TranslationService
  ) { }

  public showFormErrors(form: NgForm, translationGroup: string, bannerId: string = null): void {
    this.showErrors(this.getAllErrors(form.form), translationGroup, bannerId);
  }

  public showErrors(errors: BannerData[], translationGroup: string, bannerId: string = null): void {
    if (errors.length === 0) {
      return;
    } else if (errors.length === 1) {
      // transform first only
      const first = errors[0];

      this.clubber(first, translationGroup, 0, bannerId);

      return;
    } else {
      // transform first and count others
      const others = errors.length - 1;
      const first = errors[0];
      this.clubber(first, translationGroup, others, bannerId);

      return;
    }
  }

  public showBanner(banner: Banner): void {
    this._bannerSubject.next(banner);
  }

  private getAllErrors(form: UntypedFormGroup | UntypedFormArray): BannerData[] {
    return Object.keys(form.controls).reduce((acc, key) => {
      const control = form.get(key);
      if (control instanceof UntypedFormGroup || control instanceof UntypedFormArray) {
        acc.push(...this.getAllErrors(control));
      } else if (control.errors) {
        acc.push({
          field: key,
          control: control,
          errors: control.errors
        });
      }

      return acc;
    }, []);
  }

  private clubber(item: BannerData, translationGroup: string, others: number = 0, bannerId: string = null): void {
    const errorKey = Object.keys(item.errors)[0];

    let fieldName = item.field;
    const hyphenIndex = fieldName.indexOf('-');
    if (hyphenIndex > 0) {
      fieldName = fieldName.substring(0, hyphenIndex);
    }

    const fieldTranslation = this._translationService.translate(translationGroup, fieldName);
    const errorTranslation = this._translationService.translate('errors', errorKey);
    const titleTranslation = this._translationService.translate('errors', errorMessageConstants.validationErrors);
    const otherTranslation = others === 1
      ? this._translationService.translate('errors', errorMessageConstants.additionalValidationError)
      : this._translationService.translate('errors', errorMessageConstants.additionalValidationErrors, others.toString())
      ;

    const banner: Banner = {
      bannerId: bannerId,
      title: titleTranslation,
      text1: `${fieldTranslation}: ${errorTranslation}`,
      text2: others > 0 ? otherTranslation : ''
    };

    this._bannerSubject.next(banner);
  }


}

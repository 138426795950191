import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';
import { CustomValidatorOptions } from '../../interfaces';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[customValidator][ngModel],[customValidator][formControl]',
  providers: [{ provide: NG_VALIDATORS, useExisting: CustomValidatorDirective, multi: true }]
})
export class CustomValidatorDirective implements Validator {

  // tslint:disable-next-line:no-input-rename
  @Input('customValidator')
  public options: CustomValidatorOptions;

  constructor() { }

  public validate(control: AbstractControl): { [key: string]: any } | null {

    if (!this.options || !control.value) {
      return null;
    }

    const error: { [key: string]: any } = {};
    error[this.options.errorKey] = { value: control.value };

    if (this.options.minLength && this.options.minLength > 0 && control.value.toString().length < this.options.minLength) {
      return error;
    }

    if (!this.options.regExp || !this.options.regExp.test(control.value)) {
      return error;
    }

    return null;
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'textarea-form-control',
  templateUrl: './textarea-form-control.component.html',
  styleUrls: ['./textarea-form-control.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class TextareaFormControlComponent {

  @Input()
  public label = '';

  @Input()
  public value: string;

  @Output()
  public valueChange = new EventEmitter<string>();

  @Input()
  public isRequired = false;

  @Input()
  public isDisabled = false;

  @Input()
  public isReadonly = false;

  @Input()
  public prependIcon: IconDefinition;

  @Input()
  public appendIcon: IconDefinition;

  @Input()
  public placeholder = '';

  @Input()
  public maxlength: number = null;

  @Input()
  public name: string;

  @Input()
  public hideLabel = false;

  @Input()
  public rows = 3;

  @Input()
  public hideErrors = false;

  constructor() { }

  public onValueChange(data: string): void {
    this.value = data;
    this.valueChange.emit(this.value);
  }

}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { TranslationGroups } from '../../shared/interfaces';

@Injectable({
  providedIn: 'root'
})
export class TranslationApiService {

  private _baseUrl = environment.baseUrl + 'culture/';

  constructor(private _http: HttpClient) { }

  public getTranslationsSignIn(culture: string): Observable<TranslationGroups> {
    return this._http.get<TranslationGroups>(this._baseUrl + 'signin/' + culture);
  }

  public getTranslationsCompleteApplication(culture: string): Observable<TranslationGroups> {

    return this._http.get<TranslationGroups>(this._baseUrl + 'all/' + culture);
  }

  public getTranslationsForOnboarding(culture: string = 'de-DE'): Observable<TranslationGroups> {
    return this._http.get<TranslationGroups>(this._baseUrl + 'onboarding/?culture=' + culture);
  }
}

import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { Subscription } from 'rxjs';

import { DateTimeLogicLayer } from '../../../core/business-logic';
import { GridControlBarService, GridLayoutApiService, TranslationService } from '../../../core/services';
import { ColumnType, CompareOperator, FieldDataType, FooterRowAction, GridDataStatus, SortDirection } from '../../enums';
import { icons } from '../../helper';
import { GridColumn, GridColumnSort, GridConfiguration, GridContextMenuClick, GridLayout, GridSearchParameter, SearchApiService } from '../../interfaces';
import { LocalStorageService } from '../../services';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss']
})
export class GridComponent implements OnInit, OnDestroy, AfterViewInit {

  private _userCulture: string;
  private _subscriptions: Subscription = new Subscription();
  private _currentSearchParameter: GridSearchParameter;
  private _noFurtherData = false;
  private _gridLayoutId: number;
  private _isControlbarVisible = true;

  public icons = icons;
  public gridDataStatus = GridDataStatus;
  public footerRowAction = FooterRowAction;
  public labelYes = '';
  public labelNo = '';
  public labelAll = '';
  public labelSearch = '';
  public labelLoading = '';
  public labelNoItems = '';
  public labelErrorOccurred = '';
  public dataStatus: GridDataStatus = GridDataStatus.isLoading;

  @ViewChild('gridContainer')
  public gridContainer: ElementRef;

  @Input()
  public configuration: GridConfiguration;
  @Input()
  public searchApiService: SearchApiService<any>;

  @Output()
  public rightClick = new EventEmitter<GridContextMenuClick>();

  public data: any[] = [];
  public footerRow: any = {};
  public sortDirection = SortDirection;
  public columnType = ColumnType;
  public selectedItem: any;

  constructor(
    private _localStorageService: LocalStorageService,
    private _translationService: TranslationService,
    private _dateTimeLogicLayer: DateTimeLogicLayer,
    private _gridLayoutApiService: GridLayoutApiService,
    private _gridControlbarService: GridControlBarService) {

    this._subscriptions.add(this._gridControlbarService.dataUpdateRequested.subscribe(parameter => {
      if (this.allowGridAction()) {
        if (this.configuration.isOfflineMode) {
          this.reloadOfflineModeData();
        } else {
          this.search(parameter);
        }
      }
    }));

    this._subscriptions.add(this._gridControlbarService.doubleClickEnabled.subscribe(enable => {
      if (this.allowGridAction()) {
        this.configuration.isEnabledDoubleClick = enable;
      }
    }));

    this._subscriptions.add(this._gridControlbarService.filterbarVisibilityChanged.subscribe(visible => {
      if (this.allowGridAction()) {
        this.configuration.isVisibleColumnFilter = visible;
        this.calculateGridColumSize();
      }
    }));

    this._subscriptions.add(this._gridControlbarService.columnVisibilityChanged.subscribe(_ => {
      if (this.allowGridAction()) {
        this.calculateGridColumSize();
      }
    }));

    this._subscriptions.add(this._gridControlbarService.saveFilterChanged.subscribe(save => {
      if (this.allowGridAction()) {
        this.configuration.saveFilter = save;
      }
    }));

    this._subscriptions.add(this._gridControlbarService.visibilityChanged.subscribe(visible => {
      this._isControlbarVisible = visible;
    }));

    this._subscriptions.add(this._gridControlbarService.clearGridRequested.subscribe(_ => {
      this.data = [];
      this.dataStatus = GridDataStatus.hasNoItems;
    }));
  }

  @HostListener('window:resize', ['$event'])
  public onResize($event: any): void {
    this.calculateGridColumSize();
    if (!this.configuration.isOfflineMode) {
      this.checkLoadFurther();
    }
  }

  @HostListener('contextmenu', ['$event'])
  public onRightClick($event: MouseEvent): void {
    $event.preventDefault();

    const item = this.determineRow($event);
    if (item) {
      this.selectItem(item);
    }
    this.rightClick.next({
      item: item,
      xPosition: $event.clientX,
      yPosition: $event.clientY
    });
  }

  public ngOnInit(): void {
    if (this.configuration.isEnabledDoubleClick === undefined) {
      this.configuration.isEnabledDoubleClick = true;
    }

    this._userCulture = this._localStorageService.userProfile.culture;
    this.labelYes = this._translationService.translate('common', 'yes');
    this.labelNo = this._translationService.translate('common', 'no');
    this.labelAll = this._translationService.translate('common', 'all');
    this.labelSearch = this._translationService.translate('common', 'search');
    this.labelLoading = this._translationService.translate('common', 'loading');
    this.labelNoItems = this._translationService.translate('common', 'noItems');
    this.labelErrorOccurred = this._translationService.translate('common', 'occurred');

    this.dataStatus = this.configuration.gridDataStatus || GridDataStatus.isLoading;
    this.configuration.columns.forEach(c => {
      c.displayName = this.getColumnName(c);
      c.gridName = c.name + 'Grid';
    });
    if (this.configuration.enableGridAction) {
      this._subscriptions.add(this.configuration.enableGridAction.subscribe(enabled => {
        this.configuration.isGridDisabled = !enabled;
        if (enabled) {
          this._gridControlbarService.configureControlBar(this.configuration);
        }
      }));
    }
  }

  public ngAfterViewInit(): void {
    this.calculateGridColumSize();
    this.loadGridLayout();
    this.reloadOfflineModeData();
  }

  public ngOnDestroy(): void {
    this._gridControlbarService.destroyControlBar();
    this._subscriptions.unsubscribe();
    this.dataStatus = GridDataStatus.cancelLoading;
    this.saveGridLayout();
  }

  public loadFurtherData(): void {
    if (this.dataStatus !== GridDataStatus.cancelLoading &&
      this.dataStatus !== GridDataStatus.isLoading &&
      this._currentSearchParameter.isEnabledPagination &&
      !this._noFurtherData) {

      this._currentSearchParameter.resetGridStatus = false;
      this._currentSearchParameter.skip = this._currentSearchParameter.skip + this._currentSearchParameter.take;
      this.search(this._currentSearchParameter);
    }
  }

  public changeSorting(column: GridColumn): void {
    if (this.allowGridAction() && column.sortable) {
      const sortDecretion = column.sort;

      this.configuration.columns.forEach(c => c.sort = SortDirection.none);

      switch (sortDecretion) {
        case SortDirection.none:
          column.sort = SortDirection.asc;
          break;
        case SortDirection.asc:
          column.sort = SortDirection.desc;
          break;
        case SortDirection.desc:
          column.sort = SortDirection.asc;
          break;
      }

      this._currentSearchParameter.sortingQueryProperties = [];
      this._currentSearchParameter.sortingQueryProperties.push({ propertyName: column.backendName, sortOrder: column.sort });
      this.search(null);
    }
  }

  public getVisibleColumnCount(): number {
    return this.configuration.columns.filter(c => c.visible).length;
  }

  public searchable(column: GridColumn, types: ColumnType[]): boolean {
    if (!column.searchable || column.disableInFilterBar) {
      return false;
    }

    return types.filter(t => t === column.type).length === 1;
  }

  public filterInput(event: KeyboardEvent): void {
    if (event.key === 'Enter' && this.allowGridAction()) {
      this.search(null);
    }
  }

  public filterDropDown(): void {
    if (this.allowGridAction()) {
      this.search(null);
    }
  }

  public openItem(): void {
    if (this.configuration.isEnabledDoubleClick && this.configuration.doubleClickAction && this.allowGridAction()) {
      this.configuration.doubleClickAction.emit(null);
    }
  }

  public selectItem(item: any): void {
    if (this.selectedItem !== item && this.allowGridAction()) {
      this.selectedItem = item;
      if (this.configuration.itemSelected) {
        this.configuration.itemSelected.emit(this.selectedItem);
      }
    }
  }

  public executeAction(event: MouseEvent, item: any, column: GridColumn): void {
    event.preventDefault();
    column.action(item);
  }

  public formatBooleanValue(column: GridColumn, value: boolean): string {
    const translation = this._translationService.translateWithoutFallback(this.configuration.translationGroup, `${this.getFieldName(column)}${value}`);

    return translation ? translation : (value ? this.labelYes : this.labelNo);
  }

  private reloadOfflineModeData(): void {
    if (!this.configuration || !this.configuration.isOfflineMode) {
      return;
    }

    this.configuration.gridDataStatus = GridDataStatus.dataLoaded;
    this.dataStatus = GridDataStatus.dataLoaded;
    this.data = [];
    this.progressSearchResponse(this.configuration.offlineData || []);
    this.progressSearchResponseFooter(this.configuration.offlineDataFooter || {});
  }

  private getFieldName(column: GridColumn): string {
    return column.backendName.charAt(0).toLowerCase() + column.backendName.slice(1);
  }

  private getColumnName(column: GridColumn): string {
    return this._translationService.translate(this.configuration.translationGroup, this.getFieldName(column));
  }

  private getItemValue(column: GridColumn, item: any): any {
    if (column.type === ColumnType.boolean) {
      return this.formatBooleanValue(column, item[column.name]);
    }

    if (column.type === ColumnType.number) {
      return this.formatNumberValue(column, item);
    }

    if (column.type === ColumnType.list) {
      return this.formatListItemValue(column, item);
    }

    if (column.type === ColumnType.datetime || column.type === ColumnType.date || column.type === ColumnType.time) {
      return this.formatDateTimeValue(column, item);
    }

    if (column.type === ColumnType.tag) {
      return this.formatTagValue(column, item);
    }

    return item[column.name];
  }

  private formatNumberValue(column: GridColumn, item: any): string {
    const decimalPlaces = column.decimalPlaces === 0 ? 0 : (column.decimalPlaces || 2);
    const numberValue = parseFloat(item[column.name]);

    if (isNaN(numberValue)) {
      return '';
    }

    return numberValue.toLocaleString(this._userCulture, { minimumFractionDigits: decimalPlaces, maximumFractionDigits: decimalPlaces });
  }

  private formatListItemValue(column: GridColumn, item: any): string {
    if (!column.list || !column.list.data) {
      return item[column.name];
    }

    const listItem = item[column.name] || item[column.name] === 0 ? column.list.data.find(i => i[column.list.key]?.toLocaleString() === item[column.name]?.toLocaleString()) : null;

    return listItem ? listItem[column.list.value] : item[column.name];
  }

  private formatDateTimeValue(column: GridColumn, item: any): string {
    let value: string = item[column.name];

    if (!value) {
      return null;
    }

    const counter = value.split('.').length;
    if (column.type === ColumnType.time && counter === 2) {
      const index = value.indexOf('.');
      const days = value.substring(0, index);
      const hour = value.substring(index + 1, index + 3);
      const rest = value.substring(index + 3, value.length);

      return `${parseInt(days, 10) * 24 + parseInt(hour, 10)}${rest}`;
    }

    let dataType: FieldDataType = FieldDataType.dateTime;

    switch (column.type) {
      case ColumnType.date:
        dataType = FieldDataType.date;
        break;
      case ColumnType.time:
        dataType = FieldDataType.time;
        if (value.indexOf('.') === -1) {
          const today = moment().startOf('day');
          const month = (((today.month() + 1) < 10) ? '0' : '') + (today.month() + 1).toString();
          const day = ((today.date() < 10) ? '0' : '') + today.date().toString();
          value = `${today.year()}-${month}-${day}T${value}`;
        }
        break;
    }

    const datetime = moment(value);

    return datetime.format(this._dateTimeLogicLayer.getDateTimeFormat(dataType, this._userCulture));
  }

  private formatTagValue(column: GridColumn, item: any): string {
    if (!item[column.name]) {
      return '';
    }

    const tags = item[column.name].split(',');
    let htmlTags = '<div class="tag-box">';

    for (const tag of tags) {
      if (tag) {
        htmlTags += '<div class="tag tag-red">' + tag + '</div>';
      }
    }
    htmlTags += '</div>';

    return htmlTags;
  }

  private initSearchParameter(): GridSearchParameter {
    return {
      resetGridStatus: false,
      isEnabledPagination: this.configuration.isEnabledPagination,
      searchTerm: this.configuration.searchTerm || '',
      take: this.configuration.paginationItemCount || 25,
      skip: 0,
      whereQueryProperties: [],
      sortingQueryProperties: this.configuration.columns.filter(c => c.sortable && c.sort !== SortDirection.none).map<GridColumnSort>((c, i, a) => ({ propertyName: c.backendName, sortOrder: c.sort }))
    };
  }

  private applySearchParameter(local: GridSearchParameter, remote: GridSearchParameter): void {
    if (remote) {

      if (remote.isEnabledPagination === true || remote.isEnabledPagination === false) {
        local.isEnabledPagination = remote.isEnabledPagination;
      }

      local.take = remote.take || local.take;

      if (remote.resetGridStatus) {
        this.data = [];
        local.skip = 0;
      }

      local.searchTerm = remote.searchTerm;
    } else {
      local.skip = 0;
      this.data = [];
    }

    local.whereQueryProperties = [];
    this.configuration.columns.forEach(column => {
      if (column.searchable && column.search.searchTerm) {
        local.whereQueryProperties.push({
          propertyName: column.backendName,
          searchTerm: column.search.searchTerm,
          operator: column.search.searchOperator || CompareOperator.Equals
        });
      }

      if (column.searchable && column.search.searchTermAdditional) {
        local.whereQueryProperties.push({
          propertyName: column.backendName,
          searchTerm: column.search.searchTermAdditional,
          operator: column.search.searchOperatorAdditional || CompareOperator.Equals
        });
      }
    });

    local.additionalSearchData = this.configuration.additionalSearchData;
  }

  private progressSearchResponse(response: any[]): boolean {
    if (this.dataStatus === GridDataStatus.cancelLoading) {
      this._noFurtherData = true;

      return true;
    }

    if (response && response.length > 0) {

      if (this.configuration.manipulateDataResult) {
        this.configuration.manipulateDataResult(response);
      }

      for (const dataRow of response) {
        for (const column of this.configuration.columns) {
          dataRow[column.gridName] = this.getItemValue(column, dataRow);
        }
      }

      this.data = (this.data || []).concat(response);
      this.dataStatus = GridDataStatus.dataLoaded;

      return true;
    }

    this.dataStatus = this.data.length === 0 ? GridDataStatus.hasNoItems : GridDataStatus.dataLoaded;
    this._noFurtherData = true;

    return false;
  }

  private progressSearchResponseFooter(response: any): void {
    if (response) {

      const labelMin = this._translationService.translate('Common', 'min');
      const labelMax = this._translationService.translate('Common', 'max');
      const labelAvg = this._translationService.translate('Common', 'avgHtml');
      const labelSum = this._translationService.translate('Common', 'sumHtml');

      for (const column of this.configuration.columns) {
        column.displayNameFooter = column.displayName;

        switch (column.footerRowAction) {
          case FooterRowAction.Count:
            column.displayNameFooter = '';
            response[column.gridName] = `${this._translationService.translate('Common', 'count')}: ${response.count}`;
            break;
          case FooterRowAction.Sum:
            if (column.type === ColumnType.number) {
              response[column.gridName] = labelSum + ' ' + this.formatNumberValue(column, response);
            } else if (column.type === ColumnType.time) {
              response[column.gridName] = labelSum + ' ' + this.formatDateTimeValue(column, response);
            }
            break;
          case FooterRowAction.Min:
            if (column.type === ColumnType.number) {
              response[column.gridName] = labelMin + ' ' + this.formatNumberValue(column, response);
            } else if (column.type === ColumnType.date || column.type === ColumnType.datetime || column.type === ColumnType.time) {
              response[column.gridName] = labelMin + ' ' + this.formatDateTimeValue(column, response);
            }
            break;
          case FooterRowAction.Max:
            if (column.type === ColumnType.number) {
              response[column.gridName] = labelMax + ' ' + this.formatNumberValue(column, response);
            } else if (column.type === ColumnType.date || column.type === ColumnType.datetime || column.type === ColumnType.time) {
              response[column.gridName] = labelMax + ' ' + this.formatDateTimeValue(column, response);
            }
            break;
          case FooterRowAction.Avg:
            if (column.type === ColumnType.number) {
              response[column.gridName] = labelAvg + ' ' + this.formatNumberValue(column, response);
            }
            break;
        }
      }

      this.footerRow = response;

    } else {
      this.footerRow = {};
    }
  }

  private search(parameter: GridSearchParameter): void {
    if (this.configuration.isOfflineMode) {
      return;
    }

    if (this.dataStatus === GridDataStatus.cancelLoading) {
      return;
    }

    this.dataStatus = GridDataStatus.isLoading;
    this._noFurtherData = false;

    if (!this._currentSearchParameter) {
      this._currentSearchParameter = this.initSearchParameter();
    }

    this.applySearchParameter(this._currentSearchParameter, parameter);

    this.searchWithSearchApiService(this._currentSearchParameter.skip);
  }

  private searchWithSearchApiService(intialSkip: number): void {
    this.configuration.itemSelected?.next(null);
    this.searchApiService.search(this._currentSearchParameter).subscribe(
      response => {
        this._gridControlbarService.notifySearchExecution(JSON.parse(JSON.stringify(this._currentSearchParameter)) as GridSearchParameter);

        if (response.isFaulty) {

          this.dataStatus = GridDataStatus.errorOccurred;
          if (this._currentSearchParameter.isEnabledPagination) {
            this._currentSearchParameter.skip = this._currentSearchParameter.skip > 0 ? this._currentSearchParameter.skip - this._currentSearchParameter.take : 0;
          }
        } else if (this.progressSearchResponse(response.data)) {

          this.checkLoadFurther();
        } else {

          this._gridControlbarService.broadcastDataUpdateFinished();
        }

        // Hack to get to the grid data
        this.configuration.offlineData = this.data;
      },
      _ => {
        this.dataStatus = GridDataStatus.errorOccurred;

        if (this._currentSearchParameter.isEnabledPagination) {
          this._currentSearchParameter.skip = this._currentSearchParameter.skip > 0 ? this._currentSearchParameter.skip - this._currentSearchParameter.take : 0;
        }
      });

    if (this.configuration.showFooterRow && intialSkip === 0) {
      this.searchApiService.searchSum(this._currentSearchParameter).subscribe(
        response => {
          if (!response.isFaulty) {
            this.progressSearchResponseFooter(response.data);
          }
        });
    }
  }

  private checkLoadFurther(): void {
    if (!this._currentSearchParameter.isEnabledPagination) {
      return;
    }

    setTimeout(() => {

      let clientHeight: number = this.gridContainer.nativeElement.clientHeight;
      if (this.configuration.scrollGridBody) {
        clientHeight = this.gridContainer.nativeElement.querySelector('tbody').scrollHeight;
      }

      if (window.innerHeight >= clientHeight) {
        this.loadFurtherData();
      }
    }, 100);
  }

  private loadGridLayout(): void {
    if (!this.configuration.gridLayoutName) {
      return;
    }

    this._gridLayoutApiService.getGridLayout(this.configuration.gridLayoutName).subscribe(layout => {

      if (layout) {

        this._gridLayoutId = layout.id;
        this.configuration.isEnabledDoubleClick = layout.isEnabledDoubleClick;
        this.configuration.isEnabledPagination = layout.isEnabledPagination;
        this.configuration.paginationItemCount = layout.paginationItemCount <= 0 ? null : layout.paginationItemCount;
        this.configuration.saveFilter = layout.saveFilter;
        this.configuration.isVisibleColumnFilter = layout.isVisibleColumnFilter;
        this.configuration.searchTerm = layout.searchTerm;

        if (layout.columns) {
          for (const layoutColumn of layout.columns) {
            const column = this.configuration.columns.find(c => c.backendName === layoutColumn.backendName);

            if (column) {
              column.visible = layoutColumn.visible;
              column.sort = layoutColumn.sort;
              if (this.configuration.saveFilter) {
                column.search.searchTerm = column.searchable ? layoutColumn.searchTerm : null;
                column.search.searchTermAdditional = column.searchable ? layoutColumn.searchTermAdditional : null;
                column.search.searchOperator = column.searchable ? layoutColumn.searchOperator : null;
                column.search.searchOperatorAdditional = column.searchable ? layoutColumn.searchOperatorAdditional : null;
              }
            }
          }
        }
        this.calculateGridColumSize();
      }

      if (!this.configuration.isGridDisabled) {
        this._gridControlbarService.configureControlBar(this.configuration);
      }
    });
  }

  private saveGridLayout(): void {

    if (!this._currentSearchParameter || !this.configuration.gridLayoutName) {
      return;
    }

    const layout: GridLayout = {
      id: this._gridLayoutId,
      layoutName: this.configuration.gridLayoutName,
      isEnabledDoubleClick: this.configuration.isEnabledDoubleClick,
      isVisibleColumnFilter: this.configuration.isVisibleColumnFilter,
      saveFilter: this.configuration.saveFilter,
      isEnabledPagination: this._currentSearchParameter.isEnabledPagination,
      paginationItemCount: this._currentSearchParameter.take,
      searchTerm: this.configuration.saveFilter ? this._currentSearchParameter.searchTerm : null,
      columns: []
    };

    for (const column of this.configuration.columns) {
      layout.columns.push({
        backendName: column.backendName,
        searchTerm: this.configuration.saveFilter ? column.search.searchTerm : '',
        searchTermAdditional: this.configuration.saveFilter ? column.search.searchTermAdditional : '',
        searchOperator: this.configuration.saveFilter ? column.search.searchOperator : CompareOperator.None,
        searchOperatorAdditional: this.configuration.saveFilter ? column.search.searchOperatorAdditional : CompareOperator.None,
        visible: column.visible,
        sort: column.sortable ? column.sort : SortDirection.none
      });
    }

    this._gridLayoutApiService.upsertGridLayout(layout).subscribe(_ => { });
  }

  private allowGridAction(): boolean {
    return this.configuration && !this.configuration.isGridDisabled && this._isControlbarVisible;
  }

  private calculateGridColumSize(): void {
    if (!this.configuration.scrollGridBody) {
      return;
    }

    setTimeout(() => {
      const columns: any[] = this.gridContainer.nativeElement.querySelectorAll('tr.header>th');
      columns.forEach(column => {
        for (let index = 0; index < this.configuration.columns.length; index++) {
          const colId = 'grid-col-' + index;

          if (column.className.endsWith(colId)) {
            this.configuration.columns[index].colWidth = this.gridContainer.nativeElement.clientWidth > 991 ? column.offsetWidth : undefined;
          }
        }

      });
    }, 50);
  }

  private determineRow(rightClick: MouseEvent): any {
    const paths = rightClick.composedPath();
    let dataRowIndex = -1;
    const regexp = new RegExp('(grid-row-)(?<index>\\d+)(-index)');

    for (const path of paths) {
      const element = path as Element;
      if (!element.classList) {
        continue;
      }

      for (let index = 0; index < element.classList.length; index++) {
        if (regexp.test(element.classList[index])) {

          const match = regexp.exec(element.classList[index]);
          dataRowIndex = parseInt(match['groups'].index ?? '-1', 10);

          break;
        }
      }

      if (dataRowIndex >= 0) {
        break;
      }
    }

    if (dataRowIndex >= 0) {
      return this.data[dataRowIndex];
    }

    return null;
  }
}

<div class="d-flex"
     *ngIf="showGridButtons">
  <ng-container *ngFor="let item of gridButtons; index as i">
    <button *ngIf="item.visible"
            type="button"
            class="btn btn-outline-secondary"
            [class.ms-3]="i > firstVisibleGridButtonIndex"
            [class.ms-auto]="i === firstVisibleGridButtonIndex"
            title="{{ item.tooltip | translation: translationGroup }}"
            (click)="item.action.emit(null)">
      <fa-icon [icon]="item.icon"></fa-icon> {{ item.label | translation: translationGroup }} </button>
  </ng-container>
</div>
<ng-container *ngIf="editorConfiguration">
  <div class="d-flex"
       *ngIf="showEditorButtons">
    <button *ngIf="editorConfiguration.showDeleteButton"
            type="button"
            class="btn btn-outline-danger me-auto"
            (click)="deleteItem()"
            [disabled]="!editorConfiguration.showDeleteButton || !enableDeleteButton || !enableEditorButtons">
      <fa-icon [icon]="icons.delete"
               [fixedWidth]="true"></fa-icon> {{ 'delete' | translation: 'common' }} </button>
    <div class="ms-auto"></div>
    <ng-container *ngFor="let item of editorButtons">
      <button *ngIf="item.visible"
              type="button"
              class="btn btn-outline-secondary ms-3"
              title="{{ item.tooltip | translation: translationGroup }}"
              (click)="item.action.emit(null)">
        <fa-icon *ngIf="item.icon"
                 [icon]="item.icon"></fa-icon> {{ item.label | translation: translationGroup }} </button>
    </ng-container>
    <button *ngIf="editorConfiguration.showSaveButton"
            type="button"
            class="btn btn-outline-primary ms-3"
            (click)="saveItem()"
            [disabled]="!editorConfiguration.showSaveButton || !enableEditorButtons || !enableSaveButton">
      <fa-icon [icon]="icons.save"
               [fixedWidth]="true"></fa-icon> {{ 'save' | translation: 'common' }} </button>
    <button *ngIf="editorConfiguration.showCancelButton"
            type="button"
            class="btn btn-outline-secondary ms-3"
            (click)="cancelItem()"
            [disabled]="!editorConfiguration.showCancelButton || !enableEditorButtons">
      <fa-icon [icon]="icons.cancel"
               [fixedWidth]="true"></fa-icon> {{ 'cancel' | translation: 'common' }} </button>
  </div>
</ng-container>
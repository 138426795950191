<label *ngIf="!hideLabel">{{ label }}</label>
<div class="input-group">
  <span class="input-group-text"
        *ngIf="prependIcon">
    <fa-icon [icon]="prependIcon"
             [fixedWidth]="true"></fa-icon>
  </span>
  <input type="text"
         class="form-control"
         [placeholder]="placeholder"
         [ngClass]="{'is-invalid': maskValue.invalid && (maskValue.dirty || maskValue.touched)}"
         name="{{ name }}"
         [mask]="mask"
         [ngModel]="value"
         (ngModelChange)="onValueChange($event)"
         [required]="isRequired"
         #maskValue="ngModel"
         [disabled]="isDisabled">
  <span class="input-group-text"
        *ngIf="appendIcon">
    <fa-icon [icon]="appendIcon"
             [fixedWidth]="true"></fa-icon>
  </span>
</div>
<div class="invalid-feedback d-block"
     *ngIf="!hideErrors && maskValue.invalid && (maskValue.dirty || maskValue.touched)">
  <div *ngFor="let maskValueError of maskValue.errors | keyvalue"> {{ maskValueError.key | translation:'errors' }} </div>
</div>

import { Injectable } from '@angular/core';

import { UserProfile } from '../interfaces';
import { AppSettings } from '../models';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() { }

  public get userProfile(): UserProfile {
    const userProfileJson = localStorage.getItem(AppSettings.settings.userProfile);
    let userProfile: UserProfile;

    if (!userProfileJson) {
      userProfile = { id: null, culture: 'de-DE' };
    } else {
      userProfile = JSON.parse(userProfileJson) as UserProfile;
    }

    return userProfile;
  }

  public set userProfile(userProfile: UserProfile) {
    localStorage.setItem(AppSettings.settings.userProfile, JSON.stringify(userProfile));
  }

  public setItem(key: string, value: unknown): void {
    if (!value) {
      localStorage.setItem(key, '');
    } else {
      localStorage.setItem(key, JSON.stringify(value));
    }
  }

  public getItem(key: string): any {
    const value = localStorage.getItem(key);
    if (!value) {
      return null;
    }

    return JSON.parse(value);
  }

  public removeItem(key: string): void {
    localStorage.removeItem(key);
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { PictureViewerOverlayService } from '../../../core/services';
import { icons } from '../../helper';

@Component({
  selector: 'app-picture-viewer-overlay',
  templateUrl: './picture-viewer-overlay.component.html',
  styleUrls: ['./picture-viewer-overlay.component.scss']
})
export class PictureViewerOverlayComponent implements OnInit, OnDestroy {

  private _subscriptions: Subscription = new Subscription();

  public isOpen = false;
  public fullSize = false;
  public imageData: string;
  public icons = icons;

  constructor(
    private _pictureViewerOverlayService: PictureViewerOverlayService
  ) { }

  public ngOnInit(): void {
    this._pictureViewerOverlayService.overlayRequested$.subscribe(
      imageData => {
        this.imageData = imageData;
        this.isOpen = true;
        this.fullSize = false;
      });
  }

  public ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  public closeOverlay(): void {
    this.imageData = null;
    this.isOpen = false;
  }
}

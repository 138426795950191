<label *ngIf="!hideLabel">{{ label }}</label>
<div class="input-group">
  <span class="input-group-text"
        *ngIf="prependIcon">
    <fa-icon [icon]="prependIcon"
             [fixedWidth]="true"></fa-icon>
  </span>
  <select class="form-control {{cssClass}}"
          [ngClass]="{'is-invalid': selectValue.invalid && (selectValue.dirty || selectValue.touched)}"
          name="{{ name }}"
          [ngModel]="value"
          (ngModelChange)="onValueChange($event)"
          [required]="isRequired"
          #selectValue="ngModel"
          [disabled]="isDisabled">
    <option *ngIf="!hideEmptyOption"
            [ngValue]="null"></option>
    <option [ngValue]="item.value"
            *ngFor="let item of values">{{ item.text }}</option>
  </select>
  <span class="input-group-text"
        *ngIf="appendIcon">
    <fa-icon [icon]="appendIcon"
             [fixedWidth]="true"></fa-icon>
  </span>
</div>
<div class="invalid-feedback d-block"
     *ngIf="!hideErrors && selectValue.invalid && (selectValue.dirty || selectValue.touched)">
  <div *ngFor="let selectValueError of selectValue.errors | keyvalue"> {{ selectValueError.key | translation:'errors' }} </div>
</div>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { PluginType } from '../../../shared/enums';
import { GridSearchParameter, PluginSettings, ResponseDataObject, SearchApiService } from '../../../shared/interfaces';
import { AppSettings } from '../../../shared/models';

@Injectable({
  providedIn: 'root'
})
export class PluginApiService implements SearchApiService<PluginSettings> {

  private _baseUri = AppSettings.endpoints.application.plugin;

  constructor(
    private _http: HttpClient
  ) { }

  public search(parameter: GridSearchParameter): Observable<ResponseDataObject<PluginSettings[]>> {
    return this._http.post<ResponseDataObject<PluginSettings[]>>(this._baseUri + '/search', parameter);
  }

  public searchSum(parameter: GridSearchParameter): Observable<ResponseDataObject<PluginSettings>> {
    return this._http.post<ResponseDataObject<PluginSettings>>(this._baseUri + '/search/sum', parameter);
  }

  public getById(itemId: number, type: PluginType): Observable<ResponseDataObject<PluginSettings>> {
    return this._http.get<ResponseDataObject<PluginSettings>>(this._baseUri + this.getRoutePartForPluginType(type) + (!!itemId ? itemId : 'newinstance'));
  }

  public getIsUsed(itemId: number): Observable<ResponseDataObject<boolean>> {
    return this._http.get<ResponseDataObject<boolean>>(this._baseUri + '/isUsed/' + itemId);
  }

  public createItem(item: PluginSettings): Observable<ResponseDataObject<PluginSettings>> {
    return this._http.post<ResponseDataObject<PluginSettings>>(this._baseUri + this.getRoutePartForPluginType(item.type), item);
  }

  public updateItem(item: PluginSettings): Observable<ResponseDataObject<PluginSettings>> {
    return this._http.put<ResponseDataObject<PluginSettings>>(this._baseUri + this.getRoutePartForPluginType(item.type), item);
  }

  public deleteItem(itemId: number): Observable<ResponseDataObject<boolean>> {
    return this._http.delete<ResponseDataObject<boolean>>(this._baseUri + '/' + itemId);
  }

  private getRoutePartForPluginType(type: PluginType): string {
    switch (type) {
      case PluginType.WebFleet:
        return '/webfleet/';
      default:
        return '/';
    }
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Guid } from 'guid-typescript';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { AuthService, CommonSettingsApiService, LayoutService, MenuService, NavigationService, NotificationService, RoleApiService, TranslationService } from '../../../core/services';
import { errorMessageConstants } from '../../constants';
import { AuthenticationState } from '../../enums';
import { icons } from '../../helper';
import { DropDownItem, MenuItem, NavigationLink } from '../../interfaces';
import { AppSettings } from '../../models';
import { LocalStorageService } from '../../services';

@Component({
  selector: 'app-app-shell',
  templateUrl: './app-shell.component.html',
  styleUrls: ['./app-shell.component.scss']
})
export class AppShellComponent implements OnInit, OnDestroy {
  private _subscriptions: Subscription = new Subscription();
  private _searchSubject = new Subject<string>();
  private _modalRef: NgbModalRef;

  public icons = icons;
  public appSettings = AppSettings;

  public links: NavigationLink[] = [];
  public menuItems: MenuItem[] = [];
  public administrationArea = 'AdministrationArea';
  public logo = '';
  public authenticationState = AuthenticationState;
  public viewData = {
    isSideBarVisible: false,
    collapsed: false,
    username: '',
    tentantName: '',
    authenticationState: AuthenticationState.NotAuthenticated
  };

  public search: string;
  public search$ = this._searchSubject.asObservable();
  public currentPermissions: DropDownItem[] = [];
  public filteredPermissions: DropDownItem[] = [];
  public isGlobalAdministrator = false;

  constructor(
    private _localStorageService: LocalStorageService,
    private _navigationService: NavigationService,
    private _menuService: MenuService,
    private _titleService: Title,
    private _authService: AuthService,
    private _layoutService: LayoutService,
    private _roleApiService: RoleApiService,
    private _commonSettingsApiService: CommonSettingsApiService,
    private _notificationService: NotificationService,
    private _translationService: TranslationService,
    private _modalService: NgbModal,
    private _router: Router
  ) {
    this.viewData.collapsed = JSON.parse(this._localStorageService.getItem('navcollapse') || 'false');

    this._authService.authenticated$.subscribe(
      val => setTimeout(() => {
        this.viewData.authenticationState = val.authenticationState;
        this.viewData.tentantName = this._authService.getTenantName();
        this.viewData.username = this._authService.getUsername();
        this.isGlobalAdministrator = this._authService.hasPermission('globaladministrator.read');
        this.setLogo();
      }, 10)
    );

    this._layoutService.showSideBar$.subscribe(
      val => setTimeout(() => this.viewData.isSideBarVisible = val, 10)
    );

    this.viewData.isSideBarVisible = this._layoutService.getSideBarState();
    this.viewData.authenticationState = this._authService.getAuthenticationState();
    this.viewData.tentantName = this._authService.getTenantName();
    this.viewData.username = this._authService.getUsername();
    this.isGlobalAdministrator = this._authService.hasPermission('globaladministrator.read');

    this._subscriptions.add(
      this._router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.formatAndSetPageTitle(event as NavigationEnd);
        }
      })
    );
  }

  public ngOnInit(): void {
    this._subscriptions.add(
      this._navigationService.linksChanged$.subscribe(links => {
        setTimeout(() => {
          this.links = links;
        }, 10);
      })
    );

    this._subscriptions.add(
      this._menuService.menuItemsChanged$.subscribe(
        menuItems => {
          this.menuItems = menuItems;
        })
    );

    this._subscriptions.add(
      this.search$.pipe(
        distinctUntilChanged(),
        debounceTime(300)
      ).subscribe(
        filter => this.filterPermission(filter)
      )
    );

    this.menuItems = this._menuService.menuItems || [];
    this.links = this._navigationService.links || [];
    this.setLogo();
  }

  public ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  public switchCollapse(): void {
    this.viewData.collapsed = !this.viewData.collapsed;
    this._localStorageService.setItem('navcollapse', this.viewData.collapsed);
  }

  public unauthenticate(): void {
    this._layoutService.showSideBar(false);
    this._authService.unauthenticate();
  }

  public reAuthenticate(): void {
    this._authService.reAuthenticate();
  }

  public onSearchChanged(value: string): void {
    this._searchSubject.next(value);
  }

  public onPermissionCategoryChanged(value: boolean, data: DropDownItem): void {
    (data.value as DropDownItem[]).forEach(p => p.groupId = value);
  }

  public onPermissionChanged(value: boolean, data: DropDownItem): void {
    data.groupId = value;
  }

  public isActiveGroup(data: DropDownItem): boolean {
    return (data.value as DropDownItem[]).some(p => p.groupId);
  }

  public setAll(): void {
    this.currentPermissions.forEach(category => {
      (category.value as DropDownItem[]).forEach(permission => permission.groupId = true);
    });
  }

  public setNone(): void {
    this.currentPermissions.forEach(category => {
      (category.value as DropDownItem[]).forEach(permission => permission.groupId = false);
    });

  }

  public showPermissionDebug(content: any): void {
    if (this._modalRef) {
      return;
    }

    if (this.currentPermissions.length > 0) {
      this.filteredPermissions = this.currentPermissions;
      this._modalRef = this._modalService.open(content, { size: 'xl', centered: true, backdrop: 'static', keyboard: false });

      return;
    }

    const permissions: DropDownItem[] = [];
    this._roleApiService.getAllPermissions().subscribe(
      response => {
        if (response.isFaulty) {
          this._notificationService.showErrorToast(
            this._translationService.translate('role', 'allPermissions'),
            this._translationService.translate('errors', response.message)
          );
        } else {
          response.data.forEach(category => {
            const categoryPermissions: DropDownItem[] = [];

            category.value.forEach(permission => {
              categoryPermissions.push({
                text: permission,
                value: permission,
                groupId: this._authService.hasPermission(permission)
              });
            });

            permissions.push({
              text: category.key,
              value: categoryPermissions,
              groupId: categoryPermissions.length
            });
          });

          this.currentPermissions = permissions;
          this.filteredPermissions = this.currentPermissions;
          this._modalRef = this._modalService.open(content, { size: 'xl', centered: true, backdrop: 'static', keyboard: false });
        }
      },
      _ => {
        this._notificationService.showErrorToast(
          this._translationService.translate('role', 'allPermissions'),
          this._translationService.translate('errors', errorMessageConstants.errorReadData)
        );
      });
  }

  public closeModal(): void {
    if (this._modalRef) {
      this._modalRef.close();
      this._modalRef = null;
    }
    this.search = '';
    this.onSearchChanged('');
    this.setPermissions();
  }


  private setLogo(): void {
    if (this.viewData.authenticationState !== AuthenticationState.Authenticated || this.isGlobalAdministrator) {
      this.logo = null;

      return;
    }

    this._commonSettingsApiService.getLogo().subscribe(
      response => {
        if (response.isFaulty) {
          this._notificationService.showErrorToast(
            this._translationService.translate('errors', response.message),
            this._translationService.translate('common', 'logo')
          );
        } else {
          this.logo = response.data;
        }
      },
      _ => {
        this._notificationService.showErrorToast(
          this._translationService.translate('errors', errorMessageConstants.unexpectedErrorOccurred),
          this._translationService.translate('common', 'logo')
        );
      });
  }

  private formatAndSetPageTitle(event: NavigationEnd): void {
    const url = event.urlAfterRedirects || event.url;
    const urlParts = url.split('/').filter(part => !!part);

    if (urlParts.length === 0) {
      this._titleService.setTitle(AppSettings.appName);

      return;
    }

    let pageTitle = '';
    for (let index = 0; index < urlParts.length; index++) {
      if (!Guid.isGuid(urlParts[index])) {

        const urlPartNames = urlParts[index].split('-');
        let urlPartName = urlPartNames[0];
        for (let partIndex = 1; partIndex < urlPartNames.length; partIndex++) {
          urlPartName += (urlPartNames[partIndex][0].toUpperCase() + urlPartNames[partIndex].substring(1));
        }

        if (urlPartName === 'assistant') {
          continue;
        }

        let name = this._translationService.translate('navigation', urlPartName);
        if (!name || name.indexOf('missing') > -1) {
          name = this._translationService.translate('common', urlPartName);
        }

        if (!!name && name.indexOf('missing') === -1) {
          pageTitle += ` | ${name}`;
        }
      }
    }

    pageTitle = `${pageTitle.substring(3)} - ${AppSettings.appName}`;
    this._titleService.setTitle(pageTitle);
  }

  private filterPermission(filter: string): void {
    if (!filter) {
      this.filteredPermissions = this.currentPermissions;
    }

    const lowerFilter = filter.toLowerCase();
    const permissionCategories: DropDownItem[] = [];
    this.currentPermissions.forEach((pc) => {
      const categoryPermissions = pc.value as DropDownItem[];
      const data = categoryPermissions.filter(p => p.text.toLowerCase().indexOf(lowerFilter) >= 0);
      if (data.length > 0) {
        permissionCategories.push({
          text: pc.text,
          value: data,
          groupId: categoryPermissions.length
        });
      }
    });
    this.filteredPermissions = permissionCategories;
  }

  private setPermissions(): void {
    const permissions: string[] = [];
    this.currentPermissions.forEach(category => {
      permissions.push(... (category.value as DropDownItem[]).filter(p => !!p.groupId).map(p => p.value));
    });

    this._authService.setDebugPermissions(permissions);
    const menu = this.menuItems;
    this.menuItems = [];
    setTimeout(() => {
      this.menuItems = menu;
    }, 10);
  }
}

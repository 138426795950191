import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ContextMenuService } from '../../../core/services';
import { ContextMenuConfiguration, ContextMenuItem, ContextMenuRequest } from '../../models';


@Component({
  selector: 'app-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss']
})
export class ContextMenuComponent implements OnInit, OnDestroy {
  private _subscriptions: Subscription = new Subscription();
  private _configurations: ContextMenuConfiguration[] = [];

  public items: ContextMenuItem[];
  public xPosition: number;
  public yPosition: number;

  constructor(
    private _contextMenuService: ContextMenuService
  ) { }

  public ngOnInit(): void {
    this._subscriptions.add(
      this._contextMenuService.contextMenuRegistered$.subscribe(configuration => {
        this.removeRegistration(configuration.uniqueName);
        this._configurations.push(configuration);
      })
    );

    this._subscriptions.add(
      this._contextMenuService.contextMenuRemoved$.subscribe(uniqueName => this.removeRegistration(uniqueName))
    );

    this._subscriptions.add(
      this._contextMenuService.contextMenuRequested$.subscribe(request => this.openContextMenu(request))
    );
  }

  public ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  @HostListener('document:click', ['$event'])
  public onLeftClick(_: PointerEvent): void {
    if (!this.items) {
      return;
    }

    this.items = null;
  }

  public onClickedItem(item: ContextMenuItem): void {
    if (item.isDisabled) {
      return;
    }

    item.clicked.next(null);
  }

  private removeRegistration(uniqueName: string): void {
    const index = this._configurations.findIndex(c => c.uniqueName === uniqueName);
    if (index < 0) {
      return;
    }

    this._configurations.splice(index, 1);
  }

  private openContextMenu(request: ContextMenuRequest): void {
    this.items = null;

    const configuration = this._configurations.find(c => c.uniqueName === request.uniqueName);
    if (!configuration) {
      return;
    }

    this.xPosition = request.xPosition;
    this.yPosition = request.yPosition;

    const items: ContextMenuItem[] = [];
    for (const item of configuration.items) {
      if (item.isHidden) {
        continue;
      }

      if (item.isSeparator && items.length === 0) {
        continue;
      }

      if (item.isSeparator && items.length > 0 && items[items.length - 1].isSeparator) {
        continue;
      }

      items.push(item);
    }

    if (items.length === 0) {
      return;
    }

    if (items[items.length - 1].isSeparator) {
      items.splice(items.length - 1, 1);
    }

    const height = items.length * 37;

    if (this.yPosition + height > document.body.clientHeight) {
      this.yPosition -= height;
    }

    if (this.xPosition + 252 > document.body.clientWidth) {
      this.xPosition -= 252;
    }

    this.items = items;
  }
}

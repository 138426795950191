<label *ngIf="!hideLabel">{{ label }}</label>
<div class="input-group">
  <span class="input-group-text"
        *ngIf="prependIcon">
    <fa-icon [icon]="prependIcon"
             [fixedWidth]="true"></fa-icon>
  </span>
  <input [type]="isPassword ? 'password' : 'text'"
         class="form-control"
         [placeholder]="placeholder"
         [ngClass]="{'is-invalid': textValue.invalid && (textValue.dirty || textValue.touched)}"
         name="{{ name }}"
         [ngModel]="value"
         (ngModelChange)="onValueChange($event)"
         [required]="isRequired"
         #textValue="ngModel"
         [maxlength]="maxlength"
         [disabled]="isDisabled"
         [readOnly]="isReadonly"
         [customValidator]="customValidator">
  <span class="input-group-text"
        *ngIf="appendIcon || appendIconText">
    <fa-icon *ngIf="appendIcon"
             [icon]="appendIcon"
             [fixedWidth]="true"></fa-icon> {{ appendIconText }}
  </span>
</div>
<div class="invalid-feedback d-block"
     *ngIf="!hideErrors && textValue.invalid && (textValue.dirty || textValue.touched)">
  <div *ngFor="let textValueError of textValue.errors | keyvalue"> {{ textValueError.key | translation:'errors' }} </div>
</div>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApplicationUserTenantAssignment, GridSearchParameter, ResponseDataObject } from '../../../shared/interfaces';
import { AppSettings } from '../../../shared/models';

@Injectable({
  providedIn: 'root'
})
export class UserTenantAsignmentApiService {

  constructor(
    private _http: HttpClient
  ) { }

  public search(gridSearchParameter: GridSearchParameter): Observable<ResponseDataObject<ApplicationUserTenantAssignment[]>> {
    return this._http.post<ResponseDataObject<ApplicationUserTenantAssignment[]>>(AppSettings.endpoints.multitenancy.applicationUserTenantAssignment + '/search', gridSearchParameter);
  }

  public getUserTenantAssignmentById(id: number): Observable<ResponseDataObject<ApplicationUserTenantAssignment>> {
    return this._http.get<ResponseDataObject<ApplicationUserTenantAssignment>>(AppSettings.endpoints.multitenancy.applicationUserTenantAssignment + '/' + id);
  }

  public updateApplicationUserTenantAssignment(applicationUserTenantAssignment: ApplicationUserTenantAssignment): Observable<ResponseDataObject<ApplicationUserTenantAssignment>> {
    return this._http.put<ResponseDataObject<ApplicationUserTenantAssignment>>(AppSettings.endpoints.multitenancy.applicationUserTenantAssignment, applicationUserTenantAssignment);
  }
}

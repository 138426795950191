import { DropDownItem, NumberMaskConfig } from '../interfaces';

export class LocaleConstants {

  public static DateFormats: DropDownItem[] = [
    { value: 'de-DE', text: 'DD.MM.YYYY' },
    { value: 'en-GB', text: 'DD/MM/YYYY' }
  ];

  public static TimeFormats: DropDownItem[] = [
    { value: 'de-DE', text: 'HH:mm:ss' },
    { value: 'en-GB', text: 'HH:mm:ss' }
  ];

  public static Integerformat: DropDownItem[] = [
    { value: 'de-DE', text: '^\\d+$' },
    { value: 'en-GB', text: '^\\d+$' }
  ];

  public static DecimalOneFormat: DropDownItem[] = [
    { value: 'de-DE', text: '^(?!0+\\.00)(?=.{1,9}(\\.|$))(?!0(?!\\.))\\d{1,3}(\\.\\d{3})*(,\\d{1})$' },
    { value: 'en-GB', text: '^(?!0+\\.00)(?=.{1,9}(\\.|$))(?!0(?!\\.))\\d{1,3}(,\\d{3})*(\\.\\d{1})$' }
  ];

  public static DecimalTwoFormat: DropDownItem[] = [
    { value: 'de-DE', text: '^(?!0+\\.00)(?=.{1,9}(\\.|$))(?!0(?!\\.))\\d{1,3}(\\.\\d{3})*(,\\d{2})$' },
    { value: 'en-GB', text: '^(?!0+\\.00)(?=.{1,9}(\\.|$))(?!0(?!\\.))\\d{1,3}(,\\d{3})*(\\.\\d{2})$' }
  ];

  public static DecimalThreeFormat: DropDownItem[] = [
    { value: 'de-DE', text: '^(?!0+\\.00)(?=.{1,9}(\\.|$))(?!0(?!\\.))\\d{1,3}(\\.\\d{3})*(,\\d{3})$' },
    { value: 'en-GB', text: '^(?!0+\\.00)(?=.{1,9}(\\.|$))(?!0(?!\\.))\\d{1,3}(,\\d{3})*(\\.\\d{3})$' }
  ];

  public static CurrencyFormat: DropDownItem[] = [
    { value: 'de-DE', text: '^(?!0+\\.00)(?=.{1,9}(\\.|$))(?!0(?!\\.))\\d{1,3}(\\.\\d{3})*(,\\d{2}) €$' },
    { value: 'en-GB', text: '^£ (?!0+\\.00)(?=.{1,9}(\\.|$))(?!0(?!\\.))\\d{1,3}(,\\d{3})*(\\.\\d{2})$' }
  ];

  public static numberMaskConfig: NumberMaskConfig[] = [
    { culture: 'de-DE', align: 'right', allowNegative: true, decimal: ',', precision: 2, prefix: '', suffix: ' €', thousands: '.' },
    { culture: 'en-GB', align: 'right', allowNegative: true, decimal: '.', precision: 2, prefix: '£ ', suffix: '', thousands: ',' }
  ];
}

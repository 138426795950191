<div class="header row"
     *ngIf="enableControlBar">
  <div class="col-12 input-group"
       *ngIf="showSearch">
    <span class="input-group-text clickable"
          (click)="search()">
      <fa-icon [icon]="icons.filter"></fa-icon>
    </span>
    <input type="text"
           class="form-control"
           [(ngModel)]="filter"
           [disabled]="!enableControlBar"
           (keydown)="search($event)"
           [placeholder]="'search' | translation: 'common'">
    <button class="btn btn-outline-secondary"
            [disabled]="!enableControlBar"
            (click)="clearSearch()">
      <fa-icon [icon]="icons.clear"></fa-icon>
    </button>
    <button type="button"
            class="btn btn-outline-secondary"
            [disabled]="!enableControlBar"
            (click)="toggleFilter()"
            title="{{ 'filter' | translation: 'common' }}">
      <fa-icon [icon]="icons.filter"></fa-icon>
    </button>
    <button type="button"
            class="btn btn-outline-secondary"
            [disabled]="isRefreshing || !enableControlBar"
            (click)="refresh()"
            title="{{ 'refresh' | translation: 'common' }}">
      <fa-icon [icon]="icons.refresh"
               [spin]="isRefreshing"></fa-icon>
    </button>
    <button type="button"
            class="btn btn-outline-secondary"
            [disabled]="isResetting || !enableControlBar"
            (click)="reset()"
            title="{{ 'reset' | translation: 'common' }}">
      <fa-icon [icon]="icons.reset"
               [classes]="['spin-reverse']"
               [spin]="isResetting"></fa-icon>
    </button>
  </div>
</div>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { PlatformType } from '../../../../shared/interfaces';
import { AppSettings } from '../../../../shared/models';
import { AbstractBasicDataApiService } from '../../abstract-basic-data-api.service';

@Injectable({
  providedIn: 'root'
})
export class PlatformTypeApiService extends AbstractBasicDataApiService<PlatformType> {

  constructor(
    protected http: HttpClient
  ) {
    super(http, AppSettings.endpoints.basicinformation.vehicles.platformType);
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ResponseDataObject, ToolsAndMaterialsItem } from '../../../shared/interfaces';
import { AppSettings } from '../../../shared/models';
import { AbstractBasicDataApiService } from '../abstract-basic-data-api.service';

@Injectable({
  providedIn: 'root'
})
export class ToolsAndMaterialsItemApiService extends AbstractBasicDataApiService<ToolsAndMaterialsItem> {

  constructor(
    protected http: HttpClient
  ) {
    super(http, AppSettings.endpoints.basicinformation.toolsAndMaterials.item);
  }

  public existsInventoryNumber(inventoryNumber: string, itemId: number): Observable<ResponseDataObject<boolean>> {
    return this.http.get<ResponseDataObject<boolean>>(`${this.baseUri}/exists/${inventoryNumber}?itemId=${itemId}`);
  }
}

<label *ngIf="!hideLabel">{{ label }}</label>
<div class="input-group">
  <span class="input-group-text"
        *ngIf="prependIcon">
    <fa-icon [icon]="prependIcon"
             [fixedWidth]="true"></fa-icon>
  </span>
  <textarea class="form-control"
            [rows]="rows"
            [placeholder]="placeholder"
            [ngClass]="{'is-invalid': textValue.invalid && (textValue.dirty || textValue.touched)}"
            name="{{ name }}"
            [ngModel]="value"
            (ngModelChange)="onValueChange($event)"
            [required]="isRequired"
            #textValue="ngModel"
            [disabled]="isDisabled"
            [readOnly]="isReadonly"></textarea>
  <span class="input-group-text"
        *ngIf="appendIcon">
    <fa-icon [icon]="appendIcon"
             [fixedWidth]="true"></fa-icon>
  </span>
</div>
<div class="invalid-feedback d-block"
     *ngIf="!hideErrors && textValue.invalid && (textValue.dirty || textValue.touched)">
  <div *ngFor="let textValueError of textValue.errors | keyvalue"> {{ textValueError.key | translation:'errors' }} </div>
</div>

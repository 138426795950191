import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { NotificationButtonKind, NotificationDialogKind, NotificationToastKind } from '../../shared/enums';
import { icons } from '../../shared/helper/icon-helper';
import { NotificationDialogData, NotificationToastData } from '../../shared/interfaces';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private _dialogSubject = new Subject<NotificationDialogData>();
  private _toastSubject = new Subject<NotificationToastData>();

  public dialog$ = this._dialogSubject.asObservable();
  public toast$ = this._toastSubject.asObservable();

  constructor() { }

  public showInfoDialog(title: string, message: string): void {
    this.showDialog({
      title: `Info: ${title}`,
      kind: NotificationDialogKind.Info,
      message: message,
      buttons: [
        { caption: 'Ok', kind: NotificationButtonKind.Info }
      ]
    });
  }

  public showWarningDialog(title: string, message: string): void {
    this.showDialog({
      title: `Warning: ${title}`,
      kind: NotificationDialogKind.Warning,
      message: message,
      buttons: [
        { caption: 'Ok', kind: NotificationButtonKind.Primary }
      ]
    });
  }

  public showErrorDialog(title: string, message: string): void {
    this.showDialog({
      title: `Error: ${title}`,
      kind: NotificationDialogKind.Error,
      message: message,
      buttons: [
        { caption: 'Ok', kind: NotificationButtonKind.Primary }
      ]
    });
  }

  public showDialog(data: NotificationDialogData): void {
    this._dialogSubject.next(data);
  }

  public showInfoToast(message: string, title: string = ''): void {
    this.showToast({
      message: message,
      title: title,
      kind: NotificationToastKind.Info,
      icon: icons.info
    });
  }

  public showSuccessToast(message: string, title: string = ''): void {
    this.showToast({
      message: message,
      title: title,
      kind: NotificationToastKind.Success,
      icon: icons.check
    });
  }

  public showWarningToast(message: string, title: string = ''): void {
    this.showToast({
      message: message,
      title: title,
      kind: NotificationToastKind.Warning,
      icon: icons.exclamation
    });
  }

  public showErrorToast(message: string, title: string = ''): void {
    this.showToast({
      message: message,
      title: title,
      kind: NotificationToastKind.Danger,
      icon: icons.close
    });
  }

  public showToast(data: NotificationToastData): void {
    this._toastSubject.next(data);
  }
}

import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PictureViewerOverlayService {

  private _subjectOverlayRequested: Subject<string> = new Subject<string>();

  public overlayRequested$: Observable<string> = this._subjectOverlayRequested.asObservable();

  public showOverlayFromString(imageData: string): void {
    this._subjectOverlayRequested.next(imageData);
  }

  public showOverlayFromBlob(imageData: Blob): void {
    const reader = new FileReader();
    reader.readAsDataURL(imageData);
    reader.onloadend = () => {
      this._subjectOverlayRequested.next(reader.result as string);
    };
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MobilePhoneProvider } from '../../../../shared/interfaces';
import { AppSettings } from '../../../../shared/models';
import { AbstractBasicDataApiService } from '../../abstract-basic-data-api.service';

@Injectable({
  providedIn: 'root'
})
export class MobilePhoneProviderApiService extends AbstractBasicDataApiService<MobilePhoneProvider> {

  constructor(
    protected http: HttpClient
  ) {
    super(http, AppSettings.endpoints.basicinformation.employees.mobilephoneprovider);
  }

  public list(): Observable<MobilePhoneProvider[]> {
    return this.http.get<MobilePhoneProvider[]>(this.baseUri);
  }
}

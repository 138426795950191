import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { TimeInterval } from '../../../shared/interfaces';
import { AppSettings } from '../../../shared/models';
import { AbstractBasicDataApiService } from '../abstract-basic-data-api.service';

@Injectable({
  providedIn: 'root'
})
export class TimeIntervalApiService extends AbstractBasicDataApiService<TimeInterval> {

  constructor(
    protected http: HttpClient
  ) {
    super(http, AppSettings.endpoints.basicinformation.timeInterval);
  }
}

import { NG_VALIDATORS, Validator, AbstractControl, ValidationErrors } from '@angular/forms';
import { Directive, Input } from '@angular/core';
import * as moment from 'moment';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[maxDate]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: MaxDateValidatorDirective, multi: true }
  ]
})
export class MaxDateValidatorDirective implements Validator {

  @Input()
  public maxDate: string;

  public validate(c: AbstractControl): ValidationErrors | null {
    if (!c.value || !this.maxDate) {
      return null;
    }

    const dateValue = moment(c.value);
    const maxValue = moment(this.maxDate);

    if (dateValue.isSameOrBefore(maxValue)) {
      return null;
    } else {
      return { maxValue: true };
    }
  }
}

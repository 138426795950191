import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgxDropzoneChangeEvent } from 'ngx-dropzone';
import { icons } from '../../../helper';

import { File as IFile, UploadFileFormConfiguration } from '../../../interfaces';

@Component({
  selector: 'image-upload-form-control',
  templateUrl: './image-upload-form-control.component.html',
  styleUrls: ['./image-upload-form-control.component.scss']
})
export class ImageUploadFormControlComponent {

  public icons = icons;
  public file: File;
  public showNoRenderText: boolean;
  public sizeClass: string;

  @Output()
  public fileChange: EventEmitter<File> = new EventEmitter<File>();

  @Output()
  public fileRemoved: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public fileRejected: EventEmitter<File> = new EventEmitter<File>();

  @Input()
  public isDisabled = false;

  @Input()
  public configuration: UploadFileFormConfiguration;

  @Input()
  public label: string;

  @Input()
  public hideLabel = false;

  @Input()
  public imageData: IFile;

  @Input()
  public backgroundClass: string;

  @Input()
  public showUploadText = false;

  @Input()
  public backgroundSvg: string;

  constructor() { }


  public getAccept(): string {
    if (!this.configuration || !this.configuration.accept || this.configuration.accept.length === 0) {
      return '';
    }

    return this.configuration.accept.join(',');
  }

  public onSelect(event: NgxDropzoneChangeEvent): void {

    if (event.addedFiles && event.addedFiles.length > 0) {
      this.file = (event.addedFiles[0]);
      this.fileChange.next(event.addedFiles[0]);
    }

    if (event.rejectedFiles && event.rejectedFiles.length > 0) {
      this.fileRejected.next(event.rejectedFiles[0]);
    }
  }

  public removeImage(): void {
    this.fileRemoved.next(null);
  }

  public getBackground(): any {
    if (this.imageData && this.imageData.data) {
      this.showUploadText = false;
      // this.sizeClass = 'max-size';
    } else {
      //   this.sizeClass = 'small-size';
    }

    if (!this.isImage() && this.file) {
      return {};
    }

    let svg = 'url(../../../assets/cloud-upload-solid.svg)';
    if (this.backgroundSvg && this.backgroundSvg.length > 0) {
      svg = this.backgroundSvg;
    }

    return (this.imageData && this.imageData.data)
      ? {
        background: 'url(' + this.imageData.data + ') '
      }
      : {
        background: svg
      };
  }

  public isImage(): boolean {
    if (this.file) {
      const file = this.file.name;
      const ext = file.substr(file.lastIndexOf('.')).toLowerCase();

      if (['.jpg', '.jpeg', '.png', '.gif', '.tiff', '.svg'].some(x => x === ext)) {
        return true;
      }
    }

    return false;
  }
}

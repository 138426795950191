<label *ngIf="!hideLabel">{{ label }}</label>
<div class="rating-stars">
  <ng-container *ngFor="let star of stars;index as i">
    <div class="rating-star"
         [class.disabled]="isDisabled"
         *ngIf="star"
         (click)="selectRating(i+1)">
      <fa-icon [icon]="iconsSolid.ratingStar"
               [fixedWidth]="true"></fa-icon>
    </div>
    <div class="rating-star"
         [class.disabled]="isDisabled"
         (click)="selectRating(i+1)"
         *ngIf="!star">
      <fa-icon [icon]="iconsRegular.ratingStar"
               [fixedWidth]="true"></fa-icon>
    </div>
  </ng-container>
</div>

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ContextMenuConfiguration, ContextMenuRequest } from '../../shared/models';

@Injectable({
  providedIn: 'root'
})
export class ContextMenuService {

  private _subjectContextMenuRegistered: Subject<ContextMenuConfiguration> = new Subject<ContextMenuConfiguration>();
  private _subjectContextMenuRemoved: Subject<string> = new Subject<string>();
  private _subjectContextMenuRequested: Subject<ContextMenuRequest> = new Subject<ContextMenuRequest>();

  public contextMenuRegistered$ = this._subjectContextMenuRegistered.asObservable();
  public contextMenuRemoved$ = this._subjectContextMenuRemoved.asObservable();
  public contextMenuRequested$ = this._subjectContextMenuRequested.asObservable();

  constructor() {

  }

  public openContextMenu(request: ContextMenuRequest): void {
    this._subjectContextMenuRequested.next(request);
  }

  public registerContextMenu(configuration: ContextMenuConfiguration): void {
    this._subjectContextMenuRegistered.next(configuration);
  }

  public removeContextMenu(uniqueName: string): void {
    this._subjectContextMenuRemoved.next(uniqueName);
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { InventoryNumberSettings, ResponseDataObject } from '../../../shared/interfaces';
import { AppSettings } from '../../../shared/models';

@Injectable({
  providedIn: 'root'
})
export class InventoryNumberSettingsApiService {
  private _baseUri = AppSettings.endpoints.application.inventoryNumberSettings;

  constructor(
    private _http: HttpClient
  ) {

  }

  public getInventoryNumberSettings(): Observable<InventoryNumberSettings> {
    return this._http.get<InventoryNumberSettings>(this._baseUri);
  }

  public updateInventoryNumberSettings(settings: InventoryNumberSettings): Observable<ResponseDataObject<InventoryNumberSettings>> {
    return this._http.put<ResponseDataObject<InventoryNumberSettings>>(this._baseUri, settings);
  }
}

import { environment } from '../../../environments/environment';

export const AppSettings = {
  displayInfo: environment.displayInfo,
  debug: environment.debug,
  appName: 'TimeSwift',
  auth: {
    user: 'TIMESWIFT:AUTH:USER'
  },
  settings: {
    userProfile: 'TIMESWIFT:SETTINGS:USERPROFILE',
    layout: 'TIMESWIFT:SETTINGS:LAYOUT'
  },
  version: '1.0.0.34',
  versionDate: '25.07.2024',
  endpoints: {
    auth: environment.baseUrl + 'token',
    employee: environment.baseUrl + 'basicinformation/employees/employee',
    company: environment.baseUrl + 'basicinformation/companies/company',
    onBoarding: environment.baseUrl + 'basicinformation/onboardings/onboarding',
    document: environment.baseUrl + 'document',
    multitenancy: {
      role: environment.baseUrl + 'multitenancy/role',
      rightTemplate: environment.baseUrl + 'multitenancy/rightTemplate',
      tenant: environment.baseUrl + 'multitenancy/tenant',
      user: environment.baseUrl + 'multitenancy/applicationUser',
      applicationUserTenantAssignment: environment.baseUrl + 'multitenancy/ApplicationUserTenantAssignment',
      database: environment.baseUrl + 'multitenancy/database',
      logEntry: environment.baseUrl + 'multitenancy/logentry',
      mobileAppVersions: environment.baseUrl + 'multitenancy/mobileAppVersions',
      basicData: environment.baseUrl + 'multitenancy/basicdata'
    },
    application: {
      commonSettings: environment.baseUrl + 'application/commonsetting',
      employeeCostUnitAssignment: environment.baseUrl + 'application/employeeorganizationcostunitassignment',
      employeeSalary: environment.baseUrl + 'application/employeesalary',
      fuelCardAssignment: environment.baseUrl + 'application/fuelcardassignment',
      inventoryNumberSettings: environment.baseUrl + 'application/inventorynumbersettings',
      plugin: environment.baseUrl + 'application/plugin',
      toolsAndMaterialsAssignment: environment.baseUrl + 'application/toolsandmaterialsassignment',
      userEmployeeAssignment: environment.baseUrl + 'application/useremployeeassignment'
    },
    basicinformation: {
      basicData: environment.baseUrl + 'basicinformation/basicdata',
      costType: environment.baseUrl + 'basicinformation/costtype',
      costCenter: environment.baseUrl + 'basicinformation/costcenter',
      documentType: environment.baseUrl + 'basicinformation/documenttype',
      country: environment.baseUrl + 'basicinformation/country',
      federalState: environment.baseUrl + 'basicinformation/federalstate',
      holiday: environment.baseUrl + 'basicinformation/holiday',
      timeInterval: environment.baseUrl + 'basicinformation/timeInterval',
      toolsAndMaterials: {
        type: environment.baseUrl + 'basicinformation/toolsandmaterials/type',
        item: environment.baseUrl + 'basicinformation/toolsandmaterials/item',
        name: environment.baseUrl + 'basicinformation/toolsandmaterials/name',
        condition: environment.baseUrl + 'basicinformation/toolsandmaterials/condition'
      },
      financialInstitution: environment.baseUrl + 'basicinformation/financialinstitution',
      fuelCard: environment.baseUrl + 'basicinformation/fuelcard',
      fuelCardProvider: environment.baseUrl + 'basicinformation/fuelcardprovider',
      employees: {
        healthinsurancecompany: environment.baseUrl + 'basicinformation/employees/healthinsurancecompany',
        employer: environment.baseUrl + 'basicinformation/employees/employer',
        vehiclelicenceclass: environment.baseUrl + 'basicinformation/employees/vehiclelicenceclass',
        salarycomponent: environment.baseUrl + 'basicinformation/employees/salarycomponent',
        volumeofemployment: environment.baseUrl + 'basicinformation/employees/volumeofemployment',
        mobilephonecontract: environment.baseUrl + 'basicinformation/employees/mobilephonecontract',
        mobilephoneprovider: environment.baseUrl + 'basicinformation/employees/mobilephoneprovider',
        mobilephonetariff: environment.baseUrl + 'basicinformation/employees/mobilephonetariff',
        qualification: environment.baseUrl + 'basicinformation/employees/qualification',
        employeetype: environment.baseUrl + 'basicinformation/employees/employeetype',
        fundedPensionType: environment.baseUrl + 'basicinformation/employees/fundedPensionType',
        fundedPensionFinancingType: environment.baseUrl + 'basicinformation/employees/fundedPensionFinancingType',
        clothingtype: environment.baseUrl + 'basicinformation/employees/clothingtype',
        nationality: environment.baseUrl + 'basicinformation/employees/nationality'
      },
      vehicles: {
        fuelBox: environment.baseUrl + 'basicinformation/vehicles/fuelbox',
        fuelType: environment.baseUrl + 'basicinformation/vehicles/fueltype',
        vehicleType: environment.baseUrl + 'basicinformation/vehicles/type',
        vehicleOwner: environment.baseUrl + 'basicinformation/vehicles/owner',
        vehicleModel: environment.baseUrl + 'basicinformation/vehicles/model',
        manufacturer: environment.baseUrl + 'basicinformation/vehicles/manufacturer',
        specialSuperstructureManufacturer: environment.baseUrl + 'basicinformation/vehicles/specialsuperstructuremanufacturer',
        specialSuperstructureType: environment.baseUrl + 'basicinformation/vehicles/specialsuperstructuretype',
        specialSuperstructure: environment.baseUrl + 'basicinformation/vehicles/specialsuperstructure',
        liftgateType: environment.baseUrl + 'basicinformation/vehicles/liftgatetype',
        platformManufacturer: environment.baseUrl + 'basicinformation/vehicles/platformmanufacturer',
        platformType: environment.baseUrl + 'basicinformation/vehicles/platformtype',
        maintenanceContractType: environment.baseUrl + 'basicinformation/vehicles/maintenancecontracttype',
        liftgateManufacturer: environment.baseUrl + 'basicinformation/vehicles/liftgatemanufacturer',
        emissionClass: environment.baseUrl + 'basicinformation/vehicles/emissionclass',
        telematicBox: environment.baseUrl + 'basicinformation/vehicles/telematicbox',
        tire: environment.baseUrl + 'basicinformation/vehicles/tire'
      }
    },
    vehicles: {
      fuelFilling: environment.baseUrl + 'vehicles/fuelfilling',
      vehicle: environment.baseUrl + 'vehicles/vehicle'
    },
    workOrders: {
      workOrderStatus: environment.baseUrl + 'workorders/workorderstatus',
      workActivityType: environment.baseUrl + 'workorders/workactivitytype',
      workActivity: environment.baseUrl + 'workorders/workactivity',
      workflow: environment.baseUrl + 'workorders/workflow',
      workOrder: environment.baseUrl + 'workorders/workorder',
      accounting: environment.baseUrl + 'workorders/accounting',
      basicData: environment.baseUrl + 'workorders/basicdata',
      workOrderSettings: environment.baseUrl + 'workorders/workordersettings',
      workOrderAccountingKey: environment.baseUrl + 'workorders/accountingkey',
      workOrderAccountingRuleSet: environment.baseUrl + 'workorders/accountingruleset',
      reports: environment.baseUrl + 'workorders/reports',
      pictureFunctionType: environment.baseUrl + 'workorders/pictureFunctionType',
    },
    support: {
      helpArticle: environment.baseUrl + 'support/helparticle',
      category: environment.baseUrl + 'support/category'
    },
    timeRecording: {
      attendanceEntries: environment.baseUrl + 'timerecording/attendanceentries',
      absenceEntries: environment.baseUrl + 'timerecording/absenceentries',
      absenceType: environment.baseUrl + 'timerecording/absencetype',
      workingTimeModel: environment.baseUrl + 'timerecording/workingtimemodel',
      reports: environment.baseUrl + 'timerecording/reports',
    },
    mobile: {
      mobileAppVersions: environment.baseUrl + 'mobile/appVersions'

    }
  }
};

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { GridConfiguration, GridSearchParameter } from '../../shared/interfaces';

@Injectable({
  providedIn: 'root'
})
export class GridControlBarService {

  private _subjectConfigurationChanged: Subject<GridConfiguration> = new Subject<GridConfiguration>();
  private _subjectDataUpdateRequested: Subject<GridSearchParameter> = new Subject<GridSearchParameter>();
  private _subjectDataUpdateFinished: Subject<any> = new Subject<any>();
  private _subjectPrintRequested: Subject<any> = new Subject<any>();
  private _subjectFilterbarVisibilityChanged: Subject<boolean> = new Subject<boolean>();
  private _subjectSaveFilterChanged: Subject<boolean> = new Subject<boolean>();
  private _subjectDoubleClickEnabled: Subject<boolean> = new Subject<boolean>();
  private _subjectOptionsVisibilityChangedByMenu: Subject<boolean> = new Subject<boolean>();
  private _subjectOptionsVisibilityChangedByControlbBar: Subject<boolean> = new Subject<boolean>();
  private _subjectColumnVisibilityChanged: Subject<any> = new Subject<any>();
  private _subjectClearGridRequested: Subject<any> = new Subject<any>();
  private _subjectVisibilityChanged: Subject<boolean> = new Subject<boolean>();
  private _subjectButtonVisibilityChanged: Subject<any> = new Subject<any>();
  private _subjectSearchExecuted: Subject<GridSearchParameter> = new Subject<GridSearchParameter>();

  public configurationChanged = this._subjectConfigurationChanged.asObservable();
  public dataUpdateRequested = this._subjectDataUpdateRequested.asObservable();
  public dataUpdateFinished = this._subjectDataUpdateFinished.asObservable();
  public dataPrintRequested = this._subjectPrintRequested.asObservable();
  public filterbarVisibilityChanged = this._subjectFilterbarVisibilityChanged.asObservable();
  public saveFilterChanged = this._subjectSaveFilterChanged.asObservable();
  public doubleClickEnabled = this._subjectDoubleClickEnabled.asObservable();
  public optionsVisibilityChangedByMenu = this._subjectOptionsVisibilityChangedByMenu.asObservable();
  public optionsVisibilityChangedByControlbBar = this._subjectOptionsVisibilityChangedByControlbBar.asObservable();
  public columnVisibilityChanged = this._subjectColumnVisibilityChanged.asObservable();
  public clearGridRequested = this._subjectClearGridRequested.asObservable();
  public visibilityChanged = this._subjectVisibilityChanged.asObservable();
  public buttonVisibilityChanged = this._subjectButtonVisibilityChanged.asObservable();
  public searchExecuted = this._subjectSearchExecuted.asObservable();

  constructor() { }

  public configureControlBar(configuration: GridConfiguration): void {
    this._subjectConfigurationChanged.next(configuration);
  }

  public destroyControlBar(): void {
    this._subjectConfigurationChanged.next(null);
  }

  public requestDataUpdate(parameter: GridSearchParameter): void {
    this._subjectDataUpdateRequested.next(parameter);
  }

  public broadcastDataUpdateFinished(): void {
    this._subjectDataUpdateFinished.next(null);
  }

  public changeFilterBarVisibility(visible: boolean): void {
    this._subjectFilterbarVisibilityChanged.next(visible);
  }

  public changeSaveFilter(save: boolean): void {
    this._subjectSaveFilterChanged.next(save);
  }

  public enableDoubleClick(enable: boolean): void {
    this._subjectDoubleClickEnabled.next(enable);
  }

  public executePrint(): void {
    this._subjectPrintRequested.next(null);
  }

  public changeGridOptionsVisibilityByMenu(visible: boolean): void {
    this._subjectOptionsVisibilityChangedByMenu.next(visible);
  }

  public changeGridOptionsVisibilityByControlBar(visible: boolean): void {
    this._subjectOptionsVisibilityChangedByControlbBar.next(visible);
  }

  public changeVisibility(visible: boolean): void {
    this._subjectVisibilityChanged.next(visible);
  }

  public changeColumnVisibility(): void {
    this._subjectColumnVisibilityChanged.next(null);
  }

  public changeButtonVisibility(): void {
    this._subjectButtonVisibilityChanged.next(null);
  }

  public clearGrid(): void {
    this._subjectClearGridRequested.next(null);
  }

  public notifySearchExecution(parameter: GridSearchParameter): void {
    this._subjectSearchExecuted.next(parameter);
  }
}

export enum RightTypes {
  None = 0,
  // tslint:disable:no-bitwise
  Read = 1 << 0,
  Create = 1 << 1,
  Edit = 1 << 2,
  Delete = 1 << 3,
  Print = 1 << 4
  // tslint:enable:no-bitwise
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Dimensions, ImageCroppedEvent } from 'ngx-image-cropper';

import { ImageCropperConfiguration } from '../../../interfaces';

@Component({
  selector: 'image-cropper-form-control',
  templateUrl: './image-cropper-form-control.component.html',
  styleUrls: ['./image-cropper-form-control.component.scss']
})
export class ImageCropperFormControlComponent {

  private _defaultConfiguration: ImageCropperConfiguration = {
    alignImage: 'center',
    aspectRatio: 1 / 1,
    autoCrop: true,
    canvasRotation: 0,
    containWithinAspectRatio: false,
    cropperMinHeight: 0,
    cropperMinWidth: 0,
    cropperStaticHeight: 0,
    cropperStaticWidth: 0,
    disabled: false,
    format: 'jpeg',
    hideResizeSquares: false,
    imageQuality: 80,
    initialStepSize: 3,
    maintainAspectRatio: true,
    onlyScaleDown: false,
    resizeToHeight: 0,
    resizeToWidth: 0,
    roundCropper: false,
    transform: {}
  };

  private _imageCropperConfiguration: ImageCropperConfiguration = {};

  @Input()
  public set imageCropperConfiguration(imageCropperConfiguration: ImageCropperConfiguration) {
    this._imageCropperConfiguration = Object.assign(
      this._imageCropperConfiguration,
      this._defaultConfiguration,
      imageCropperConfiguration || {}
    );
  }

  public get imageCropperConfiguration(): ImageCropperConfiguration {
    return this._imageCropperConfiguration;
  }

  @Input()
  public label: string;

  @Input()
  public hideLabel = false;

  @Input()
  public imageData: string;

  @Output()
  public imageCropped = new EventEmitter<ImageCroppedEvent>();

  @Output()
  public startCropImage = new EventEmitter<void>();

  @Output()
  public imageLoaded = new EventEmitter<void>();

  @Output()
  public cropperReady = new EventEmitter<Dimensions>();

  @Output()
  public loadImageFailed = new EventEmitter<void>();

  constructor() { }

  public onImageCropped(event: ImageCroppedEvent): void {
    this.imageCropped.next(event);
  }

  public onImageLoaded(): void {
    this.imageLoaded.next();
  }

  public onCropperReady(dimensions: Dimensions): void {
    this.cropperReady.next(dimensions);
  }

  public onLoadImageFailed(): void {
    this.loadImageFailed.next();
  }

  public onStartCropImage(): void {
    this.startCropImage.next();
  }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { FileDownload } from '../../shared/interfaces';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  public openFile(file: Observable<FileDownload>, asDownload: boolean, revokeFileTimeInMs: number = 1000): void {
    file.subscribe(data => {
      if (asDownload) {
        this.downloadFile(data, revokeFileTimeInMs);
      } else {
        this.openFileInNewTab(data, revokeFileTimeInMs);
      }
    });
  }

  public openBase64File(fileName: string, fileBase64: string, asDownload: boolean, revokeFileTimeInMs: number = 1000): void {
    fetch(fileBase64)
      .then(res => res.blob())
      .then(blob => {
        const file: FileDownload = {
          fileName: fileName,
          file: blob
        };

        if (asDownload) {
          this.downloadFile(file, revokeFileTimeInMs);
        } else {
          this.openFileInNewTab(file, revokeFileTimeInMs);
        }
      });
  }

  private downloadFile(fileData: FileDownload, revokeFileTimeInMs: number): void {
    const blobUrl = this.createObjectURL(fileData);

    const windowInstance = window.open('_blank');
    const link = windowInstance.document.createElement('a');
    windowInstance.document.body.appendChild(link);
    link.href = blobUrl;
    link.download = fileData.fileName;
    link.click();

    // windowInstance.close();
    // this.revokeObjectURL(blobUrl, revokeFileTimeInMs);
  }

  private openFileInNewTab(fileData: FileDownload, revokeFileTimeInMs: number): void {
    const blobUrl = this.createObjectURL(fileData);
    const windowInstance = window.open(blobUrl);

    windowInstance.onload = () => {
      setTimeout(() => {
        windowInstance.document.title = fileData.fileName;
      }, 10);
    };

    // this.revokeObjectURL(blobUrl, revokeFileTimeInMs);
  }

  private createObjectURL(fileData: FileDownload): string {
    return window.URL.createObjectURL(fileData.file);
  }

  private revokeObjectURL(blobUrl: string, revokeFileTimeInMs: number): void {
    setTimeout(() => {
      window.URL.revokeObjectURL(blobUrl);
    }, revokeFileTimeInMs);
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { icons } from '../../../helper';
import { iconsRegular } from '../../../helper/icon-regular-helper';

@Component({
  selector: 'rating-stars-form-control',
  templateUrl: './rating-stars-form-control.component.html',
  styleUrls: ['./rating-stars-form-control.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class RatingStarsFormControlComponent implements OnInit {

  public iconsSolid = icons;
  public iconsRegular = iconsRegular;
  public stars: boolean[] = [];

  @Input()
  public label: string;

  @Input()
  public value: number;

  @Output()
  public valueChange = new EventEmitter<number>();

  @Input()
  public isRequired = false;

  @Input()
  public isDisabled = false;

  @Input()
  public name: string;

  @Input()
  public hideLabel = false;

  @Input()
  public errorMessages: any;

  @Input()
  public hideErrors = false;

  constructor() {
  }

  public ngOnInit(): void {
    this.refreshStars();
  }

  public selectRating(star: number): void {
    if (this.isDisabled) {
      return;
    }

    if (this.value === star) {
      this.value = undefined;
    } else {
      this.value = star;
    }
    this.refreshStars();
    this.valueChange.emit(this.value);
  }

  private refreshStars(): void {
    for (let index = 0; index < 5; index++) {
      this.stars[index] = this.value > index;
    }
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { DefaultCRUDResponse, GridSearchParameter, MobileAppVersion, MobileAppVersionCreate, ResponseDataObject, SearchApiService } from '../../../shared/interfaces';
import { AppSettings } from '../../../shared/models';

@Injectable({
  providedIn: 'root'
})
export class MobileAppVersionsApiService implements SearchApiService<MobileAppVersion> {

  constructor(
    private _http: HttpClient
  ) { }

  public search(parameter: GridSearchParameter): Observable<ResponseDataObject<MobileAppVersion[]>> {
    return this._http.post<ResponseDataObject<MobileAppVersion[]>>(AppSettings.endpoints.multitenancy.mobileAppVersions + '/search', parameter);
  }

  public searchSum(parameter: GridSearchParameter): Observable<ResponseDataObject<any>> {
    return this._http.post<any>(AppSettings.endpoints.multitenancy.mobileAppVersions + '/search/sum', parameter);
  }

  public create(mobileAppVersion: MobileAppVersionCreate, appData: string): Observable<ResponseDataObject<DefaultCRUDResponse>> {
    return this._http.post<ResponseDataObject<DefaultCRUDResponse>>(AppSettings.endpoints.multitenancy.mobileAppVersions, { mobileAppVersion: mobileAppVersion, appData: appData });
  }

  public delete(mobileAppVersionId: number): Observable<ResponseDataObject<DefaultCRUDResponse>> {
    return this._http.delete<ResponseDataObject<DefaultCRUDResponse>>(AppSettings.endpoints.multitenancy.mobileAppVersions + '/' + mobileAppVersionId);
  }

  public activate(mobileAppVersionId: number): Observable<ResponseDataObject<DefaultCRUDResponse>> {
    return this._http.put<ResponseDataObject<DefaultCRUDResponse>>(AppSettings.endpoints.multitenancy.mobileAppVersions + '/' + mobileAppVersionId + '/activate', null);
  }
}

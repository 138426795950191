import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslationService } from '../../../core/services';
import { AppSettings } from '../../models';

@Component({
  selector: 'app-file-download',
  templateUrl: './file-download.component.html',
  styleUrls: ['./file-download.component.scss']
})
export class FileDownloadComponent implements OnInit {

  constructor(
    private _titleService: Title,
    private _translationService: TranslationService,
  ) {

    this._titleService.setTitle(`${this._translationService.translate('navigation', 'fileDownload')} - ${AppSettings.appName}`);
  }

  public ngOnInit(): void {

  }
}

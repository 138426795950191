import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { environment } from '../../../environments/environment';
import { GridLayout, ResponseDataObject } from '../../shared/interfaces';

@Injectable({
  providedIn: 'root'
})
export class GridLayoutApiService {

  private _baseUrl = environment.baseUrl + 'administration/gridlayout/';

  constructor(private _http: HttpClient) { }

  public getGridLayout(_: string): Observable<GridLayout> {
    // ToDo: Implement if needed
    // return this._http.get<GridLayout>(this._baseUrl + layoutName);
    return of(null);
  }

  public upsertGridLayout(_: GridLayout): Observable<ResponseDataObject<any>> {
    // ToDo: Implement if needed
    // return this._http.post<ResponseDataObject>(this._baseUrl, layout);
    return of({ isFaulty: false });
  }
}

<label *ngIf="!hideLabel">{{ label }}</label>
<div class="input-group">
  <input [type]="'text'"
         class="form-control"
         [placeholder]="'00:00'"
         [ngClass]="{'is-invalid': textValue.invalid && (textValue.dirty || textValue.touched)}"
         name="{{ name }}"
         [ngModel]="value"
         (ngModelChange)="onValueChange($event)"
         [required]="isRequired"
         #textValue="ngModel"
         [maxlength]="5"
         [disabled]="isDisabled"
         [readOnly]="isReadonly"
         [customValidator]="timeValidationOptions"
         #tpp="ngbPopover"
         triggers="manual"
         [autoClose]="'outside'"
         placement="auto"
         popoverClass="time-picker-popover"
         [ngbPopover]="popoverTimePicker"
         [popoverTitle]="popoverTimePickerTitle"
         (hidden)="onClosedPopover()">
  <span class="input-group-text"
        [class.disabled]="isDisabled || isReadonly"
        (click)="openTimePicker(tpp)">
    <fa-icon [icon]="icons.time"
             [fixedWidth]="true"></fa-icon>
  </span>
</div>
<div class="invalid-feedback d-block"
     *ngIf="textValue.invalid && (textValue.dirty || textValue.touched)">
  <div *ngFor="let textValueError of textValue.errors | keyvalue"> {{ textValueError.key | translation:'errors' }} </div>
</div>
<ng-template #popoverTimePickerTitle>
  <div class="time-picker-popover-title text-center">
    <span class="clockpicker-span-hours"
          [class.text-primary]="showHours"
          (click)="switchToHours()">{{ getHours() }}</span> : <span class="clockpicker-span-minutes"
          [class.text-primary]="showMinutes"
          (click)="switchToMinutes()">{{ getMinutes() }}</span>
  </div>
</ng-template>
<ng-template #popoverTimePicker>
  <div class="time-picker-popover-body">
    <div class="clockpicker-plate">
      <div class="clockpicker-canvas"><svg class="clockpicker-svg"
             [attr.width]="diameter"
             [attr.height]="diameter">
          <g [attr.transform]="'translate(' +dialRadius+','+dialRadius+')'">
            <line x1="0"
                  y1="0"
                  [attr.x2]="clockPickerHandCx"
                  [attr.y2]="clockPickerHandCy"></line>
            <circle class="clockpicker-canvas-fg"
                    r="3.5"
                    [attr.cx]="clockPickerHandCx"
                    [attr.cy]="clockPickerHandCy"></circle>
            <circle class="clockpicker-canvas-bg"
                    [attr.r]="tickRadius"
                    [attr.cx]="clockPickerHandCx"
                    [attr.cy]="clockPickerHandCy"></circle>
            <circle class="clockpicker-canvas-bearing"
                    cx="0"
                    cy="0"
                    r="2"></circle>
          </g>
        </svg></div>
      <div class="clockpicker-dial clockpicker-hours"
           *ngIf="showHours">
        <ng-container *ngFor="let hour of hours">
          <div class="clockpicker-tick"
               (click)="onSelectedTime(hour.value)"
               [ngStyle]="{ left: hour.left, top: hour.top}"
               [class.inner-circle]="hour.inner">{{hour.value}}</div>
        </ng-container>
      </div>
      <div class="clockpicker-dial clockpicker-minutes"
           *ngIf="showMinutes">
        <ng-container *ngFor="let minute of minutes">
          <div class="clockpicker-tick"
               (click)="onSelectedTime(minute.value)"
               [ngStyle]="{ left: minute.left, top: minute.top}">{{minute.value}}</div>
        </ng-container>
      </div>
    </div>
  </div>
</ng-template>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppSettings, MultiTenancyBasicDataRequest, MultiTenancyBasicDataResponse } from '../../../shared/models';
import { ResponseDataObject } from '../../../shared/interfaces';

@Injectable({
  providedIn: 'root'
})
export class MultiTenancyBasicDataApiService {

  private _url = AppSettings.endpoints.multitenancy.basicData;

  constructor(
    private _http: HttpClient
  ) {

  }

  public loadBasicData(request: MultiTenancyBasicDataRequest): Observable<ResponseDataObject<MultiTenancyBasicDataResponse>> {
    return this._http.post<ResponseDataObject<MultiTenancyBasicDataResponse>>(this._url, request);
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

@Component({
  selector: 'tag-form-control',
  templateUrl: './tag-form-control.component.html',
  styleUrls: ['./tag-form-control.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class TagFormControlComponent implements OnInit {

  @Input()
  public label: string;

  @Input()
  public tagCssClass: string;

  public value: string;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('value')
  public set setValue(value: string) {
    this.value = value;
    this.initTagEditorList();
  }

  @Output()
  public valueChange = new EventEmitter<string>();

  @Input()
  public name: string;

  @Input()
  public isRequired = false;

  @Input()
  public placeholder = '';

  @Input()
  public isDisabled = false;

  @Input()
  public isReadonly = false;

  @Input()
  public hideLabel = false;

  @Input()
  public maxTagCount = 0;

  @Input()
  public tagPosition: 'top' | 'bottom' = 'top';

  @Input()
  public tooltip = '';

  @Input()
  public search: (text: string) => Observable<string[]>;

  public tags: string[] = [];
  public availableTags: string[] = [];
  public tagInput = '';

  constructor() {

  }

  public searchTags: (text$: Observable<string>) => Observable<string[]>;

  public ngOnInit(): void {
    this.searchTags = (text$: Observable<string>) =>
      text$.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        switchMap(term => {
          if (!this.search || !term || term.length < 2) {
            return of([]);
          }

          return this.search(term);
        }));
  }

  public onValueChange(data: string): void {
    this.value = data;
    if (this.value !== this.tags.join(',')) {
      this.initTagEditorList();
    }

    this.valueChange.emit(this.value);
  }

  public removeTag(tag: string): void {
    const index = this.tags.indexOf(tag);
    this.tags.splice(index, 1);
    this.onValueChange(this.tags.join(','));
  }

  public addTag(): void {
    this.checkAndAddTag(this.tagInput);
  }

  public checkKey(event: KeyboardEvent): void {

    if (this.isReadonly || this.isDisabled) {
      event.preventDefault();
      event.stopPropagation();

      return;
    }

    const key: number = event.which || event.keyCode;

    if (key === 8 || key === 37 || key === 39 || key === 46) {
      return;
    }

    /* tab || enter || comma */
    if (key === 9 || key === 13 || key === 188) {
      this.checkAndAddTag(this.tagInput);

      if (key !== 9 || event.shiftKey) {
        event.preventDefault();
        event.stopPropagation();
      }

      return;
    }

    if (this.tagInput.length === 0 && !/^[a-zA-Z0-9]$/i.test(event.key) || !/^[a-zA-Z0-9\s\-.]$/i.test(event.key)) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  private checkAndAddTag(tag: string): void {
    if (!!tag && tag.trim() !== '') {
      tag = tag.trim();
      const tagToLower = tag.toLocaleLowerCase();

      if (tag.length >= 2 && this.tags.filter(t => t.toLocaleLowerCase() === tagToLower).length === 0) {
        this.tags.push(tag);
        this.onValueChange(this.tags.join(','));
      }
      this.tagInput = '';
    }
  }

  private initTagEditorList(): void {
    if (this.value) {
      const tagString: string = this.value;
      this.tags = tagString.split(',');
    } else {
      this.tags = [];
    }
  }
}

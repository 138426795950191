<div class="banner"
     [ngClass]="getBannerBackgroundClass()">
  <div class="banner-icon">
    <fa-icon [icon]="banner.icon || getFallbackIcon()"
             [size]="'6x'"
             [fixedWidth]="true">
    </fa-icon>
  </div>
  <div class="banner-content ms-3">
    <h4>{{ banner.title }}</h4>
    <p>{{ banner.text1 }}</p>
    <p *ngIf="banner.text2">{{ banner.text2 }}</p>
  </div>
  <div class="close-icon">
    <fa-icon [icon]="icons.cancel"
             [fixedWidth]="true"
             (click)="hide()">
    </fa-icon>
  </div>
</div>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { FuelCardProvider } from '../../../shared/interfaces';
import { AbstractBasicDataApiService } from '../abstract-basic-data-api.service';
import { AppSettings } from '../../../shared/models';

@Injectable({
  providedIn: 'root'
})
export class FuelCardProviderApiService extends AbstractBasicDataApiService<FuelCardProvider> {

  constructor(
    protected http: HttpClient
  ) {
    super(http, AppSettings.endpoints.basicinformation.fuelCardProvider);
  }
}

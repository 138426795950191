import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { mainNavigationRoutes } from './app-routing.module';
import { AuthService, MenuService } from './core/services';
import { AuthenticationState } from './shared/enums';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(
    private _authService: AuthService,
    private _menuService: MenuService,
    private _router: Router
  ) {

    this._authService.authenticated$.subscribe(
      _ => setTimeout(() => {
        this._menuService.addNavigationItems(mainNavigationRoutes);
      }, 10)
    );

    this._router.events.pipe(
      filter((e): e is NavigationEnd => e instanceof NavigationEnd),
      map(e => e.url))
      .subscribe(url => {
        if (url === '/' && this._authService.getAuthenticationState() === AuthenticationState.Authenticated) {
          const firstRoute = this._menuService.menuItems.find(mi => this._authService.hasPermission(mi.permission));
          if (firstRoute) {
            this._router.navigate([firstRoute.path]);
          }
        }
      });
  }
}

export const errorMessageConstants = {
  unexpectedErrorOccurred: 'unexpectedErrorOccurred',
  notFound: 'notFound',
  invalidData: 'invalidData',
  noChangesToSave: 'noChangesToSave',
  validationErrors: 'validationErrors',
  additionalValidationError: 'additionalValidationError',
  additionalValidationErrors: 'additionalValidationErrors',
  errorReadData: 'errorReadData',
  saveDataError: 'saveDataError',
  notPossible: 'notPossible',
  successfullyDeleted: 'successfullyDeleted',
  successfullyRemoved: 'successfullyRemoved',
  duplicatedEntryFound: 'duplicatedEntryFound'
};

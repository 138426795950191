import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgxDropzoneChangeEvent } from 'ngx-dropzone';
import { RejectedFile } from 'ngx-dropzone/lib/ngx-dropzone.service';

import { NotificationService, TranslationService } from '../../../../core/services';
import { File as FileInterface, UploadFileFormConfiguration } from '../../../interfaces';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'upload-file-form-control',
  templateUrl: './upload-file-form-control.component.html',
  styleUrls: ['./upload-file-form-control.component.scss']
})
export class UploadFileFormControlComponent implements OnInit {

  @Input()
  public configuration: UploadFileFormConfiguration;

  @Input()
  public label: string;

  @Output()
  public filesSelected: EventEmitter<FileInterface> = new EventEmitter<FileInterface>();

  constructor(
    protected _notificationService: NotificationService,
    protected _translationService: TranslationService,
  ) { }

  public ngOnInit(): void {
  }

  public getAccept(): string {
    if (!this.configuration || !this.configuration.accept || this.configuration.accept.length === 0) {
      return '';
    }

    return this.configuration.accept.join(',');
  }

  public onSelect(event: NgxDropzoneChangeEvent): void {
    event.addedFiles.forEach(f => {
      this.readFile(f).then(
        data => this.filesSelected.next({
          fileName: f.name,
          data: data.toString()
        })
      );
    });

    event.rejectedFiles.forEach((f: RejectedFile) => {
      this._notificationService.showWarningToast(
        this._translationService.translate('file-error', f.reason) + ' ' + f.name
      );
    });

  }

  private readFile(file: File): Promise<string | ArrayBuffer> {
    return new Promise<string | ArrayBuffer>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = e => resolve((e.target as FileReader).result);
      reader.onerror = _ => reject(null);

      if (!file) {
        return reject(null);
      }

      reader.readAsDataURL(file);
    });
  }

}

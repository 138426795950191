<label *ngIf="!hideLabel">{{ label }}</label>
<div class="input-group">
  <span class="input-group-text"
        *ngIf="prependIcon">
    <fa-icon [icon]="prependIcon"
             [fixedWidth]="true"></fa-icon>
  </span>
  <input type="text"
         class="form-control"
         [placeholder]="placeholder"
         [ngClass]="{'is-invalid': numberValue.invalid && (numberValue.dirty || numberValue.touched)}"
         name="{{ name }}"
         [ngModel]="value"
         [minValue]="minValue"
         [maxValue]="maxValue"
         (ngModelChange)="onValueChange($event)"
         [required]="isRequired"
         currencyMask
         [options]="numberMaskConfig"
         #numberValue="ngModel"
         [disabled]="isDisabled">
  <span class="input-group-text"
        *ngIf="appendIcon">
    <fa-icon [icon]="appendIcon"
             [fixedWidth]="true"></fa-icon>
  </span>
</div>
<div class="invalid-feedback d-block"
     *ngIf="!hideErrors && numberValue.invalid && (numberValue.dirty || numberValue.touched)">
  <div *ngFor="let numberValueError of numberValue.errors | keyvalue">
    <ng-container *ngIf="errorMessages && errorMessages[numberValueError.key]"> {{ errorMessages[numberValueError.key]}} </ng-container>
    <ng-container *ngIf="!errorMessages || !errorMessages[numberValueError.key]"> {{ numberValueError.key | translation:'errors' }} </ng-container>
  </div>
</div>

<label *ngIf="!hideLabel">{{ label }}</label>
<image-cropper [imageBase64]="imageData"
               [format]="imageCropperConfiguration.format"
               [aspectRatio]="imageCropperConfiguration.aspectRatio"
               [maintainAspectRatio]="imageCropperConfiguration.maintainAspectRatio"
               [containWithinAspectRatio]="imageCropperConfiguration.containWithinAspectRatio"
               [resizeToWidth]="imageCropperConfiguration.resizeToWidth"
               [resizeToHeight]="imageCropperConfiguration.resizeToHeight"
               [cropperStaticWidth]="imageCropperConfiguration.cropperStaticWidth"
               [cropperStaticHeight]="imageCropperConfiguration.cropperStaticHeight"
               [cropperMinWidth]="imageCropperConfiguration.cropperMinWidth"
               [cropperMinHeight]="imageCropperConfiguration.cropperMinHeight"
               [initialStepSize]="imageCropperConfiguration.initialStepSize"
               [onlyScaleDown]="imageCropperConfiguration.onlyScaleDown"
               [roundCropper]="imageCropperConfiguration.roundCropper"
               [imageQuality]="imageCropperConfiguration.imageQuality"
               [autoCrop]="imageCropperConfiguration.autoCrop"
               [alignImage]="imageCropperConfiguration.alignImage"
               [backgroundColor]="imageCropperConfiguration.backgroundColor"
               [hideResizeSquares]="imageCropperConfiguration.hideResizeSquares"
               [disabled]="imageCropperConfiguration.disabled"
               [canvasRotation]="imageCropperConfiguration.canvasRotation"
               [transform]="imageCropperConfiguration.transform"
               (imageCropped)="onImageCropped($event)"
               (imageLoaded)="onImageLoaded()"
               (cropperReady)="onCropperReady($event)"
               (loadImageFailed)="onLoadImageFailed()"
               (startCropImage)="onStartCropImage()">
</image-cropper>

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavigationLink } from '../../interfaces';

@Component({
  selector: 'app-sub-menu-navbar',
  templateUrl: './sub-menu-navbar.component.html',
  styleUrls: ['./sub-menu-navbar.component.scss']
})
export class SubMenuNavbarComponent implements OnInit {

  @Input()
  public links: NavigationLink[] = [];

  @Input()
  public showButton = false;

  @Input()
  public buttonTitle = '';

  @Input()
  public showSecondButton = false;

  @Input()
  public secondButtonTitle = '';

  @Output()
  public buttonClicked: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public secondButtonClicked: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  public ngOnInit(): void {
  }

  public onButtonClicked(): void {
    this.buttonClicked.next(null);
  }

  public onSecondButtonClicked(): void {
    this.secondButtonClicked.next(null);
  }
}

import { Pipe, PipeTransform } from '@angular/core';

import { CurrencyLogicLayer } from '../../core/business-logic/currency.logicLayer';
import { NumberMaskConfig } from '../interfaces';

@Pipe({
  name: 'localeCurrency'
})
export class LocaleCurrencyPipe implements PipeTransform {

  constructor(
    private _currencyLogicLayer: CurrencyLogicLayer
  ) { }

  public transform(value: any, args: NumberMaskConfig): string {
    if ((!value && value !== 0) || !args) {
      return value;
    }

    return this._currencyLogicLayer.formatCurrency(value, args);
  }

}

import { CommonModule, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxCurrencyModule } from 'ngx-currency';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgxIbanModule } from 'ngx-iban';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { provideEnvironmentNgxMask, NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';

import { AssistantComponent } from './components/assistant/assistant.component';
import { BasicDataButtonBarComponent } from './components/basic-data-button-bar/basic-data-button-bar.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ForbiddenComponent } from './components/forbidden/forbidden.component';
import {
  CheckboxFormControlComponent,
} from './components/form-controls/checkbox-form-control/checkbox-form-control.component';
import { DateFormControlComponent } from './components/form-controls/date-form-control/date-form-control.component';
import {
  DropDownFormControlComponent,
} from './components/form-controls/drop-down-form-control/drop-down-form-control.component';
import { IbanFormControlComponent } from './components/form-controls/iban-form-control/iban-form-control.component';
import { MaskFormControlComponent } from './components/form-controls/mask-form-control/mask-form-control.component';
import { NumberFormControlComponent } from './components/form-controls/number-form-control/number-form-control.component';
import { TextFormControlComponent } from './components/form-controls/text-form-control/text-form-control.component';
import {
  TextareaFormControlComponent,
} from './components/form-controls/textarea-form-control/textarea-form-control.component';
import {
  UploadFileFormControlComponent,
} from './components/form-controls/upload-file-form-control/upload-file-form-control.component';
import { GridControlBarComponent } from './components/grid-control-bar/grid-control-bar.component';
import { GridComponent } from './components/grid/grid.component';
import { LoginComponent } from './components/login/login.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { NotificationComponent } from './components/notification/notification.component';
import { HasRightDirective } from './directives/has-right.directive';
import { CharacterValidatorDirective } from './directives/validators/character-validator.directive';
import { CustomValidatorDirective } from './directives/validators/custom-validator.directive';
import { MaxValueValidatorDirective } from './directives/validators/max-validator.directive';
import { MinValueValidatorDirective } from './directives/validators/min-validator.directive';
import { NumberValidatorDirective } from './directives/validators/number-validator.directive';
import { LocaleFormatter, StringDateAdapter } from './logic';
import { LocaleCurrencyPipe, LocaleDatePipe, LocaleTimePipe, TranslationPipe } from './pipes';
import { TreeViewComponent } from './components/tree-view/tree-view.component';
import { TimePickerFormControlComponent } from './components/form-controls/time-picker-form-control/time-picker-form-control.component';
import { BannerComponent } from './components/banner/banner.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { RatingStarsFormControlComponent } from './components/form-controls/rating-stars-form-control/rating-stars-form-control.component';
import { TagFormControlComponent } from './components/form-controls/tag-form-control/tag-form-control.component';
import { ColorChromeModule } from 'ngx-color/chrome';
import { ColorPickerFormControlComponent } from './components/form-controls/color-picker-form-control/color-picker-form-control.component';
import { ImageUploadFormControlComponent } from './components/form-controls/image-upload-form-control/image-upload-form-control.component';
import { ImageCropperFormControlComponent } from './components/form-controls/image-cropper-form-control/image-cropper-form-control.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { AppShellComponent } from './components/app-shell/app-shell.component';
import { SubMenuNavbarComponent } from './components/sub-menu-navbar/sub-menu-navbar.component';
import { PictureViewerOverlayComponent } from './components/picture-viewer-overlay/picture-viewer-overlay.component';
import { MarkdownModule } from 'ngx-markdown';
import { MaxDateValidatorDirective, MinDateValidatorDirective } from './directives';
import { FileDownloadComponent } from './components/file-download/file-download.component';
import { DateTimeFormControlComponent } from './components/form-controls/date-time-form-control/date-time-form-control.component';
import { CalendarMonthCardComponent } from './components/calendar-month-card/calendar-month-card.component';
import { ContextMenuComponent } from './components/context-menu/context-menu.component';
import { KpiChartComponent } from './components/charts/kpi-chart/kpi-chart.component';
import { BarChartComponent } from './components/charts/bar-chart/bar-chart.component';
import { DonutChartComponent } from './components/charts/donut-chart/donut-chart.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    RouterModule,
    FontAwesomeModule,
    InfiniteScrollModule,
    NgxCurrencyModule,
    NgxDropzoneModule,
    MarkdownModule,
    NgxIbanModule,
    DragDropModule,
    ColorChromeModule,
    ImageCropperModule,
    NgxMaskDirective,
    NgxMaskPipe
  ],
  exports: [
    CommonModule,
    FormsModule,
    NgbModule,
    InfiniteScrollModule,
    NgxCurrencyModule,
    NgxDropzoneModule,
    NotFoundComponent,
    ForbiddenComponent,
    DashboardComponent,
    TranslationPipe,
    FontAwesomeModule,
    HasRightDirective,
    NotificationComponent,
    GridComponent,
    GridControlBarComponent,
    BasicDataButtonBarComponent,
    NumberValidatorDirective,
    CharacterValidatorDirective,
    CustomValidatorDirective,
    MinValueValidatorDirective,
    MaxValueValidatorDirective,
    MinDateValidatorDirective,
    MaxDateValidatorDirective,
    NumberFormControlComponent,
    DateFormControlComponent,
    TextFormControlComponent,
    DropDownFormControlComponent,
    TextareaFormControlComponent,
    CheckboxFormControlComponent,
    LocaleDatePipe,
    LocaleTimePipe,
    LocaleCurrencyPipe,
    MaskFormControlComponent,
    UploadFileFormControlComponent,
    NgxIbanModule,
    IbanFormControlComponent,
    TreeViewComponent,
    BannerComponent,
    TimePickerFormControlComponent,
    RatingStarsFormControlComponent,
    DragDropModule,
    TagFormControlComponent,
    ColorPickerFormControlComponent,
    ImageUploadFormControlComponent,
    ImageCropperFormControlComponent,
    SubMenuNavbarComponent,
    PictureViewerOverlayComponent,
    MarkdownModule,
    DateTimeFormControlComponent,
    CalendarMonthCardComponent,
    ContextMenuComponent,
    KpiChartComponent,
    BarChartComponent,
    DonutChartComponent,
    NgxMaskDirective,
    NgxMaskPipe
  ],
  declarations: [
    NotFoundComponent,
    ForbiddenComponent,
    DashboardComponent,
    TranslationPipe,
    HasRightDirective,
    LoginComponent,
    NotificationComponent,
    GridComponent,
    GridControlBarComponent,
    BasicDataButtonBarComponent,
    NumberValidatorDirective,
    CharacterValidatorDirective,
    CustomValidatorDirective,
    MinValueValidatorDirective,
    MaxValueValidatorDirective,
    MinDateValidatorDirective,
    MaxDateValidatorDirective,
    AssistantComponent,
    NumberFormControlComponent,
    DateFormControlComponent,
    TextFormControlComponent,
    DropDownFormControlComponent,
    TextareaFormControlComponent,
    CheckboxFormControlComponent,
    LocaleDatePipe,
    LocaleTimePipe,
    LocaleCurrencyPipe,
    UploadFileFormControlComponent,
    MaskFormControlComponent,
    IbanFormControlComponent,
    TreeViewComponent,
    BannerComponent,
    TimePickerFormControlComponent,
    RatingStarsFormControlComponent,
    TagFormControlComponent,
    ColorPickerFormControlComponent,
    ImageUploadFormControlComponent,
    ImageCropperFormControlComponent,
    AppShellComponent,
    SubMenuNavbarComponent,
    PictureViewerOverlayComponent,
    FileDownloadComponent,
    DateTimeFormControlComponent,
    CalendarMonthCardComponent,
    ContextMenuComponent,
    KpiChartComponent,
    BarChartComponent,
    DonutChartComponent
  ],
  providers: [
    TranslationPipe,
    StringDateAdapter,
    LocaleFormatter,
    LocaleDatePipe,
    LocaleTimePipe,
    DecimalPipe,
    provideEnvironmentNgxMask()
  ],
})
export class SharedModule { }

<div class="calendar-month">
  <ng-container *ngIf="daysTitle">
    <ng-container *ngFor="let day of daysTitle">
      <div class="calendar-day-title"> {{day.displayNumber}} </div>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="days">
    <ng-container *ngFor="let day of days;index as i">
      <div class="calendar-day calendar-day-{{i}}"
           [class.editable]="isEditable"
           [class.is-none-working-day]="!day.isWorkingDay"
           [class.disabled]="day.isDisabled">
        <div class="calendar-day-number"
             [class.light]="day.isHolidayLeftHalf"
             (click)="onClickDay(day, true)"> {{day.displayNumber}}</div>
        <div [class.is-holiday]="day.isHolidayLeftHalf"
             [class.is-used-day]="day.isUsedDayLeftHalf"
             [title]="day.tooltipLeftHalf"
             [style.background-color]="day.colorLeftHalf"
             class="left-half"
             (click)="onClickDay(day, true)"></div>
        <div [class.is-holiday]="day.isHolidayRightHalf"
             [class.is-used-day]="day.isUsedDayRightHalf"
             [title]="day.tooltipRightHalf"
             [style.background-color]="day.colorRightHalf"
             class="right-half"
             (click)="onClickDay(day, false)"></div>
      </div>
    </ng-container>
  </ng-container>
</div>

import { NG_VALIDATORS, Validator, AbstractControl, ValidationErrors } from '@angular/forms';
import { Directive, Input } from '@angular/core';
import * as moment from 'moment';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[minDate]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: MinDateValidatorDirective, multi: true }
  ]
})
export class MinDateValidatorDirective implements Validator {

  @Input()
  public minDate: string;

  public validate(c: AbstractControl): ValidationErrors | null {
    if (!c.value || !this.minDate) {
      return null;
    }

    const dateValue = moment(c.value);
    const minValue = moment(this.minDate);

    if (dateValue.isSameOrAfter(minValue)) {
      return null;
    } else {
      return { minValue: true };
    }
  }
}

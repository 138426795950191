import { DecimalPipe } from '@angular/common';
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import * as d3 from 'd3';
import * as d3Array from 'd3-array';
import * as d3Scale from 'd3-scale';
import * as d3Shape from 'd3-shape';
import { Guid } from 'guid-typescript';
import { Subscription } from 'rxjs';
import { TranslationService } from '../../../../core/services';
import { BarChartConfig, BarChartData } from '../../../models/charts';


@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss']
})
export class BarChartComponent implements OnInit, OnDestroy {

  private _subscription: Subscription;
  private _svg: any;
  private _barChartConfig: BarChartConfig;
  private _height: number;
  private _width: number;
  private _isLoading = false;

  @Input()
  public set isLoading(value: boolean) {
    setTimeout(() => {
      this.generateChart(this._isLoading);
    });

    this._isLoading = value;
  }
  public get isLoading(): boolean {
    return this._isLoading;
  }

  @Input()
  public set barChartConfig(barChartInfo: BarChartConfig) {
    this._barChartConfig = barChartInfo;
    setTimeout(() => {
      this.generateChart(this._isLoading);
    });
  }
  public get barChartConfig(): BarChartConfig {
    return this._barChartConfig;
  }
  @ViewChild('chart', { static: true })
  public chart: ElementRef;

  constructor(
    private _translationService: TranslationService,
    private _router: Router,
    private _decimalPipe: DecimalPipe,
  ) { }

  public ngOnInit(): void {

  }

  public ngOnDestroy(): void {
    if (this._subscription) {
      this._subscription.unsubscribe();
      this._subscription = null;
    }
  }

  private generateChart(isLoading: boolean): void {
    if (!this._barChartConfig) {
      return;
    }

    const barChartDiv = this.chart.nativeElement as HTMLDivElement;
    barChartDiv.id = this._barChartConfig.identifier + '_c';
    this._width = barChartDiv.clientWidth;
    this._height = barChartDiv.clientHeight;

    while (barChartDiv.lastChild) {
      barChartDiv.removeChild(barChartDiv.lastChild);
    }

    if (!this._barChartConfig.barChartData || this._barChartConfig.barChartData.length === 0 || isLoading) {
      return;
    }

    const margin = {
      top: 20,
      right: 20,
      bottom: 30,
      left: 40
    };

    this._height = this._height - margin.top - margin.bottom;

    this._svg = d3.select(this.chart.nativeElement)
      .append('svg')
      .attr('width', this._width + margin.left + margin.right)
      .attr('height', this._height + margin.top + margin.bottom)
      .attr('style', 'border: transparent')
      .style('pointer-events', 'all')
      .style('cursor', () => this._barChartConfig.navigateToOnClick ? 'pointer' : 'arrow')
      .append('g')
      .attr('transform', `translate(${margin.left}, ${margin.top})`);

    if (!this._barChartConfig.barChartData || this._barChartConfig.barChartData.length === 0) {
      this._svg.append('text')
        .attr('x', (this._width - margin.left - margin.right) / 2)
        .attr('y', this._height / 2)
        .style('text-anchor', 'middle')
        .text(this._translationService.translate('common', 'noData'));

      return;
    }

    const keys = this._barChartConfig.barChartData.map(d => d.key).filter((value, index, self) => self.indexOf(value) === index);

    const x = d3.scaleBand()
      .domain(keys)
      .range([0, this._width - (margin.right * 4)])
      .padding(0.2);

    const y = d3.scaleLinear()
      .range([this._height, 0]);

    const yMultiplyValue = this._barChartConfig.maxHundredPercent ? 1 : 1.1;

    let maxValueYLeft = d3.max(this._barChartConfig.barChartData, (d: any) => d.barValue) * yMultiplyValue || 1;
    if (this._barChartConfig.avgLine) {
      maxValueYLeft = maxValueYLeft > this._barChartConfig.avgLine ? maxValueYLeft : this._barChartConfig.avgLine * yMultiplyValue;
    }

    y.domain([0, maxValueYLeft]);

    // X Axis
    this._svg.append('g')
      .attr('transform', 'translate(0,' + this._height + ')')
      .call(d3
        .axisBottom(x)
        .tickSize(-this._height)
        .tickFormat((d: any) => this.shortenText(d)));

    // Y Axis
    this._svg.append('g')
      .call(d3
        .axisLeft(y)
        .tickSize(-(this._width - margin.left - margin.right - margin.right))
        .tickFormat((d: any) => this._barChartConfig.ytickPosition === 'left'
          ? this._barChartConfig.ytickFormat + d
          : d + this._barChartConfig.ytickFormat));

    const rx = 5;
    const ry = 5;

    this._svg.selectAll('.bar')
      .data(this._barChartConfig.barChartData)
      .enter().append('path')
      .attr('class', 'bar')
      .attr('fill', (d: any) => this.getBarColor(d))
      .attr('d', d => {
        d.x = x(d.key);

        return `M${x(d.key)},${y(0) + ry}
          a${rx},${ry} 0 0 1 ${rx},${-ry}
          h${x.bandwidth() - 2 * rx}
          a${rx},${ry} 0 0 1 ${rx},${ry}
          v${this._height - y(0) - ry}
          h${-(x.bandwidth())}Z`;
      })
      .transition()
      .duration(1000)
      .attr('d', d => {
        d.x = x(d.key);

        return d.barValue > 0
          ? `M${x(d.key)},${y(d.barValue) + ry}
          a${rx},${ry} 0 0 1 ${rx},${-ry}
          h${x.bandwidth() - 2 * rx}
          a${rx},${ry} 0 0 1 ${rx},${ry}
          v${this._height - y(d.barValue) - ry}
          h${-(x.bandwidth())}Z`
          : '';
      })
      .attr('id', (d: any) => 'B' + (d.identifier || Guid.create().toString()));

    if (this._barChartConfig.avgLine) {
      this._svg.append('line')
        .style('stroke-dasharray', ('10, 5'))
        .style('stroke', '#6B6B6B')
        .style('stroke-width', 2)
        .attr('x1', 0)
        .attr('y1', y(this._barChartConfig.avgLine))
        .attr('x2', margin.left - margin.right - margin.right)
        .attr('y2', y(this._barChartConfig.avgLine))
        .transition()
        .duration(1500)
        .attr('x2', this._width - margin.left - margin.right - margin.right);
    }

    // Right Y Axis
    const yRight = d3Scale.scaleLinear().range([this._height, 0]);
    if (!this._barChartConfig.lineDataHidden) {
      yRight.domain([0, d3Array.max(this._barChartConfig.barChartData, (d: BarChartData) => d.lineValue) * yMultiplyValue || 1]);

      this._svg.append('g')
        .attr('transform', `translate(${this._width - (margin.right * 4)}, 0)`)
        .call(d3.axisRight(yRight).tickFormat((d: any) => this._barChartConfig.y2tickPosition === 'left'
          ? this._barChartConfig.y2tickFormat + d
          : d + this._barChartConfig.y2tickFormat));

      const line: any = (data, setValue) => d3Shape.line()
        .curve(d3Shape.curveMonotoneX)
        .x((d: any) => x(d.key) + x.bandwidth() / 2)
        .y((d: any) => setValue ? yRight(d.lineValue) : yRight(0))
        (data);

      this._svg.append('path')
        .datum(this._barChartConfig.barChartData)
        .attr('fill', 'none')
        .attr('stroke', this._barChartConfig.lineColor)
        .attr('stroke-width', 3)
        .attr('d', (d) => line(d, false))
        .transition()
        .duration(1500)
        .attr('d', (d) => line(d, true));

      this._svg.selectAll('dot')
        .data(this._barChartConfig.barChartData)
        .enter()
        .append('circle')
        .attr('fill', '#FFFFFF')
        .attr('stroke', this._barChartConfig.lineColor)
        .attr('stroke-width', 3)
        .attr('cx', (d: any) => x(d.key) + x.bandwidth() / 2)
        .attr('cy', (_: any) => yRight(0))
        .transition()
        .duration(1500)
        .attr('cy', (d: any) => yRight(d.lineValue))
        .attr('r', 5);
    }

    // Style outer grid lines
    this._svg.selectAll('.domain')
      .style('color', '#dddddd')
      .style('stroke-width', '1px');

    // Style grid lines
    this._svg.selectAll('line')
      .style('color', '#dddddd')
      .style('stroke-width', '1px');

    this._svg.selectAll('text')
      .style('font-size', '0.625rem')
      .style('color', '#a3a3a3');

    const focus = this._svg.append('g')
      .attr('class', 'focus')
      .style('display', 'none');

    focus.append('rect')
      .attr('x', x.bandwidth() / 2 - 40)
      .attr('y', '-30')
      .attr('width', '80')
      .attr('height', '20')
      .attr('rx', 5)
      .attr('ry', 5)
      .style('stroke-width', 0.1)
      .style('stroke', '#FFFFFF');

    focus.append('text')
      .attr('x', x.bandwidth() / 2)
      .attr('dy', '-14')
      .style('text-anchor', 'middle')
      .style('fill', '#FFFFFF');

    const lineFocus = this._svg.append('g')
      .attr('class', 'focus')
      .style('display', 'none');

    lineFocus.append('rect')
      .attr('fill', this._barChartConfig.lineColor)
      .attr('x', x.bandwidth() / 2 - 40)
      .attr('y', '-30')
      .attr('width', '80')
      .attr('height', '20')
      .attr('rx', 5)
      .attr('ry', 5);

    lineFocus.append('text')
      .attr('x', x.bandwidth() / 2)
      .attr('dy', '-14')
      .style('text-anchor', 'middle')
      .style('fill', '#FFFFFF');

    // Hover effect.
    this._svg
      .append('rect')
      .style('fill', 'none')
      .style('pointer-events', 'all')
      .attr('width', this._width)
      .attr('height', this._height)
      .on('click', (event: any) => {
        if (this._barChartConfig.navigateToOnClick) {
          const x0 = d3.pointer(event, this.chart.nativeElement)[0];

          let d: BarChartData;
          let hit = false;
          this._barChartConfig.barChartData.forEach(data => {
            if (x0 >= data.x + margin.left && x0 < data.x + x.bandwidth() + margin.left) {
              d = data;
              hit = true;

              return;
            }
          });

          if (!hit) {
            d = null;
          }

          if (d) {
            this._router.navigate(d.clickNavigateToCommand, d.clickNavigateToExtras);
          }
        }
      })
      .on('mouseover', () => {
        focus.style('display', null);
        if (!this._barChartConfig.lineDataHidden) {
          lineFocus.style('display', null);
        }
      })
      .on('mouseout', () => {
        focus.style('display', 'none');

        if (!this._barChartConfig.lineDataHidden) {
          lineFocus.style('display', 'none');
        }
      })
      .on('mousemove', (event: any) => {
        const x0 = d3.pointer(event, this.chart.nativeElement)[0];

        let d: BarChartData;
        let hit = false;
        this._barChartConfig.barChartData.forEach(data => {

          if (x0 >= data.x + margin.left && x0 < data.x + x.bandwidth() + margin.left) {
            d = data;
            hit = true;

            return;
          }
        });

        if (!hit) {
          d = null;
        }

        if (d) {
          const y1 = y(d.barValue) >= 15 ? y(d.barValue) : 15;
          const yValues = [y1];
          focus
            .transition().duration(30)
            .attr('transform', 'translate(' + x(d.key) + ',' + y1 + ')')
            .attr('fill', () => this.getBarColor(d));
          focus.select('text').text(() => this._barChartConfig.ytickPosition === 'left'
            ? this._barChartConfig.ytickFormat + this._decimalPipe.transform(d.barValue)
            : this._decimalPipe.transform(d.barValue) + this._barChartConfig.ytickFormat);

          if (!this._barChartConfig.lineDataHidden) {
            const y2 = this.getNotIntersectingYValue(yValues, yRight(d.lineValue), yRight(d.lineValue) > this._height / 2);
            yValues.push(y2);

            lineFocus
              .transition().duration(30)
              .attr('transform', 'translate(' + x(d.key) + ',' + y2 + ')');
            lineFocus.select('text').text(() => this._barChartConfig.y2tickPosition === 'left'
              ? this._barChartConfig.y2tickFormat + this._decimalPipe.transform(d.lineValue)
              : this._decimalPipe.transform(d.lineValue) + this._barChartConfig.y2tickFormat);
          }
        }
      });
  }

  private clickEvent(id: any): void {
    this._barChartConfig.barChartData.forEach(item => {
      const selected = this._svg.select('#B' + item.identifier);
      if (item.identifier === id) {
        selected.attr('fill', this._barChartConfig.barColor + 'aa');
      } else {
        selected.attr('fill', this._barChartConfig.barColor);
      }
    });
  }

  private shortenText(text: string): string {
    if (this._barChartConfig.shortenText || false) {
      return text.substring(0, 6);
    }

    return text;
  }

  private getBarColor(data: any): string {
    if (this._barChartConfig.barColors && this._barChartConfig.barColors.length > 0) {
      const barChartDataExcludeHundredPercentBars = this._barChartConfig.barChartData.filter(f => !f.hundredPercentFilledBar);

      if (barChartDataExcludeHundredPercentBars && barChartDataExcludeHundredPercentBars.length > 0 && !(data as BarChartData).hundredPercentFilledBar) {
        return this._barChartConfig.barColors[barChartDataExcludeHundredPercentBars.indexOf(data)];
      }

      if ((data as BarChartData).hundredPercentFilledBar) {
        return '#DE425B';
      }

      return this._barChartConfig.barColors[this._barChartConfig.barChartData.filter(f => f.hundredPercentFilledBar).indexOf(data)];

    }

    return this._barChartConfig.barColor;
  }

  private getNotIntersectingYValue(yValues: number[], y: number, stackToTop: boolean): number {
    let newY = y;
    const intersections = yValues.filter(yv =>
      (y >= yv && y <= yv + 20) || (y + 20 >= yv && y + 20 <= yv + 20));
    if (intersections.length > 0) {
      if (stackToTop) {
        y = Math.min(...intersections) - 25;
      } else {
        y = Math.max(...intersections) + 20 + 25;
      }
      newY = this.getNotIntersectingYValue(yValues, y, stackToTop);
    }

    return newY;
  }
}

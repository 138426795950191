import { Injectable } from '@angular/core';

import { LocaleConstants } from '../../shared/constants/locale.constants';
import { FieldDataType } from '../../shared/enums';


@Injectable({
  providedIn: 'root'
})
export class DateTimeLogicLayer {

  public getDateTimeFormat(type: FieldDataType, culture: string): string {

    const dateFormat = LocaleConstants.DateFormats.find(f => f.value === culture).text;
    const timeFormat = LocaleConstants.TimeFormats.find(f => f.value === culture).text;

    switch (type) {
      case FieldDataType.date:
        return dateFormat;
      case FieldDataType.time:
        return timeFormat;
    }

    return `${dateFormat} ${timeFormat}`;
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';

import { DropDownItem } from '../../../interfaces';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'drop-down-form-control',
  templateUrl: './drop-down-form-control.component.html',
  styleUrls: ['./drop-down-form-control.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class DropDownFormControlComponent {


  @Input()
  public label: string;

  @Input()
  public values: DropDownItem[] = [];

  @Input()
  public value: any;

  @Output()
  public valueChange = new EventEmitter<string>();

  @Input()
  public name: string;

  @Input()
  public isRequired = false;

  @Input()
  public isDisabled = false;

  @Input()
  public prependIcon: IconDefinition;

  @Input()
  public appendIcon: IconDefinition;

  @Input()
  public hideLabel = false;

  @Input()
  public cssClass = '';

  @Input()
  public hideErrors = false;

  @Input()
  public hideEmptyOption = false;

  constructor() { }

  public onValueChange(data: any): void {
    this.value = data;
    this.valueChange.emit(this.value);
  }

}

import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import * as d3 from 'd3';
import * as d3Array from 'd3-array';
import * as d3Scale from 'd3-scale';
import * as d3Shape from 'd3-shape';
import { TranslationService } from '../../../../core/services';
import { KpiChartConfig, KpiChartData } from '../../../models/charts';
import * as moment from 'moment';

export interface Margin {
  top: number;
  right: number;
  bottom: number;
  left: number;
}

@Component({
  selector: 'app-kpi-chart',
  templateUrl: './kpi-chart.component.html',
  styleUrls: ['./kpi-chart.component.scss']
})
export class KpiChartComponent {

  private _kpiChartConfig: KpiChartConfig;
  private _isLoading = false;

  @Input()
  public set isLoading(value: boolean) {
    // Timeout needed to render container height correctly.
    setTimeout(() => {
      this.drawAreaChart(value);
    }, 0);

    this._isLoading = value;
  }

  public get isLoading(): boolean {
    return this._isLoading;
  }

  @Input()
  public set kpiChartConfig(chartInfo: KpiChartConfig) {
    this._kpiChartConfig = chartInfo || {
      identifier: '',
      chartColor: '',
      chartData: [],
      headerLabel: '',
      headerLabelInfoNote: '',
      lineColor: '',
      maxLabel: '',
      minLabel: '',
      progressColorClass: '',
      progressPercentage: 0,
      valueLabel: '',
      valuePosition: 'left',
      hideProgress: true
    };

    if ((this._kpiChartConfig.chartData && this._kpiChartConfig.chartData.length > 0) || this._kpiChartConfig.identifier) {
      this.isLoading = false;
    }

    // Timeout needed to render container height correctly.
    setTimeout(() => {
      this.drawAreaChart(this._isLoading);
    }, 0);
  }
  public get kpiChartConfig(): KpiChartConfig {
    return this._kpiChartConfig;
  }

  @ViewChild('chart', { static: true })
  public chart: ElementRef;

  constructor(
    private _translationService: TranslationService
  ) { }

  private drawAreaChart(isLoading: boolean): void {
    if (!this.kpiChartConfig) {
      return;
    }

    const areaChartDiv = this.chart.nativeElement as HTMLDivElement;
    areaChartDiv.id = this.kpiChartConfig.identifier + '_c';
    const width = areaChartDiv.clientWidth;
    let height = areaChartDiv.clientHeight;

    while (areaChartDiv.lastChild) {
      areaChartDiv.removeChild(areaChartDiv.lastChild);
    }

    if (!this.kpiChartConfig.chartData || this.kpiChartConfig.chartData.length === 0 || isLoading) {
      return;
    }

    const margin = {
      top: 2,
      right: 5,
      bottom: this.kpiChartConfig.showXAxis ? 60 : 45,
      left: 0
    };

    height = height - margin.top - margin.bottom;

    const x = d3Scale.scaleLinear().range([0, width]);
    const y = d3Scale.scaleLinear().range([height, 0]);
    const max = Math.max(...this.kpiChartConfig.chartData.map(v => v.y));

    x.domain(d3Array.extent(this.kpiChartConfig.chartData, (d: KpiChartData) => d.x));
    y.domain([0, d3Array.max(this.kpiChartConfig.chartData, (d: KpiChartData) => d.y) || 1]);

    const svg = d3.select(this.chart.nativeElement)
      .append('svg')
      .attr('width', width)
      .attr('height', height + margin.top + margin.bottom)
      .attr('style', 'border: transparent')
      .append('g')
      .attr('transform', `translate(0, ${margin.top})`)
      ;

    if (this.kpiChartConfig.showXAxis) {

      // Style X axis
      svg.append('g')
        .attr('transform', 'translate(0,' + height + ')')
        .call(d3
          .axisBottom(x)
          .ticks(10)
          .tickPadding(10)
          .tickFormat((d: any) => d));

      // Style axis texts
      svg.selectAll('text')
        .style('font-size', '0.75rem')
        .style('font-weight', '500')
        .style('color', '#B3B3B3');

      // Add X axis main label
      svg
        .append('text')
        .attr('text-anchor', 'end')
        .attr('x', width - 16)
        .attr('y', height + margin.top + 50)
        .text(moment().format('MM.YYYY'))
        .style('stroke', '#B3B3B3')
        .style('stroke-width', 0.5)
        .style('font-size', '1rem')
        .style('font-weight', '100');

      // Remove axis main line
      svg.select('.domain').remove();

      // Remove axis tick lines
      svg.selectAll('line').remove();

    }

    // Area
    if (this.kpiChartConfig.chartData.find(d => d.y > 0)) {

      const area: any = (data, setValue) =>
        d3Shape.area()
          .curve(d3Shape.curveMonotoneX)
          .x((d: any) => x(d.x))
          .y0(height + 4)
          .y1((d: any) => setValue ? y(d.y) : y(0))
          (data);

      svg.append('linearGradient')
        .attr('id', this._kpiChartConfig.identifier + '_g')
        .attr('gradientUnits', 'userSpaceOnUse')
        .attr('x1', 0).attr('y1', y(0))
        .attr('x2', 0).attr('y2', y(max))
        .selectAll('stop')
        .data([
          {
            offset: '0%', color: this.kpiChartConfig.additionalClass !== 'interactive'
              ? '#F9F9F9'
              : '#FFFFFF'
          },
          { offset: '100%', color: this.kpiChartConfig.chartColor }
        ])
        .enter().append('stop')
        .attr('offset', (d) => d.offset)
        .attr('stop-color', (d) => d.color);

      svg.append('path')
        .datum(this.kpiChartConfig.chartData)
        .attr('style', 'fill: url(#' + this._kpiChartConfig.identifier + '_g);')
        .attr('stroke', 'transparent')
        .attr('stroke-width', 2)
        .attr('d', d => area(d, false))
        .transition()
        .duration(1000)
        .attr('d', d => area(d, true));
    }

    // Line
    const line: any = (data, setValue) =>
      d3Shape.line()
        .curve(d3Shape.curveMonotoneX)
        .x((d: any) => x(d.x))
        .y((d: any) => setValue ? y(d.y) : y(0))
        (data);

    svg.append('path')
      .datum(this.kpiChartConfig.chartData)
      .attr('fill', 'none')
      .attr('stroke', this.kpiChartConfig.lineColor)
      .attr('stroke-width', 2)
      .attr('d', d => line(d, false))
      .transition()
      .duration(1000)
      .attr('d', d => line(d, true));

    if (this.kpiChartConfig.additionalClass === 'interactive') {

      // This allows to find the closest X index of the mouse:
      const bisect = d3.bisector((d: any) => d.x).left;

      // Vertical line
      const focus = svg
        .append('g')
        .append('line')
        .style('fill', 'none')
        .attr('stroke', 'black')
        .style('opacity', 0);

      // Create a tooltip
      const tooltip = d3.select(this.chart.nativeElement)
        .append('div')
        .style('position', 'absolute')
        .style('visibility', 'hidden')
        .style('background-color', 'white')
        .style('border', 'solid')
        .style('border-width', '1px')
        .style('border-radius', '5px')
        .style('padding', '10px')
        .style('width', '200px')
        .style('font-size', '0.875rem');

      // What happens when the mouse move -> show the annotations at the right positions.
      const mouseover = (): void => {
        focus
          .style('opacity', 1)
          .style('pointer', 'none');

        tooltip
          .style('visibility', 'visible');
      };

      const mousemove = (event: any): void => {
        // recover coordinate we need
        const mouse = d3.pointer(event, this.chart.nativeElement);

        const x0 = x.invert(mouse[0]);
        const i = bisect(this.kpiChartConfig.chartData, x0, 1);
        const selectedData = this.kpiChartConfig.chartData[i];

        const text = this._translationService.translate('common', 'complete');
        const tooltipText = `<b>${moment().startOf('day').set('day', selectedData.x).format('DD.MM.YYYY')}</b><br><hr>
          <div class="row pe-2">
            <div class="col-9">
              <div class="d-inline-block"
                style="width: 8px; height: 12px;
                  vertical-align:middle;
                  margin-bottom: 2px;
                  background-color: ${this.kpiChartConfig.lineColor}">
              </div>
              <span>${text}</span>
            </div>
            <span class="col-3 text-right">${selectedData.y}</span>
          </div>`;

        focus
          .attr('x1', mouse[0]).attr('y1', y(0))
          .attr('x2', mouse[0]).attr('y2', y(max));


        tooltip
          .html(tooltipText)
          .style('left', (mouse[0] + (mouse[0] > 700 ? -220 : 20)) + 'px')
          .style('top', (mouse[1] + (mouse[1] > 280 ? -90 : 0)) + 'px');
      };

      const mouseout = (): void => {
        focus.style('opacity', 0);
        tooltip.style('visibility', 'hidden');
      };

      // Create a rect on top of the svg area: this rectangle recovers mouse position
      svg
        .append('rect')
        .style('fill', 'none')
        .style('pointer-events', 'all')
        .attr('width', width)
        .attr('height', height)
        .on('mouseover', mouseover)
        .on('mousemove', mousemove)
        .on('mouseout', mouseout);
    }
  }
}
